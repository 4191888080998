import * as React from 'react';

import { FormikProps } from 'formik';

import { ChatFormButtonsProps, NewMessage, ChatPlugin } from '@common/react/components/Chat/Chat';
import EmojiComponent from '@common/react/components/Chat/EmojiPlugin/EmojiComponent';

export const EmojiPlugin: ChatPlugin = {
	formButton: (formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) => <React.Fragment key="emoji">
		<EmojiComponent
			getPopupContainer={props.getPopupContainer}
			onSelect={(emoji) => formikBag.setFieldValue('text', formikBag.values.text.concat(emoji.native))}
			triggerNode={
				<button
					type="button"
					className="btn btn-sm btn-default"
					style={{ fontSize: 16, lineHeight: '20px' }}
				>
					<i className="fa fa-smile-o fa chat-message-item-ptr" aria-hidden="true" />
				</button>
			}
		/>
	</React.Fragment>,
};
