import { Lang } from '@common/typescript/objects/Lang';

export interface LanguageInfo {
	language: Lang;
	name: string;
	caption: string;
}

export const languages: Array<LanguageInfo> = [
	{ language: Lang.En, name: 'En', caption: 'English' },
	{ language: Lang.Es, name: 'Es', caption: 'Spanish' },
];
