import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';

import { MainLayout } from '@app/components/Layouts/MainLayout';
import Footer from '@app/components/UI/Footer/Footer';
import Header from '@app/components/UI/Header/Header';
import '@app/scss/pages/openPage.scss';
import '@app/scss/pages/privacyPolicy.scss';

interface Props extends RouteProps {
	component: any;
	withHeader?: boolean;
}

const OpenPageRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (
			<MainLayout>
				<ErrorBoundaryWithSentry>
					<RequestProvider>
						{rest.withHeader && <Header />}
						<Component {...props} />
						<Footer />
					</RequestProvider>
				</ErrorBoundaryWithSentry>
			</MainLayout>
		)}
	/>
);

export default OpenPageRoute;
