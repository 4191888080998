import { AppointmentFile } from '@app/objects/AppointmentFile';
import { Patient } from '@app/objects/Patient';
import { User } from '@app/objects/User';
import { EmailTemplate } from '@app/objects/EmailTemplate';
import { Subscription } from '@app/objects/Subscription';

export interface AppointmentEmail {
	id: number;
	patient: Patient | null | undefined;
	patientId: number | null | undefined;
	subscription: Subscription | null | undefined;
	subscriptionId: number | null | undefined;
	subject: string;
	text: string;
	html: string;
	from: string;
	to: string;
	time: number;
	read: boolean;
	patientFiles: Array<AppointmentFile>;
	archive: boolean;
	cc: string;
	bcc: string;
	user?: User;
	emailNotificationEvent: EmailNotificationEvent;
	appointmentEmailStatus: AppointmentEmailStatus;
	appointmentEmailType: AppointmentEmailType | null;
	emailTemplate: EmailTemplate | null | undefined;
	emailTemplateId: number | null | undefined;
}

export interface EmailNotificationEvent {
	notificationId?: number;
	appointmentEmailId?: number;
	event: string;
	time: Date;
	lastEventUpdate: Date;
	deliveryEvent: number;
	engagementEvent: number;
}

export enum AppointmentEmailAction {
	Read,
	Unread,
	Delete
}

export enum AppointmentEmailStatus
{
	New = 0,
	Sent = 1,
	Error = 2,
	NotAllow = 3,
	Inbound= 4,
}

export enum AppointmentEmailType
{
	ByUser = 0,
	EmailTemplate = 1,
	Review = 2,
	Reminder = 3,
	AppointmentChanges = 4,
	Bill = 5,
	Invite = 6,
	SupportChat = 7,
	Specials = 8,
	Document = 9,
}

export const AppointmentEmailStatusTypeNames = {
	[AppointmentEmailStatus.New]: 'New',
	[AppointmentEmailStatus.Sent]: 'Sent',
	[AppointmentEmailStatus.Error]: 'Error',
	[AppointmentEmailStatus.NotAllow]: 'Not Allow',
	[AppointmentEmailStatus.Inbound]: 'Inbound',
};

export const AppointmentEmailStatusTypeIcons = {
	[AppointmentEmailStatus.New]: 'fa-check check-icon',
	[AppointmentEmailStatus.Sent]: 'fa-check check-icon_checked',
	[AppointmentEmailStatus.Error]: 'fa fa-close check-icon_error',
	[AppointmentEmailStatus.NotAllow]: 'fa fa-ban check-icon_error',
	[AppointmentEmailStatus.Inbound]: 'fa fa-inbox check-icon_checked',
};

export const AppointmentEmailTypesNames = {
	[AppointmentEmailType.ByUser]: 'Sent By User',
	[AppointmentEmailType.Review]: 'Review',
	[AppointmentEmailType.Reminder]: 'Appointment Reminder',
	[AppointmentEmailType.Bill]: 'Bill',
	[AppointmentEmailType.AppointmentChanges]: 'Appointment Changes',
	[AppointmentEmailType.EmailTemplate]: 'Email Template',
	[AppointmentEmailType.Invite]: 'Invite',
	[AppointmentEmailType.Specials]: 'Specials',
	[AppointmentEmailType.SupportChat]: 'Support Chat',
	[AppointmentEmailType.Document]: 'Document',
};

export const AppointmentEmailTypesIcons = {
	[AppointmentEmailType.ByUser]: 'fa-user',
	[AppointmentEmailType.Review]: 'fa-commenting',
	[AppointmentEmailType.Reminder]: 'fa-bell',
	[AppointmentEmailType.Bill]: 'fa-usd',
	[AppointmentEmailType.AppointmentChanges]: 'fa-exchange',
	[AppointmentEmailType.EmailTemplate]: 'fa-file',
	[AppointmentEmailType.Invite]: 'fa-handshake-o',
	[AppointmentEmailType.Specials]: 'fa-shopping-bag',
	[AppointmentEmailType.SupportChat]: 'fa-question-circle',
	[AppointmentEmailType.Document]: 'fa-address-card',
};
