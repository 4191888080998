import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';

import { Feature } from '@commonTuna/react/objects/CompanyFeature';

import CompanyFeatureAccess from '@app/components/UI/CompanyFeatureAccess/CompanyFeatureAccess';

const params = { fallback: <span /> };

const PhoneCallPopupModal = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PhoneCallPopupModal" */
		'@app/components/UI/PhoneCallPopupModal/PhoneCallPopupModal'
	)), params);

const PhoneCallModal:React.FC = () => {
	return (
		<CompanyFeatureAccess feature={Feature.PhoneCall}>
			<PhoneCallPopupModal />
		</CompanyFeatureAccess>
	);
};

export default PhoneCallModal;
