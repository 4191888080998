import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';

import { Gender } from '@commonTuna/react/objects/Enums';
import { Payer } from '@commonTuna/react/objects/Payer';

export enum RelationShipToInsuredEnum {
	Self,
	Spouse,
	Child,
	Other
}

export interface BaseInsurance extends WithDeleted {
	payer: Nullable<Payer>;
	payerId: number | null;

	insuranceId: string;
	groupName: string;
	insuranceGroupId: string;
	planName: string;
	subscriberFirstName: string;
	subscriberLastName: string;
	subscriberMiddleName: string;
	subscriberGender: Gender;
	subscriberDateBirth: Nullable<number>;
	subscriberAddress: string;
	relationShipToInsured: RelationShipToInsuredEnum;
	updated?: Nullable<number>;
	created?: Nullable<number>;
}
