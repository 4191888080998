import React from 'react';

import * as moment from 'moment/moment';

import { Rangepicker, RangepickerProps } from '@common/react/components/Forms/Datepicker/Datepicker';
import DatePicker from '@common/react/components/Forms/Datepicker/CoreDatePicker';
import { getPopupContainer } from '@common/react/components/Utils/Utils';

import { DashboardTimeInterval } from '@commonTuna/react/components/Utils';
import { getIntervalFromRange } from '@commonTuna/react/components/UI/TimeIntervalRadio/TimeIntervalRadio';

const CustomRangePicker: React.FC<RangepickerProps> = (props) => {
	const { utcOffset, utc } = props;

	const diff = React.useMemo(() => {
		return utcOffset ? 60 * parseInt(utcOffset, 10) * 60 * 1000 : 0;
	}, [utcOffset]);

	const interval = React.useMemo(() => {
		return getIntervalFromRange(props.value, utcOffset, props.utc);
	}, [props.value, utcOffset, props.utc]);

	if (interval === DashboardTimeInterval.Day) {
		let defaultValue;
		const value = props.value?.[0];

		if (value !== null && Number(value)) {
			defaultValue = utcOffset
				? moment(value as number + diff).utc()
				: utc
					? moment(value as number).utc()
					: moment(value as number);
		} else {
			defaultValue = null;
		}

		return <div className="datepicker-component">
			<DatePicker
				onChange={(date) => {
					props.onChange(date ? [
						utcOffset
							? moment(+date).utc().startOf('day').valueOf() - diff
							: utc
								? moment(+date).utc().startOf('day').valueOf()
								: moment(+date).startOf('day').valueOf(),
						utcOffset
							? moment(+date).utc().endOf('day').valueOf() - diff
							: utc
								? moment(+date).utc().endOf('day').valueOf()
								: moment(+date).endOf('day').valueOf(),
					] : null);
				}}
				format={props.format || 'MM/DD/YYYY'}
				locale={props.locale}
				value={defaultValue || null}
				getPopupContainer={props?.antdProps?.getPopupContainer || getPopupContainer}
			/>
		</div>;
	}

	return <Rangepicker {...props} />;
};

export default CustomRangePicker;
