import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';

import { Terminal } from '@commonTuna/react/objects/Terminal';
import { QrCode } from '@commonTuna/react/objects/QrCode';
import { BasePaymentAccount } from '@commonTuna/react/objects/PaymentAccount';

export interface BasePaymentInquiryOrder extends WithDeleted {
	orderId: number;
	paymentInquiryId: number;
}

export interface BasePaymentInquiry extends WithDeleted {
	uid: string;
	time: number;
	amount: number;
	orderId: string;
	description: string;
	maskedCardNumber: string;
	failureReason: string;
	status: PaymentInquiryStatus;
	scheduled: boolean;

	terminal: Nullable<Terminal>;
	terminalId: Nullable<number>;

	paymentAccount: Nullable<BasePaymentAccount>;
	paymentAccountId: Nullable<number>;

	qrCode: Nullable<QrCode>;
	qrCodeId: Nullable<number>;

	purpose: PaymentInquiryPurpose;
}

export enum PaymentInquiryStatus
{
	New = 0,
	Processed = 1,
	Failed = 2,
	InProcess = 3
}

export const PaymentInquiryStatusNames = {
	[PaymentInquiryStatus.New]: 'New',
	[PaymentInquiryStatus.Processed]: 'Processed',
	[PaymentInquiryStatus.Failed]: 'Failed',
	[PaymentInquiryStatus.InProcess]: 'InProcess',
};

export enum PaymentInquiryMethod {
	QR = 1,
	Terminal = 2,
	Card = 3
}

export const PaymentInquiryMethodNames = {
	[PaymentInquiryMethod.QR]: 'QR',
	[PaymentInquiryMethod.Terminal]: 'Terminal',
	[PaymentInquiryMethod.Card]: 'Payment Account',
};

export const PaymentInquiryStatusTagColor = {
	[PaymentInquiryStatus.New]: 'blue',
	[PaymentInquiryStatus.Processed]: 'green',
	[PaymentInquiryStatus.Failed]: 'red',
	[PaymentInquiryStatus.InProcess]: 'cyan',
};

export enum PaymentInquiryPurpose {
	Sale,
	Refund
}
