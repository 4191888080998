import React from 'react';

import { Notification } from '@common/typescript/objects/Notification';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { Chat, ChatPlugins } from '@common/react/components/Chat/Chat';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import '@common/react/scss/components/typingComponent.scss';

interface Props {
	chat: Chat;
	render: (contacts) => React.ReactNode;
}

interface ContactTyping {
	userId: number;
	timer: any;
}

const TypingComponent: React.FC<Props> = ({ chat, render }) => {
	const [contactIds, setContacts] = React.useState<Array<number>>([]);
	const contactsTyping = React.useRef<Array<ContactTyping>>([]);
	const context = useChatSettingsProviderContext();
	const { subscribe, getUser } = useApplicationContext();
	const user = getUser<BaseUser>();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { notificationTypes, plugins } } = context;
	const delay = plugins[ChatPlugins.Typing]?.options?.delay ?? 5000;

	const handleNotification = React.useCallback((notification: Notification<BaseUser>) => {
		if (!user) return;
		const data = notification.data;
		const userId = data.user;
		switch (notification.objectType) {
			case notificationTypes.typing:
				if (user.id === userId || chat.id !== data.chatId) return;

				const item = contactsTyping.current.find((item) => item.userId === userId);
				const newItem = item || { userId, timer: undefined };
				if (!item) {
					contactsTyping.current.push(newItem);
					setContacts(contactsTyping.current.map((item) => item.userId));
				}

				newItem.timer && clearTimeout(newItem.timer);

				newItem.timer = setTimeout(() => {
					contactsTyping.current = contactsTyping.current.filter((item) => item.userId !== userId);
					setContacts(contactsTyping.current.map((item) => item.userId));
				}, delay);

				break;
			case notificationTypes.chatMessage:
				if (user.id === data.userId || chat.id !== data.chatId) return;

				const typingUser = contactsTyping.current.find((item) => item.userId === data.userId);
				if (typingUser) {
					typingUser.timer && clearTimeout(typingUser.timer);
					contactsTyping.current = contactsTyping.current.filter((item) => item.userId !== data.userId);
					setContacts(contactsTyping.current.map((item) => item.userId));
				}
				break;
			// no default
		}
	}, [user?.id, chat?.id]);

	React.useEffect(subscribe(handleNotification));

	React.useEffect(() => {
		contactsTyping.current.forEach((item) => item.timer && clearTimeout(item.timer));
		contactsTyping.current = [];
		setContacts([]);
	}, [user?.id, chat?.id]);

	const contacts = React.useMemo(() => {
		if (!contactIds.length) return [];

		return chat.contacts.filter((contact) => contactIds.find((id) => contact.id === id));
	}, [contactIds, chat?.id, chat?.contacts?.length]);

	return <>
		{render(contacts)}
	</>;
};

export default TypingComponent;
