import React from 'react';

import Button from 'antd/lib/button';

import { RecordResult } from '@common/react/utils/record-audio';

const wrapperStyle: React.CSSProperties = {
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	zIndex: 30,
	backgroundColor: 'white',
	minWidth: '100px',
	minHeight: '100px',
	borderRadius: '50%',
	boxShadow: '0 0 25px 5px rgba(0, 0, 0, 0.2)',
	overflow: 'hidden',
};

const videoStyle: React.CSSProperties = {
	width: '500px',
	height: '500px',
	transform: 'scale(1.8)',
};

interface VideoMessagePreviewProps {
	recordResult: RecordResult;
	setRecordResult: (a: RecordResult | undefined) => void;
	onReset?: () => void;
	className?: string;
	shape?: 'circle' | 'default' | 'round';
	buttonStyle?: React.CSSProperties;
	containerClassName?: string;
	width?: number;
	waveColor?: string;
	progressColor?: string;
}

const VideoMessagePreview: React.FC<VideoMessagePreviewProps> = ({
	recordResult, setRecordResult, onReset, ...rest
}) => {
	const { className = 'btn-primary', shape = 'default', buttonStyle } = rest;
	const [isPlaying, setIsPlaying] = React.useState<boolean>(false);

	const videoRef = React.useRef<HTMLVideoElement>(null);
	const wavesurferContainer = React.useRef<HTMLDivElement>(null);
	const wavesurfer = React.useRef<any>();

	React.useEffect(() => {
		importWavesurfer()
			.then((wavesurfer) => initializeWavesurfer(wavesurfer.default))
			.catch(console.warn);
	}, []);

	const importWavesurfer = async () => {
		/* eslint-disable */
		// @ts-ignore
		return await import('wavesurfer.js');
		/* eslint-enable */
	};

	const initializeWavesurfer = (wavesurferBundle) => {
		if (!wavesurferContainer.current) return;

		wavesurfer.current = wavesurferBundle.create({
			container: wavesurferContainer.current,
			waveColor: rest.waveColor ?? '#58a0e8',
			progressColor: rest.progressColor ?? '#cacaca',
			barWidth: 2,
			barHeight: 2,
			barMinHeight: 2,
			height: 40,
			cursorColor: 'transparent',
			backend: 'MediaElement',
		});

		wavesurfer.current?.load(videoRef.current);
	};

	const reset = () => {
		setRecordResult(undefined);

		onReset && onReset();
	};

	const play = () => {
		if (!videoRef.current) return;

		videoRef.current.play()
			.then(() => {
				setIsPlaying(true);
			})
			.catch(console.warn);
	};

	const stop = () => {
		if (!videoRef.current) return;

		videoRef.current.pause();
		videoRef.current.currentTime = 0;
	};

	const handlePlayClick: React.MouseEventHandler = (e) => {
		e.preventDefault();

		if (!isPlaying) {
			play();
		} else {
			stop();
		}
	};

	const handleResetClick: React.MouseEventHandler = (e) => {
		e.preventDefault();

		reset();
	};

	const handlePlay = () => {
		setIsPlaying(true);
	};

	const handlePause = () => {
		setIsPlaying(false);
	};

	const handleEnd = () => {
		stop();
	};

	return (
		<div style={{ display: 'flex' }} className={rest.containerClassName || 'mr10'}>
			<Button
				style={{ borderRadius: '50%', ...buttonStyle }}
				type="primary"
				className={`btn mr10 ${className}`}
				shape={shape}
				icon={!isPlaying ? <i className="fa fa-play" /> : <i className="fa fa-stop" />}
				onClick={handlePlayClick}
				title="Play"
			/>
			<div className="wavesurfer-container" style={{ width: rest.width ?? 200 }} ref={wavesurferContainer} />
			<Button
				style={{ borderRadius: '50%', ...buttonStyle }}
				type="primary"
				className={`btn ${className}`}
				shape={shape}
				icon={<i className="fa fa-times" />}
				onClick={handleResetClick}
				title="Remove"
			/>
			<div style={{ ...wrapperStyle, display: isPlaying ? 'block' : 'none' }}>
				{/* eslint-disable */}
				<video
					ref={videoRef}
					style={videoStyle}
					src={recordResult.url}
					onPlay={handlePlay}
					onEnded={handleEnd}
					onPause={handlePause}
				/>
				{/* eslint-enable */}
			</div>
		</div>
	);
};

export default VideoMessagePreview;
