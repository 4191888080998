import React from 'react';

import '@app/scss/components/filtersComponentTitle.scss';

export const SectionTitle: React.FC<{title: string}> = ({ title }) => {
	return (
		<div className="filters-component-title__container">
			<hr className="line" />
			<div className="filters-component-title">
				<strong>
					{title}
				</strong>
			</div>
		</div>
	);
};
