import * as React from 'react';

import Radio from 'antd/lib/radio';

import { HandleChangeEventElement } from '@common/react/components/Core/ItemsProvider/ItemsProvider';

import { Feature } from '@commonTuna/react/objects/CompanyFeature';

import { systemTypeGroup, SystemTypeGroupElem, SystemTypes } from '@app/store/Dashboard';
import { useCompanyFeatureProviderContext } from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';

interface ComponentProps {
	systemType: number | string | undefined;
	handleChangeAndRefresh: (evt: HandleChangeEventElement) => void;
	className?: string;
	paramName?: string;
}

const SystemTypeGroupFilter: (props: ComponentProps) => React.ReactElement = ({
	systemType,
	handleChangeAndRefresh,
	className = 'filter-ratio-component ml10 mb10',
	paramName = 'systemType',
}) => {
	const [checkedSystemTypeGroup, setCheckedSystemTypeGroup] = React.useState<Array<SystemTypeGroupElem>>([]);

	const { checkFeatureAccess } = useCompanyFeatureProviderContext();

	const systemTypeGroupCheckFeatures = () => {
		const resultSystemTypeGroup = systemTypeGroup.filter((item) => {
			return ((item.value === SystemTypes.Chrono && checkFeatureAccess(Feature.DrChrono))
				|| (item.value === SystemTypes.Nopali && checkFeatureAccess(Feature.Nopali))
				|| (item.value === SystemTypes.ZocDoc && checkFeatureAccess(Feature.ZocDoc))
				|| (item.value === SystemTypes.Inquiry && checkFeatureAccess(Feature.Inquiries))
				|| (item.value === SystemTypes.all)
				|| (item.value === SystemTypes.Tuna)
				|| (item.value === SystemTypes.PhoneCall));
		});
		setCheckedSystemTypeGroup([...resultSystemTypeGroup]);
	};

	React.useEffect(() => {
		systemTypeGroupCheckFeatures();
	}, []);

	return (
		<>
			{checkedSystemTypeGroup && checkedSystemTypeGroup.length > 0
			&& (
				<div data-param={paramName} className={className}>
					<Radio.Group
						className="pull-right custom-radio"
						name={paramName}
						value={systemType !== undefined && systemType !== null ? +systemType : SystemTypes.all}
						onChange={(e) => {
							handleChangeAndRefresh({
								currentTarget: {
									name: paramName,
									value: e.target.value,
								},
							});
						}}
					>
						{checkedSystemTypeGroup.map((item, index) => (
							<Radio.Button value={item.value} key={item.image || item.caption}>
								<div
									title={item.caption}
									className="custom-radio-button"
									style={{ paddingBottom: '7px', maxWidth: '50px', height: '100%' }}
								>
									{item.image
										? <img src={item.image} alt={item.caption} style={{ maxHeight: '100%', paddingBottom: '3px' }} />
										: item.caption}
								</div>
							</Radio.Button>
						))}
					</Radio.Group>
				</div>
			)}
		</>
	);
};

export default SystemTypeGroupFilter;
