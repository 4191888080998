import { BaseInquiry } from '@common/react/objects/BaseInquiry';
import { Lang } from '@common/typescript/objects/Lang';
import { Nullable } from '@common/typescript/objects/Nullable';

import { Gender } from '@commonTuna/react/objects/Enums';
import { Device } from '@commonTuna/react/objects/Device';

import { InquirySystem } from '@commonTuna/react/objects/InquirySystem';

import { Location } from '@app/objects/Location';
import { Page } from '@app/objects/Page';
import { Doctor } from '@app/objects/Doctor';
import { Appointment } from '@app/objects/Appointment';
import { Patient } from '@app/objects/Patient';
import { ProcessingType } from '@app/objects/InquiryProcessing';
import { User } from '@app/objects/User';

export enum InquiryStatus {
	New = 0,
	Open = 1,
	InProgress = 2,
	InComplete = 3,
	Complete = 4,
	DrChrono = 5,
	All = 6,
}

const BaseInquiryStatusNamesWithoutAll = {
	[InquiryStatus.New]: 'New',
	[InquiryStatus.Open]: 'Open',
	[InquiryStatus.InProgress]: 'In Progress',
	[InquiryStatus.InComplete]: 'In Complete',
	[InquiryStatus.Complete]: 'Complete',
};

export const BaseInquiryStatusNames = {
	[InquiryStatus.All]: 'All Statuses',
	...BaseInquiryStatusNamesWithoutAll,
};

export const InquiryStatusArray = [
	InquiryStatus.All,
	InquiryStatus.New,
	InquiryStatus.Open,
	InquiryStatus.InProgress,
	InquiryStatus.InComplete,
	InquiryStatus.Complete,
	InquiryStatus.DrChrono,
];

export const InquiryStatusNames = {
	...BaseInquiryStatusNames,
	[InquiryStatus.DrChrono]: 'Dr Chrono',
};

export interface Inquiry extends BaseInquiry {
	locationId: number | null;
	location?: Location | null;

	mainPageId: number | null;
	mainPage?: Page | null;

	pageId: number | null;
	page?: Page | null;

	procedure: number | null;
	procedureId?: number | null;

	doctorId: number | null;
	doctor?: Doctor | null;

	appointments: Array<Appointment>;
	patients: Array<Patient>;

	checked: boolean;
	firstName: string;
	lastName: string;
	date: number | null;
	status: InquiryStatus;
	podiumReviewRequested: boolean;
	time: number | null;
	chronoTime: number | null;
	gender: Gender;
	processingEvents: Array<InquiryProcessingEvent>;
	commentCount: number;
	language: Lang;
	path: string;
	device: Device;

	birthDate: Nullable<number>;

	inquirySystem: Nullable<InquirySystem>;
}

export interface InquiryProcessingEvent {
	id: number;
	userId: number | null;
	user: User | null;
	inquiryId: number;
	processingType: ProcessingType;
	offset: number;
	doneTill: number;
	time: number;
}

export const getInquiryStatuses = (inquiry: Inquiry): object => {
	const checkDoctor = inquiry.doctor && inquiry.doctor.chronoId;
	const checkLocation = inquiry.location && inquiry.location.chronoId;
	const checkTime = inquiry.chronoTime != null;

	const chrono = checkDoctor && checkLocation && checkTime;

	if (chrono) {
		return {
			[InquiryStatus.DrChrono]: InquiryStatusNames[InquiryStatus.DrChrono],
			...BaseInquiryStatusNamesWithoutAll,
		};
	}

	return BaseInquiryStatusNamesWithoutAll;
};
