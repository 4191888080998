import React from 'react';

import { useHistory } from 'react-router-dom';

import GA4React from 'ga-4-react';

interface RouteChangeTrackerProps {
	id: string;
}

const RouteChangeTracker: React.FC<RouteChangeTrackerProps> = ({ id, children }) => {
	const history = useHistory();

	React.useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			const ga4react = new GA4React(id);

			ga4react.initialize().then((ga4) => {
				ga4.pageview(window.location.pathname + window.location.search);

				history.listen((location, action) => {
					if (GA4React.isInitialized()) {
						ga4react.pageview(location.pathname + location.search);
						ga4react.gtag('set', 'page', location.pathname);
					}
				});
			}, console.error);
		}
	}, []);

	return <>{children}</>;
};

export default RouteChangeTracker;
