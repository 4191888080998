import * as React from 'react';

import Select, { SelectProps } from 'antd/lib/select';

import { SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import ItemsSelect from '@common/react/components/UI/ItemsSelect/ItemsSelect';

import { defaultStatusesFilters } from '@app/objects/Appointment';
import '@app/scss/components/statusFilter.scss';
import { filterOption } from '@app/utils/filterOption';
import { NullableVital } from '@app/components/Pages/Admin/BaseReport/BaseReport';
import { Vital } from '@app/objects/Vital';

interface ComponentProps extends Omit<SelectProps, 'mode'> {
	value: Array<number> | undefined;
	onChange: (value, option?) => void;
	vitals?: Array<Vital>;
	antdProps?: SelectProps;
	withAdditionalOptions?: boolean;
	maxTagCount?: number;
	maxTagTextLength?: number;
	allowClear?: boolean;
	className?: string;
	valueName?: string;
	mode?: string;
	resetValue?: boolean;
	filterItem?: (item) => boolean;
}

const Option = Select.Option;

const VitalsFilter: React.FC<ComponentProps> = (props) => {
	const {
		value, onChange, maxTagCount, maxTagTextLength, antdProps, valueName = 'vitalIds', mode = 'multiple', resetValue,
	} = props;
	const {
		withAdditionalOptions, vitals, allowClear = true, className = 'ml10 pull-right',
	} = props;
	const [currentValue, setValue] = React.useState<any>(null);

	const handleChange = (value, option) => {
		onChange({ [valueName]: value }, option);
		setValue(value);
		resetValue && setTimeout(() => setValue(undefined), 0);
	};

	return (
		<div className={`status-filter ${className}`}>
			<ItemsSelect<Vital>
				getOptionProps={(item) => ({
					value: item.id,
					children: item.name,
					item,
					label: item.name,
				})}
				selectProps={{
					...antdProps,
					allowClear,
					mode: mode === 'multiple' ? mode : undefined,
					placeholder: 'Vitals...',
					value: resetValue
						? currentValue
						: value || (mode === 'multiple' ? [] : undefined),
					onChange: handleChange,
					maxTagCount,
					maxTagTextLength,
					className: 'w100',
					filterOption,
					showSearch: true,
				}}
				filterItem={props.filterItem}
				defaultSort={['sortOrder', SortingDirection.Ascending]}
				filterHandler={() => false}
				type="vital"
				items={vitals}
				pagination={{ current: 1, pageSize: 100 }}
				filters={defaultStatusesFilters}
				additionalOptions={withAdditionalOptions
					? (
						<Option key={NullableVital} value={NullableVital} label="No vitals">
							Without vitals
						</Option>
					) : null}
			/>
		</div>
	);
};

export default VitalsFilter;
