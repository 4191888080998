import * as React from 'react';

import { ChatPlugin } from '@common/react/components/Chat/Chat';
import ChatNameEditor from '@common/react/components/Chat/ChatNameEditor';

export const ChatNameEditorPlugin: ChatPlugin = {
	messagesHeaderAction: ({ currentChat, actions, storageName }) =>
		(currentChat ? <ChatNameEditor
			render={(show) => <button
				type="button"
				title="Edit ChatName"
				className="btn btn-sm btn-primary pull-right"
				onClick={show}
			>
				<i className="fa fa-pencil" />
			</button>}
			onSave={(chat) => actions.updateChat(chat, storageName)}
			chat={currentChat}
		/> : null),
};
