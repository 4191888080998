import * as React from 'react';

import { saveAs } from 'file-saver';

import Modal from 'antd/lib/modal';

import { BaseParams } from '@common/typescript/objects/BaseParams';
import { ButtonProps } from '@common/react/components/Forms/Button';
import { FetchButton, FetchMethod } from '@common/react/components/UI/LoadingButton/LoadingButton';

interface Props extends ButtonProps {
	method?: FetchMethod;
	path: string;
	data?: BaseParams;
	fileName?: string;
	onSuccess?: (blob: Blob) => void;
	onError?: (err: string) => void;
	children: React.ReactNode;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

// tslint:disable-next-line
export function DownloadFileButton(props: Props) {
	const {
		method = FetchMethod.POST, path, children, data, fileName = 'file.pdf', onSuccess: propsOnSuccess, onError: propsOnError, onClick,
		...rest
	} = props;

	const beforeAction = (action, event: React.MouseEvent<HTMLButtonElement>) => {
		onClick && onClick(event);
		return true;
	};

	const onSuccess = (res) => {
		res.blob().then((blob) => {
			saveAs(blob, res.headers.get('name') || fileName);
			propsOnSuccess && propsOnSuccess(blob);
		});
	};

	const onError = (err: string) => {
		propsOnError && propsOnError(err);
		Modal.error({
			title: 'Error',
			content: err,
		});
	};

	return <FetchButton
		type="button"
		fetchUrl={path}
		fetchData={data}
		method={method}
		beforeAction={beforeAction}
		onSuccess={onSuccess}
		onError={onError}
		defaultErrorMessage="Something went wrong while downloading the file."
		{...rest}
	>
		{children}
	</FetchButton>;
}
