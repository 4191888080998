import * as React from 'react';

import Modal from 'antd/lib/modal';

import { getUserName } from '@common/react/utils/utils';
import Autocomplete from '@common/react/components/Forms/Autocomplete/Autocomplete';
import { BaseParams } from '@common/react/objects/BaseParams';
import { BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { Chat } from '@common/react/components/Chat/Chat';
import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';
import Avatar from '@common/react/components/Forms/Avatar';

interface AddChatPopupProps {
	user: BaseUserWithAvatar;
	render: (show: () => void) => void;
	chat: Chat;
	getPatientId: (portalId: number) => void;
	autocompleteParams?: BaseParams;
}

interface AddChatPopupState {
	popupShow: boolean;
	userId: number;
}

const AddUserToSupportChatPopup: React.FC<AddChatPopupProps> = (props) => {
	const [state, _setState] = React.useState<AddChatPopupState>({
		popupShow: false,
		userId: -1,
	});
	const [key, setKey] = React.useState(-1);
	const setState = React.useCallback((newState) => _setState((state) => ({ ...state, ...newState })), []);

	const showPopup = React.useCallback(() => setState({ popupShow: true }), []);
	const popupHide = React.useCallback(() => setState({ popupShow: false }), []);

	const { user: loggedUser, chat, autocompleteParams } = props;

	const patientContact = chat.contacts.find((contact) => !contact.remoteId || !(contact.remoteId > 0));

	return (
		<>
			{props.render(showPopup)}
			<Modal
				title="Add users"
				open={state.popupShow}
				onCancel={popupHide}
				destroyOnClose
				width={500}
				footer={null}
			>
				<div className="add-user-to-chat">
					<div>
						Patient:
						{' '}
						<LinkWithPrevLocation
							to={{}}
							onClick={() => {
								patientContact && props.getPatientId(patientContact.id);
							}}
							className="inline-select-item inline-select-item_link"
						>
							<span>{getUserName(patientContact)}</span>
						</LinkWithPrevLocation>
					</div>
					<div>
						Supports:
						{' '}
						{chat.contacts.map((user) => {
							return user.remoteId && user.remoteId > 0
								? (
									<LinkWithPrevLocation
										to={`/user-editor/${user.remoteId}`}
										className="inline-select-item inline-select-item_link"
										key={user.id}
									>
										<div className="inline-select-item__avatar">
											<Avatar
												type=""
												object={user}
												readonly
												className="avatar-container"
												showOpenIcon={false}
												showDownload={false}
												withModal={false}
												firstName={user.firstName}
												lastName={user.lastName}
											/>
										</div>
										<span>{getUserName(user)}</span>
									</LinkWithPrevLocation>
								)
								: null;
						})}
					</div>
					<div>
						<Autocomplete<BaseUserWithAvatar>
							key={key}
							type="userAutoCompleteViewList"
							renderOption={(autocompleteItem) => {
								return {
									key: `${autocompleteItem.id}`,
									value: getUserName(autocompleteItem),
									title: getUserName(autocompleteItem),
									item: autocompleteItem,
									label: <>{getUserName(autocompleteItem)}</>,
								};
							}}
							onSelect={(value) => {
								_setState((prevState) => ({
									...prevState,
									userId: +value,
								}));
							}}
							loadOnFocus
							params={autocompleteParams}
							antdProps={{
								placeholder: 'Start typing for search...',
							}}
						/>
					</div>
					<div className="text-center mt10">
						<RequestButton
							requestType="supportChatUser"
							requestProps={{
								chatId: props.chat.id,
								userId: state.userId,
							}}
							onSuccess={() => {
								popupHide();
								_setState((prevState) => ({
									...prevState,
									userId: -1,
									user: null,
								}));
								setKey(Math.random());
							}}
							className="btn btn-primary"
							title="Add"
							disabled={!state.userId || state.userId < 0}
						>
							Add
						</RequestButton>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default AddUserToSupportChatPopup;
