import * as React from 'react';

import loadable from '@loadable/component';

import { ChatMessageType, ChatPlugin } from '@common/react/components/Chat/Chat';
import { loadableDelay } from '@common/react/loadable/loadableSettings';
import Button from '@common/react/components/Forms/Button';
import ForwardMessage from '@common/react/components/Chat/ForwardPlugin/ForwardMessage';
import RegularChatMessage from '@common/react/components/Chat/RegularMessagePlugin/RegularChatMessage';
import ForwardLastMessage from '@common/react/components/Chat/ForwardPlugin/ForwardLastMessage';

const ForwardButton = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "ForwardButton" */ '@common/react/components/Chat/ForwardPlugin/ForwardButton')),
	{
		fallback: (
			<Button
				title="Forward"
				className="message-action btn btn-default btn-sm"
				isLoading
				disabled
			>
				Forward
			</Button>
		),
	},
);

const allowMessageTypes = [
	ChatMessageType.Regular,
	ChatMessageType.Giphy,
	ChatMessageType.Tenor,
	ChatMessageType.Sticker,
];

export const ForwardPlugin: ChatPlugin = {
	chatMessageAction: ({ ...rest }) => allowMessageTypes.includes(rest.message?.chatMessageType)
	&& <ForwardButton
		{...rest}
	/>,
	message: {
		render: ({ message, contacts, onImageClick }) => <ForwardMessage message={message} contacts={contacts} />,
		lastMessage: ({ message, chat, userId }) => <ForwardLastMessage message={message} chat={chat} userId={userId} />,
		notification: ({ message, withRemoteId, contacts }) => {
			return <RegularChatMessage message={message} contacts={contacts} />;
		},
	},
};
