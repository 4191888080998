import * as React from 'react';
import { Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';

import Layout from '@app/components/Layout';

import DashboardRoute from '@app/components/Routes/DashboardRoute';
import { MainRoute } from '@app/components/Routes/MainRoute';
import OpenPageRoute from '@app/components/Routes/OpenPageRoute';
import TunaLoader from '@app/components/UI/TunaLoader';

const paramsFullScreen = {
	fallback: <TunaLoader />,
};

const params = {
	fallback: <TunaLoader />,
};

const paramsLogo = {
	fallback: <TunaLoader />,
};

/* --------------Admin---------------*/

const Vitals = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "VitalsPage" */ '@app/components/Pages/Admin/Vital/Vitals')), params);
const Inquiries = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InquiriesPage" */ '@app/components/Pages/Admin/Inquiries/Inquiries')), params);
const Sales = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SalesPage" */ '@app/components/Pages/Admin/Sales/Sales')), params);
const SaleEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SalesEditor" */ '@app/components/Pages/Admin/Sales/SaleEditor')), params);
const Inquiry = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InquiryPage" */ '@app/components/Pages/Admin/Inquiries/InquiryEditor')), params);
const Services = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ServicesPage" */ '@app/components/Pages/Admin/Services/Services')), params);
const Payers = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PayersPage" */ '@app/components/Pages/Admin/Payers/Payers')), params);
const Users = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UsersPage" */ '@app/components/Pages/Admin/Users/Users')), params);
const UserEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UserEditorPage" */ '@app/components/Pages/Admin/Users/UserEditor')), params);
const SystemUserGroups = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SystemUserGroups" */ '@app/components/Pages/Admin/Users/SystemUserGroups')), params);
const Regions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PagesPage" */ '@app/components/Pages/Admin/Region/Regions')), params);
const RegionEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PagesPage" */ '@app/components/Pages/Admin/Region/RegionEditor')), params);
const Pages = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PagesPage" */ '@app/components/Pages/Admin/Pages/Pages')), params);
const PageEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageEditorPage" */ '@app/components/Pages/Admin/Pages/PageEditor')), params);
const Cases = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CasesPage" */ '@app/components/Pages/Admin/Cases/Cases')), params);
const CaseEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CaseEditorPage" */ '@app/components/Pages/Admin/Cases/CaseEditor')), params);
const DocumentTypes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DocumentTypesPage" */ '@app/components/Pages/Admin/DocumentTypes/DocumentTypes')), params);
const LedgerReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "OrdersTargetPage" */ '@app/components/Pages/Admin/LedgerReport/LedgerReport')), params);
const OrderDetailsReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "OrdersTargetPage" */ '@app/components/Pages/Admin/OrderDetailsReport/OrderDetailsReport')), params);
const Orders = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "OrdersPage" */ '@app/components/Pages/Admin/OrdersPage/OrdersPage')), params);
const Quotes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Quotes" */ '@app/components/Pages/Admin/Quotes/Quotes')), params);
const Refunds = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Refunds" */ '@app/components/Pages/Admin/Refunds/Refunds')), params);
const Faqs = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "FaqsPage" */ '@app/components/Pages/Admin/Faqs/Faqs')), params);
const ServiceCalls = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ServiceCalls" */ '@app/components/Pages/Admin/ServiceCall/ServiceCallsRoot')), params);
const SystemFaqs = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "FaqsPage" */ '@app/components/Pages/Admin/Faqs/SystemFaqs')), params);
const FaqEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "FaqEditor" */ '@app/components/Pages/Admin/Faqs/FaqEditor')), params);
const SystemFaqEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SystemFaqEditor" */ '@app/components/Pages/Admin/Faqs/SystemFaqEditor')), params);
const ReviewRequests = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ReviewRequestsPage" */ '@app/components/Pages/Admin/ReviewRequests/ReviewRequestsPage')), params);
const Reviews = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ReviewsPage" */ '@app/components/Pages/Admin/Reviews/Reviews')), params);
const ReviewEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ReviewEditorPage" */ '@app/components/Pages/Admin/Reviews/ReviewEditor')), params);
const Locations = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LocationsPage" */ '@app/components/Pages/Admin/Locations/Locations')), params);
const LocationEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LocationEditorPage" */ '@app/components/Pages/Admin/Locations/LocationEditor')), params);
const PortalHomeSpecials = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorsPage" */ '@app/components/Pages/Admin/Specials/PortalHomeSpecials')), params);
const PortalDoctors = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorsPage" */ '@app/components/Pages/Admin/Doctors/PortalDoctors')), params);
const Doctors = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorsPage" */ '@app/components/Pages/Admin/Doctors/Doctors')), params);
const DoctorEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorEditorPage" */ '@app/components/Pages/Admin/Doctors/DoctorEditor')), params);
const Professions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProfessionEditorPage" */ '@app/components/Pages/Admin/Professions/Professions')), params);
const Honors = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HonorsPage" */ '@app/components/Pages/Admin/Honors/Honors')), params);
const HonorEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HonorEditorPage" */ '@app/components/Pages/Admin/Honors/HonorEditor')), params);
const Dashboard = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DashboardPage" */ '@app/components/Pages/Admin/Dashboard/Dashboard')), params);
const Patients = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PatientsPage" */ '@app/components/Pages/Admin/Patients/Patients')), params);
const PatientEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PatientEditorPage" */ '@app/components/Pages/Admin/Patients/PatientEditor')), params);
const Appointments = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AppointmentsPage" */
		'@app/components/Pages/Admin/Appointments/AppointmentsWithEditableTable/AppointmentsET'
	)), params);
const AppointmentEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AppointmentEditorPage" */ '@app/components/Pages/Admin/Appointments/AppointmentEditorPage')), params);
const Analyzes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AnalyzesPage" */ '@app/components/Pages/Admin/Analyzes/Analyzes')), params);
const InstagramPosts = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InstagramPostsPage" */ '@app/components/Pages/Admin/InstagramPosts/InstagramPosts')), params);
const InstagramPostEditor = loadable(() =>
	loadableDelay(
		import(/* webpackChunkName: "InstagramPostEditorPage" */ '@app/components/Pages/Admin/InstagramPosts/InstagramPostEditor'),
	), params);
const AuthLinks = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AuthLinks" */ '@common/react/components/Pages/AuthLinks/AuthLinks')), params);
const AuthLinksFilters = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AuthLinksFilters" */ '@commonTuna/react/components/Pages/Admin/AuthLinks/AuthLinksFilters')), params);
const ChatsPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ChatsPage" */ '@app/components/Pages/Admin/Chats/Chats')), params);
const SupportChatsPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SupportChatsPage" */ '@app/components/Pages/Admin/SupportChats/SupportChats')), params);
const AppointmentEmails = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AppointmentEmailsPage" */ '@app/components/Pages/Admin/AppointmentEmails/AppointmentEmails')), params);
const Calendar = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CalendarPage" */ '@app/components/Pages/Admin/Calendar/Calendar')), params);
const Categories = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CategoriesPage" */ '@app/components/Pages/Admin/Categories/Categories')), params);
const CategoryEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CategoryEditorPage" */ '@app/components/Pages/Admin/Categories/CategoryEditor')), params);
const Products = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CategoriesPage" */ '@app/components/Pages/Admin/Products/Products')), params);
const ProductEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProductEditorPage" */ '@app/components/Pages/Admin/Products/ProductEditor')), params);
const Statuses = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "StatusesPage" */ '@app/components/Pages/Admin/Statuses/Statuses')), params);
const Documents = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DocumentsPage" */ '@app/components/Pages/Admin/Documents/Documents')), params);
const Invoices = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InvoicesPage" */ '@app/components/Pages/Admin/Invoices/Invoices')), params);
const InvoiceEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InvoiceEditorPage" */ '@app/components/Pages/Admin/Invoices/InvoiceEditor')), params);
const GlobalQuestions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DiseasesPage" */ '@app/components/Pages/Admin/Diseases/GlobalQuestions')), params);
const MedicalQuestions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DiseasesPage" */ '@app/components/Pages/Admin/Diseases/MedicalQuestions')), params);
const GlobalQuestionEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DiseaseEditorPage" */ '@app/components/Pages/Admin/Diseases/GlobalQuestionEditor')), params);
const MedicalQuestionEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DiseaseEditorPage" */ '@app/components/Pages/Admin/Diseases/MedicalQuestionEditor')), params);
const Templates = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TemplatesPage" */ '@app/components/Pages/Admin/Templates/Templates')), params);
const TemplateEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TemplateEditorPage" */ '@app/components/Pages/Admin/Templates/TemplateEditor')), params);
const EmailTemplates = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EmailTemplatesPage" */ '@app/components/Pages/Admin/EmailTemplate/EmailTemplates')), params);
const Verifications = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "VerificationsPage" */ '@app/components/Pages/Admin/Verification/Verifications')), params);
const SystemVerifications = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "VerificationsPage" */ '@app/components/Pages/Admin/Verification/SystemVerifications')), params);
const EmailTemplateEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EmailTemplatesPage" */ '@app/components/Pages/Admin/EmailTemplate/EmailTemplateEditor')), params);
const Accounts = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AccountsPage" */ '@app/components/Pages/Admin/Accounts/Accounts')), params);
const CompanyEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CompanyEditorPage" */ '@app/components/Pages/Admin/Company/CompanyEditor')), params);
const Ledgers = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LedgersPage" */ '@app/components/Pages/Admin/Ledgers/Ledgers')), params);
const LedgerEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LedgerEditorPage" */ '@app/components/Pages/Admin/Ledgers/LedgerEditor')), params);
const SpecialsList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SpecialsPage" */ '@app/components/Pages/Admin/Specials/Specials')), params);
const SpecialsEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SpecialEditorPage" */ '@app/components/Pages/Admin/Specials/SpecialEditor')), params);
const AppointmentTypes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AppointmentTypesPage" */ '@app/components/Pages/Admin/Appointments/AppointmentTypes')), params);
const AddressBook = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AddressBookPage" */ '@app/components/Pages/Admin/AddressBook/AddressBook')), params);
const Tasks = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TasksPage" */ '@app/components/Pages/Admin/Tasks/Tasks')), params);
const ProcedureList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProceduresPage" */ '@app/components/Pages/Admin/Procedures/Procedures')), params);
const ProcedureEditor = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "ProcedureEditorPage" */
			'@app/components/Pages/Admin/Procedures/ProcedureEditor/ProcedureEditor'
		)),
	params,
);
const WorkShiftsReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "WorkShiftsPage" */ '@app/components/Pages/Admin/WorkShifts/WorkShiftsReport')), params);
const RoleSchemas = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RoleSchemasPage" */ '@app/components/Pages/Admin/RoleSchemas/RoleSchemas')), params);
const DaysOffReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "WorkShiftsPage" */ '@app/components/Pages/Admin/DaysOff/DaysOffReport')), params);
const BalanceReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BalanceReport" */ '@app/components/Pages/Admin/BalanceReport/BalanceReport')), params);
const AppointmentInsuranceList = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "InsuranceListPage" */
			'@app/components/Pages/Admin/Appointments/Insurance/AppointmentInsurances'
		)),
	params,
);
const BatchList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BatchListPage" */ '@app/components/Pages/Admin/Batches/Batches')), params);
const BatchEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BatchEditorPage" */ '@app/components/Pages/Admin/Batches/BatchEditor/BatchEditor')), params);
const ProcedureGroupList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProcedureGroupsPage" */ '@app/components/Pages/Admin/ProcedureGroups/ProcedureGroups')), params);
const FaxEventList = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "FaxEventsPage" */
			'@app/components/Pages/Admin/FaxEvents/FaxEvents'
		)),
	params,
);
const ClaimPaymentDocuments = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PaymentDocuments" */
		'@app/components/Pages/Admin/ClaimPaymentDocuments/ClaimPaymentDocuments'
	)), params);
const ClaimReports = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClaimReportsPage" */ '@app/components/Pages/Admin/ClaimReports/ClaimReports')), params);
const ClaimReportViewer = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClaimReportViewerPage" */
		'@app/components/Pages/Admin/ClaimReports/ClaimReportViewer/ClaimReportViewer'
	)), params);
const ClaimPayments = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClaimReportsPage" */ '@app/components/Pages/Admin/ClaimPayments/ClaimPayments')), params);
const ClaimPaymentPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClaimPaymentPage" */ '@app/components/Pages/Admin/ClaimPayments/ClaimPaymentPage')), params);
const ServiceHours = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorProceduresPage" */ '@app/components/Pages/Admin/Procedures/ServiceHours')), params);
const SyncTimes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SyncTimesPage" */ '@app/components/Pages/Admin/SyncTimes/SyncTimes')), params);

const Wizard = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "WizardPage" */ '@app/components/Pages/Admin/Wizard/Wizard')), params);
const AppointmentWizard = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "AppointmentWizardPage" */ '@app/components/Pages/Admin/AppointmentWizard/AppointmentWizard')),
	paramsFullScreen,
);
const Checkout = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "CheckoutPage" */
			'@app/components/Pages/Admin/Checkout/Checkout'
		)),
	paramsFullScreen,
);
const CardPayment = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "CardPaymentPage" */
			'@app/components/Pages/Admin/CardPayment/CardPayment'
		)),
	paramsFullScreen,
);
const UserSessions = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "UserSessionsPage" */
			'@commonTuna/react/components/Pages/Admin/UserSessions/UserSessions'
		)),
	params,
);
const Notifications = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "NotificationsPage" */
			'@app/components/Pages/Admin/Notifications/Notifications'
		)),
	params,
);
const Prescriptions = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "Prescriptions" */
			'@app/components/Pages/Admin/Prescriptions/Prescriptions'
		)),
	params,
);
const Medications = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "Prescriptions" */
			'@app/components/Pages/Admin/Medications/Medications'
		)),
	params,
);
const AppointmentInsuranceEditor = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "InsurancePage" */
			'@app/components/Pages/Admin/Appointments/Insurance/AppointmentInsuranceEditor'
		)),
	params,
);
const GlobalPayersCompany = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "GlobalPayersCompany" */
			'@app/components/Pages/Admin/GlobalPayerCompany/GlobalPayersCompany'
		)),
	params,
);
const ClaimPaymentDocumentDetails = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "ClaimPaymentDocumentDetailsPage" */
			'@app/components/Pages/Admin/ClaimPaymentDocumentDetails/ClaimPaymentDocumentDetails'
		)),
	params,
);
const ProfitReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RevenueReport" */ '@app/components/Pages/Admin/ProfitReport/ProfitReport')), params);
const ProcedureReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProcedureReport" */ '@app/components/Pages/Admin/Procedures/ProcedureReport')), params);
const FaxRules = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "FaxRulesPage" */ '@app/components/Pages/Admin/FaxRules/FaxRules')), params);
const TimeReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TimeReport" */ '@app/components/Pages/Admin/TimeReport/TimeReport')), params);
const PatientReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PatientReport" */ '@app/components/Pages/Admin/PatientReport/PatientReport')), params);
const NumbersReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "NumbersReport" */ '@app/components/Pages/Admin/NumbersReport/NumbersReport')), params);
const ProductReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProductReport" */ '@app/components/Pages/Admin/Products/ProductReport')), params);
const PaymentsReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PaymentsReport" */ '@app/components/Pages/Admin/PaymentsReport/PaymentsReport')), params);
const IncomeStatementReport = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "IncomeStatementReport" */
			'@app/components/Pages/Admin/IncomeStatementReport/IncomeStatementReport'
		)),
	params,
);
const CompareReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CompareReport" */ '@app/components/Pages/Admin/CompareReport/CompareReport')), params);
const ProvidersReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProviderProfitReport" */ '@app/components/Pages/Admin/ProvidersReport/ProvidersReport')), params);
const SourceReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AppointmentSourceReport" */ '@app/components/Pages/Admin/SourceReport/SourceReport')), params);
const ProviderReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProviderReport" */ '@app/components/Pages/Admin/ProviderReport/ProviderReport')), params);
const MetricsReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "MetricsReport" */ '@app/components/Pages/Admin/MetricsReport/MetricsReport')), params);
const UserReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UserReport" */ '@app/components/Pages/Admin/UserReport/UserReport')), params);
const ReviewRequestReport = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "ReviewRequestReport" */ '@app/components/Pages/Admin/ReviewRequestReport/ReviewRequestReport')),
	params,
);
const InquiriesReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InquiriesReport" */ '@app/components/Pages/Admin/InquiriesReport/InquiriesReport')), params);
const Instructions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Instructions" */ '@app/components/Pages/Admin/Templates/Instructions')), params);
const Transactions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Transactions" */ '@app/components/Pages/Admin/Transactions/Transactions')), params);
const Terminals = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Terminals" */ '@app/components/Pages/Admin/Terminals/Terminals')), params);
const TransactionViewer = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TransactionViewer" */ '@app/components/Pages/Admin/Transactions/TransactionViewer')), params);
const CustomerProfileViewer = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CustomerProfileViewer" */ '@app/components/Pages/Admin/Transactions/CustomerProfileViewer')), params);
const BillingPlanEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BillingPlanEditor" */ '@app/components/Pages/Admin/BillingPlans/BillingPlanEditor')), params);
const BillingPlans = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BillingPlans" */ '@app/components/Pages/Admin/BillingPlans/BillingPlans')), params);
const SpecialsReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SpecialsReport" */ '@app/components/Pages/Admin/Specials/SpecialsReport')), params);
const AppointmentTypesReport = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "AppointmentTypesReport" */
			'@app/components/Pages/Admin/AppointmentTypesReport/AppointmentTypesReport'
		)),
	params,
);
const GroupReport = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "GroupReport" */ '@app/components/Pages/Admin/GroupReport/GroupReport')),
	params,
);
const DayOffReport = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "DayOffReport" */ '@app/components/Pages/Admin/DayOffReport/DayOffReport')),
	params,
);
const QuotasReport = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "QuotasReport" */ '@app/components/Pages/Admin/QuotasReport/QuotasReport')),
	params,
);
const QuotesReport = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "QuotesReport" */ '@app/components/Pages/Admin/QuotesReport/QuotesReport')),
	params,
);
const Preview = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "Preview" */ '@app/components/Pages/Admin/Preview/Preview')),
	params,
);
const CaseCreator = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "CaseCreator" */ '@app/components/Pages/Admin/Cases/CaseCreator')),
	params,
);
const PhoneCalls = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "PhoneCalls" */ '@app/components/Pages/Admin/PhoneCalls/PhoneCalls')),
	params,
);
const PatientNotifications = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "PatientNotifications" */ '@app/components/Pages/Admin/PatientNotifications/PatientNotifications')),
	params,
);
const BaseHostedServices = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "BaseHostedServicesPage" */
			'@app/components/Pages/Admin/BaseHostedServices/BaseHostedServices'
		)),
	params,
);
const PageAccesses = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "PageAccessesPage" */
			'@app/components/Pages/Admin/PageAccesses/PageAccesses'
		)),
	params,
);
const SmsLogs = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "SmsLogs" */ '@app/components/Pages/Admin/SmsLogs/SmsLogs')),
	params,
);
const EmailLogs = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "EmailLogs" */
			'@app/components/Pages/Admin/EmailLogs/EmailLogs'
		)),
	params,
);
const PageImages = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageImagesPage" */
		'@app/components/Pages/Admin/PageImages/PageImages'
	)), params);
const PageImageEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageImageEditorPage" */
		'@app/components/Pages/Admin/PageImages/PageImageEditor'
	)), params);
const QuestionnaireHistory = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "QuestionnaireHistoryPage" */
			'@app/components/Pages/Admin/QuestionnaireHistory/QuestionnaireHistory'
		)),
	params,
);
const PaymentInquiries = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "PaymentInquiriesPage" */ '@app/components/Pages/Admin/PaymentInquiries/PaymentInquiries')),
	params,
);
const ItemsReport = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "ItemsReportPage" */ '@app/components/Pages/Admin/ItemsReport/ItemsReport')),
	params,
);
const TransactionBatches = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "TransactionBatchesPage" */ '@app/components/Pages/Admin/TransactionBatches/TransactionBatches')),
	params,
);
const InventoriesReport = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "InventoriesReportPage" */ '@app/components/Pages/Admin/InventoriesReport/InventoriesReport')),
	params,
);
const SubscriptionsPage = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "SubscriptionsPage" */ '@app/components/Pages/Admin/Subscription/Subscriptions')),
	params,
);
/* ------------Admin end-------------*/

const Login = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LoginPage" */ '@app/components/Pages/Login/Login')), paramsLogo);
const Recover = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RecoverPage" */ '@app/components/Pages/Recover/Recover')), paramsLogo);
const Confirm = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConfirmPage" */ '@app/components/Pages/Confirm/Confirm')), paramsLogo);
const Confirmation = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConfirmPage" */ '@app/components/Pages/Confirmation/Confirmation')), paramsLogo);
const Register = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RegisterPage" */ '@app/components/Pages/Register/Register')), paramsLogo);
const Profile = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProfilePage" */ '@app/components/Pages/Profile')), paramsLogo);
const PrivacyPolicy = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PrivacyPolicy" */
		'@app/components/Pages/PrivacyPolicy/PrivacyPolicy'
	)), paramsLogo);
const PatientUnsubscribe = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UnsubscribePage" */ '@app/components/Pages/PatientUnsubscribe')), params);
const UserUnsubscribe = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UserUnsubscribePage" */ '@app/components/Pages/UserUnsubscribe')), params);

const AccessDenied = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AccessDenied" */
		'@common/react/components/Pages/AccessDenied/AccessDenied'
	)), paramsLogo);
const ChangeEmail = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ChangeEmail" */
		'@app/components/Pages/ChangeEmail/ChangeEmail'
	)), paramsLogo);
const TaskEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TaskEditor" */ '@app/components/Pages/Admin/Tasks/TaskEditor')), params);
const ExpiredLink = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ExpiredLink" */ '@app/components/Pages/ExpiredLink/ExpiredLink')), params);

export const MainRoutes: React.FC = () => (
	<Switch>
		<MainRoute exact path="/" component={Login} />
		<MainRoute path="/login" component={Login} />
		<OpenPageRoute path="/recover" component={Recover} />
		<OpenPageRoute path="/confirm" component={Confirm} />
		<OpenPageRoute path="/confirmation" component={Confirmation} />
		<OpenPageRoute path="/change-email" component={ChangeEmail} />
		<OpenPageRoute path="/unsubscribe-patient" component={PatientUnsubscribe} />
		<OpenPageRoute path="/unsubscribe-user" component={UserUnsubscribe} />
		<OpenPageRoute path="/expiredLink" component={ExpiredLink} />
		<MainRoute path="/registration" component={Register} />
		<OpenPageRoute path="/privacy-policy" component={PrivacyPolicy} />
		<NotFoundRoute
			path="/access-denied"
			title="403 - ACCESS DENIED"
			component={AccessDenied}
			loginRoute={DashboardRoute}
			openRoute={(props) => <OpenPageRoute {...props} withHeader />}
		/>
		<DashboardRoute path="/calendar" component={Calendar} title="Calendar" />
		<DashboardRoute path="/profile" component={Profile} title="Profile" />
		<DashboardRoute path="/inquiry-list/:page?" component={Inquiries} title="Inquiries" />
		<DashboardRoute path="/sale-list/:page?" component={Sales} title="Sales" />
		<DashboardRoute path="/sale-editor/:id?" component={SaleEditor} />
		<DashboardRoute path="/vitals/:page?" component={Vitals} title="Vitals" />
		<DashboardRoute path="/inquiry-editor/:id" component={Inquiry} />
		<DashboardRoute path="/service-list/:page?" component={Services} title="Services" />
		<DashboardRoute path="/user-list/:page?" component={Users} title="Users" />
		<DashboardRoute path="/user-editor/:id" component={UserEditor} />
		<DashboardRoute path="/user-groups/:page?" component={SystemUserGroups} title="System User Groups" />
		<DashboardRoute path="/region-list/:page?" component={Regions} title="Regions" />
		<DashboardRoute path="/region-editor/:id?" component={RegionEditor} />
		<DashboardRoute path="/page-editor/:id/:type" component={PageEditor} />
		<DashboardRoute path="/page-list/:page?" component={Pages} title="Pages" />
		<DashboardRoute path="/case-list/:page?" component={Cases} title="Before-Afters" />
		<DashboardRoute path="/case-editor/:id" component={CaseEditor} />
		<DashboardRoute path="/documentType-list/:page?" component={DocumentTypes} title="Document Types" />
		<DashboardRoute path="/ledger-report" component={LedgerReport} title="Ledger" />
		<DashboardRoute path="/order-details/:page?" component={OrderDetailsReport} title="Order Details" />
		<DashboardRoute path="/order-list/:page?" component={Orders} title="Orders" />
		<DashboardRoute path="/quote-list/:page?" component={Quotes} title="Quotes" />
		<DashboardRoute path="/refund-list/:page?" component={Refunds} title="Refunds" />
		<DashboardRoute path="/faq-list/:page?" component={Faqs} title="Faqs" />
		<DashboardRoute path="/serviceCall-list/:page?" component={ServiceCalls} title="Service Calls" />
		<DashboardRoute path="/system-faq-list/:page?" component={SystemFaqs} title="System FAQs" />
		<DashboardRoute path="/faq-editor/:id" component={FaqEditor} />
		<DashboardRoute path="/system-faq-editor/:id" component={SystemFaqEditor} />
		<DashboardRoute path="/review-requests/:page?" component={ReviewRequests} title="Review Requests" />
		<DashboardRoute path="/review-list/:page?" component={Reviews} title="Reviews" />
		<DashboardRoute path="/review-editor/:id" component={ReviewEditor} />
		<DashboardRoute path="/portal-specials/:page?" component={PortalHomeSpecials} title="Portal Specials" />
		<DashboardRoute path="/portal-doctors/:page?" component={PortalDoctors} title="Portal Doctors" />
		<DashboardRoute path="/doctor-list/:page?" component={Doctors} title="Providers" />
		<DashboardRoute path="/insurance-editor/:id" component={AppointmentInsuranceEditor} title="Appointment Insurance" />
		<DashboardRoute path="/doctor-editor/:id" component={DoctorEditor} />
		<DashboardRoute path="/location-list/:page?" component={Locations} title="Locations" />
		<DashboardRoute path="/location-editor/:id" component={LocationEditor} />
		<DashboardRoute path="/profession-list/:page?" component={Professions} title="Job Categories" />
		<DashboardRoute path="/honor-list/:page?" component={Honors} title="Awards Page" />
		<DashboardRoute path="/honor-editor/:id" component={HonorEditor} />
		<DashboardRoute path="/dashboard" component={Dashboard} title="Dashboard" />
		<DashboardRoute path="/patient-list/:page?" component={Patients} title="Patients" />
		<DashboardRoute path="/patient-editor/:id" component={PatientEditor} />
		<DashboardRoute path="/appointment-editor/:id" component={AppointmentEditor} />
		<DashboardRoute path="/appointment-list/:page?" component={Appointments} title="Appointments" />
		<DashboardRoute path="/analysis-list/:page?" component={Analyzes} title="Lab. Orders" />
		<DashboardRoute path="/instagram-post-list/:page?" component={InstagramPosts} title="Instagram Posts" />
		<DashboardRoute path="/instagram-post-editor/:id" component={InstagramPostEditor} />
		<DashboardRoute path="/email-list/:page?" component={AppointmentEmails} title="Emails" />
		<DashboardRoute path="/chats" component={ChatsPage} title="Chats" />
		<DashboardRoute path="/support-chats" component={SupportChatsPage} title="Support Chats" />
		<DashboardRoute path="/categories/:page?" component={Categories} title="Categories" />
		<DashboardRoute path="/category-editor/:id" component={CategoryEditor} />
		<DashboardRoute path="/products/:page?" component={Products} title="Products" />
		<DashboardRoute path="/product-editor/:id" component={ProductEditor} />
		<DashboardRoute path="/status-list/:page?" component={Statuses} title="Statuses" />
		<DashboardRoute path="/document-list/:page?" component={Documents} title="Documents" />
		<DashboardRoute path="/prescription-list/:page?" component={Prescriptions} title="E-Rx" />
		<DashboardRoute path="/medications/:page?" component={Medications} title="Medications" />
		<DashboardRoute path="/invoice-list/:page?" component={Invoices} title="Invoices" />
		<DashboardRoute path="/invoice-editor/:id" component={InvoiceEditor} />
		<DashboardRoute path="/global-question-list/:page?" component={GlobalQuestions} title="Global Questions" />
		<DashboardRoute path="/global-question-editor/:id" component={GlobalQuestionEditor} />
		<DashboardRoute path="/medical-question-list/:page?" component={MedicalQuestions} title="Medical Questions" />
		<DashboardRoute path="/medical-question-editor/:id" component={MedicalQuestionEditor} />
		<DashboardRoute path="/template-list/:page?" component={Templates} title="Consent Forms" />
		<DashboardRoute path="/instruction-list/:page?" component={Instructions} title="Instruction Forms" />
		<DashboardRoute path="/template-editor/:id" component={TemplateEditor} />
		<DashboardRoute path="/instruction-editor/:id" component={TemplateEditor} />
		<DashboardRoute path="/email-template/:page?" component={EmailTemplates} title="Email Templates" />
		<DashboardRoute path="/email-template-editor/:id" component={EmailTemplateEditor} />
		<DashboardRoute path="/account-list/:page?" component={Accounts} title="Accounts" />
		<DashboardRoute path="/company-editor" component={CompanyEditor} />
		<DashboardRoute path="/ledger-list/:page?" component={Ledgers} title="Billing" />
		<DashboardRoute path="/ledger-editor/:id" component={LedgerEditor} />
		<DashboardRoute path="/verifications-list/:page?" component={Verifications} title="Verifications" />
		<DashboardRoute path="/system-verifications-list/:page?" component={SystemVerifications} title="Verifications" />
		<DashboardRoute path="/specials-list/:page?" component={SpecialsList} title="Specials" />
		<DashboardRoute path="/specials-editor/:id" component={SpecialsEditor} />
		<DashboardRoute path="/procedure-list/:page?" component={ProcedureList} title="Services" />
		<DashboardRoute path="/procedure-editor/:id" component={ProcedureEditor} />
		<DashboardRoute path="/service-hours/:page?" component={ServiceHours} title="Service Hours" />
		<DashboardRoute path="/payer-list/:page?" component={Payers} title="Payers" />
		<DashboardRoute path="/appointment-type-list/:page?" component={AppointmentTypes} title="Appointment Types" />
		<DashboardRoute path="/contact-list/:page?" component={AddressBook} title="Address Book" />
		<DashboardRoute path="/task-list/:page?" component={Tasks} title="Tasks" />
		<DashboardRoute path="/task-editor/:id" component={TaskEditor} />
		<DashboardRoute path="/work-shift-list/:page?" component={WorkShiftsReport} title="Work Shifts" />
		<DashboardRoute
			path="/user-session-list/:page?"
			component={() => <UserSessions withServerPage popoverClassName="dashboard-filters-component" />}
			title="Entry Log Journal"
		/>
		<DashboardRoute path="/roleschema-list/:page?" component={RoleSchemas} title="Role Schemas" />
		<DashboardRoute path="/notifications/:page?" component={Notifications} title="Notifications" />
		<DashboardRoute path="/day-off-list/:page?" component={DaysOffReport} title="Calendar Events" />
		<DashboardRoute path="/balance-check-report/:page?" component={BalanceReport} title="Balance Check Report" />
		<DashboardRoute path="/insurance-list/:page?" component={AppointmentInsuranceList} title="Claims" />
		<DashboardRoute path="/batch-list/:page?" component={BatchList} title="Batches" />
		<DashboardRoute path="/batch-editor/:id" component={BatchEditor} title="Batch" />
		<DashboardRoute path="/procedure-group-list/:page?" component={ProcedureGroupList} title="Service Groups" />
		<DashboardRoute path="/fax-list/:page?" component={FaxEventList} title="Faxes" />
		<DashboardRoute path="/claim-payment-documents/:page?" component={ClaimPaymentDocuments} title="Checks" />
		<DashboardRoute path="/claim-payment-documents-details/:id" component={ClaimPaymentDocumentDetails} title="Check Details" />
		<DashboardRoute path="/claim-reports/:page?" component={ClaimReports} title="Claim Reports" />
		<DashboardRoute path="/claim-report/:id" component={ClaimReportViewer} title="Claim Report" />
		<DashboardRoute path="/claim-payments/:page?" component={ClaimPayments} title="Claim Payments" />
		<DashboardRoute path="/claim-payment/:id" component={ClaimPaymentPage} title="Claim Payment" />
		<DashboardRoute path="/global-payer-company-list/:page?" component={GlobalPayersCompany} title="Global Payers" />
		<DashboardRoute path="/revenue-report" component={ProfitReport} title="Profit Report" />
		<DashboardRoute path="/procedure-report" component={ProcedureReport} title="Procedure Report" />
		<DashboardRoute path="/time-report" component={TimeReport} title="Apt. Duration Report" />
		<DashboardRoute path="/fax-rule-list/:page?" component={FaxRules} title="Fax Rules" />
		<DashboardRoute path="/numbers-report" component={NumbersReport} title="Numbers Report" />
		<DashboardRoute path="/product-report" component={ProductReport} title="Product Report" />
		<DashboardRoute path="/payments-report" component={PaymentsReport} title="Payments Report" />
		<DashboardRoute path="/income-report" component={IncomeStatementReport} title="Revenue Report" />
		<DashboardRoute path="/review-request-report" component={ReviewRequestReport} title="Review Request Report" />
		<DashboardRoute path="/compare-report" component={CompareReport} title="Comparison Report" />
		<DashboardRoute path="/providers-report" component={ProvidersReport} title="Providers Report" />
		<DashboardRoute path="/source-report" component={SourceReport} title="Source Report" />
		<DashboardRoute path="/provider-report" component={ProviderReport} title="Time Report" />
		<DashboardRoute path="/metrics-report" component={MetricsReport} title="Metrics Report" />
		<DashboardRoute path="/items-report" component={ItemsReport} title="Items Report" />
		<DashboardRoute path="/inventories-report" component={InventoriesReport} title="Inventory Report" />
		<DashboardRoute path="/user-report" component={UserReport} title="User Report" />
		<DashboardRoute path="/inquiries-report" component={InquiriesReport} title="Inquiries Report" />
		<DashboardRoute path="/transaction-list/:page?" component={Transactions} title="Transactions" />
		<DashboardRoute path="/transaction-editor/:id" component={TransactionViewer} title="Transaction" />
		<DashboardRoute path="/terminal-list/:page?" component={Terminals} title="Terminals" />
		<DashboardRoute path="/customer-profile/:id" component={CustomerProfileViewer} title="Customer Profile" />
		<DashboardRoute path="/billing-plan-editor/:id" component={BillingPlanEditor} title="Billing Plan Editor" />
		<DashboardRoute path="/billing-plan-list/:page?" component={BillingPlans} title="Billing Plans" />
		<DashboardRoute path="/specials-report" component={SpecialsReport} title="Specials Report" />
		<DashboardRoute path="/appointment-types-report" component={AppointmentTypesReport} title="Appointment Types Report" />
		<DashboardRoute path="/group-report" component={GroupReport} title="Service Groups Report" />
		<DashboardRoute path="/dayoff-report" component={DayOffReport} title="Days Off Report" />
		<DashboardRoute path="/quotas-report" component={QuotasReport} title="Orders Time Report" />
		<DashboardRoute path="/quotes-report" component={QuotesReport} title="Quotes Report" />
		<DashboardRoute path="/patients-report" component={PatientReport} title="Patients Report" />
		<DashboardRoute path="/phone-call-list" component={PhoneCalls} title="Phone Calls" />
		<DashboardRoute path="/patient-notifications" component={PatientNotifications} title="Patient Notifications" />
		<DashboardRoute path="/base-hosted-service-list/:page?" component={BaseHostedServices} title="Hosted Services" />
		<DashboardRoute path="/page-access-list/:page?" component={PageAccesses} title="Page Accesses" />
		<DashboardRoute path="/smsLogs" component={SmsLogs} title="Sms Logs" />
		<DashboardRoute path="/emailLogs" component={EmailLogs} title="Email Logs" />
		<DashboardRoute path="/page-image-list/:page?" component={PageImages} title="Page Images" />
		<DashboardRoute path="/page-image-editor/:id" component={PageImageEditor} />
		<DashboardRoute path="/questionnaire-history" component={QuestionnaireHistory} title="Questions History" />
		<DashboardRoute path="/payment-inquiries" component={PaymentInquiries} title="Payment Inquiries" />
		<DashboardRoute path="/authLink-list/:page?" component={(props) => <AuthLinks filters={<AuthLinksFilters />} />} title="Auth Links" />
		<DashboardRoute path="/sync-time-list/:page?" component={SyncTimes} title="Sync Info" />
		<DashboardRoute path="/subscriptions/:page?" component={SubscriptionsPage} title="Subscriptions" />
		<DashboardRoute dashboard={false} path="/wizard" component={Wizard} />
		<DashboardRoute dashboard={false} path="/appointment-wizard/:id" component={AppointmentWizard} />
		<DashboardRoute dashboard={false} path="/checkout/:id" component={Checkout} />
		<DashboardRoute dashboard={false} path="/card-payment/:orderId" component={CardPayment} />
		<DashboardRoute dashboard={false} path="/preview-creator/:id" component={Preview} />
		<DashboardRoute dashboard={false} path="/case-creator/:id" component={CaseCreator} />
		<DashboardRoute path="/payment-batches" component={TransactionBatches} title="Transaction Batches" />
		<NotFoundRoute path="*" loginRoute={DashboardRoute} openRoute={(props) => <OpenPageRoute {...props} withHeader />} />
	</Switch>
);

export const routes = (
	<Layout>
		<MainRoutes />
	</Layout>
);
