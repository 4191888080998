import * as React from 'react';

import Select, { SelectProps } from 'antd/lib/select';

import { UserGroupTypeNames } from '@commonTuna/react/objects/UserGroup';

export interface UserGroupFilterProps {
	additionalParams: any;
	param: string;
	afterChange?: (key, value) => void;
	disabled?: boolean;
	className?: string;
	onChange: (evt) => void;
	placeholder?: string;
	defaultValue?: string | number | null | Array<any>;
	selectProps?: SelectProps;
	currentValue?: any;
	showSystemUserGroup?: boolean;
}

const Option = Select.Option;

const UserGroupFilter: React.FunctionComponent<UserGroupFilterProps> = (props) => {
	const {
		additionalParams,
		param,
		afterChange,
		disabled,
		className,
		onChange,
		placeholder,
		defaultValue = -1,
		selectProps,
		currentValue,
		showSystemUserGroup = false,
	} = props;

	return (
		<div className={className || 'select-filter-component'}>
			<Select
				value={currentValue || additionalParams[param] || defaultValue}
				onChange={(value, option) => {
					const handleChangeEventElement = {
						currentTarget: {
							name: param,
							value,
							option,
						},
					};
					onChange(handleChangeEventElement);
					afterChange && afterChange(param, value);
				}}
				disabled={disabled}
				placeholder={placeholder}
				{...selectProps}
			>
				<Option value="">All flags</Option>
				{Object.keys(UserGroupTypeNames)
					.filter((key) => !Number.isNaN(Number(key))
						&& (+key < 100 || showSystemUserGroup)
						&& (+key >= 1000 || !showSystemUserGroup))
					.map((key) => (
						<Option value={key} key={key}>{UserGroupTypeNames[key]}</Option>
					))}
			</Select>
		</div>
	);
};

export default UserGroupFilter;
