import React from 'react';

import Message from 'antd/lib/message';
import Tooltip from 'antd/lib/tooltip';

import { BaseParams } from '@common/react/objects/BaseParams';
import useRequest from '@common/react/hooks/useRequest';
import { isUndefined } from '@common/react/utils/guards';

import { UserFilters } from '@app/objects/UserFilters';

interface Props {
	actorType: string;
	filters?: BaseParams;
	userFilters?: UserFilters;
	afterSave?: (res) => void;
	messageGroup?: string;
	className?: string;
}

const AddUserFiltersButton: React.FC<Props> = ({
	filters, actorType, messageGroup, afterSave, userFilters, className = 'ml10 btn btn-sm btn-primary',
}) => {
	const request = useRequest();

	const [name, setName] = React.useState<string>(userFilters?.name || '');

	const saveCurrentFilters = () => request('userFilters', userFilters ? { ...userFilters, name, actorType } : {
		name,
		actorType,
		messageGroup,
		jsonMessage: JSON.stringify(filters || {}, (key, value) => {
			if (!value && Array.isArray(filters?.[key])) {
				return [];
			}

			return isUndefined(value) ? null : value;
		}),
	});

	return (
		<>
			<Tooltip
				trigger="click"
				placement="topLeft"
				title={<div className="is-relative input-group user-filter-name">
					<input
						placeholder="Name"
						className="form-control"
						value={name}
						onChange={(e) => {
							setName(e.currentTarget.value);
						}}
					/>
					<div className="text-end input-group-btn">
						<button
							type="button"
							className="btn btn-sm btn-primary"
							title="Save"
							disabled={userFilters ? userFilters.name === name : false}
							onClick={() => {
								saveCurrentFilters()
									.then((res) => {
										if (!userFilters) {
											setName('');
										}
										afterSave && afterSave(res);
									}).catch((message) => Message.error(message));
							}}
						>
							Save
						</button>
					</div>
				</div>}
			>
				<button
					type="button"
					className={className}
					title={userFilters ? 'Edit' : 'Add'}
				>
					<i className={`fa fa-${userFilters ? 'edit' : 'plus'}`} />
				</button>
			</Tooltip>
		</>
	);
};

export default AddUserFiltersButton;
