import * as React from 'react';

import * as Yup from 'yup';
import {
	Field, FieldProps, Form,
} from 'formik';
import Modal from 'antd/lib/modal';
import Message from 'antd/lib/message';

import { Chat } from '@common/react/components/Chat/Chat';
import Button from '@common/react/components/Forms/Button';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import { simpleStringValidator } from '@common/react/utils/validationHelpers';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';

const validationSchema = Yup.object().shape({
	name: simpleStringValidator,
});

interface ChatNameEditorProps {
	render: (show: () => void) => void;
	onSave: (chat: Chat) => void;
	chat: Partial<Omit<Chat, 'id' | 'name'>> & {id: number, name: string};
}

interface ChatNameEditorState {
	popupShow: boolean;
}

interface FormValues {
	id: number;
	name: string;
}

const ChatNameEditor:React.FC<ChatNameEditorProps> = (props) => {
	const [state, setState] = React.useState<ChatNameEditorState>({
		popupShow: false,
	});

	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests } } = context;

	const showPopup = () => setState((prev) => ({ ...prev, popupShow: true }));
	const popupHide = () => setState((prev) => ({ ...prev, popupShow: false }));

	return <>
		{props.render(showPopup)}
		<Modal
			title="Edit Chat Name"
			open={state.popupShow}
			onCancel={popupHide}
			destroyOnClose
			width={450}
			footer={null}
		>
			<ItemProvider
				id={props.chat.id}
				type={requests.chat}
				saveRequest={requests.chat}
				readonly={false}
				validationSchema={validationSchema}
				add={{
					id: props.chat.id,
					name: props.chat.name || '',
				} as FormValues}
				item={{
					id: props.chat.id,
					name: props.chat.name || '',
				} as FormValues}
				skipInitLoad
				onSaveRequestError={Message.error}
			>
				<ItemEditor<FormValues>
					afterSubmit={(item, result) => {
						setState((prev) => ({
							popupShow: false,
						}));

						props.onSave({ ...result, ...props.chat, name: result.name });
					}}
					formikProps={{
						enableReinitialize: true,
					}}
					showMessages={false}
					formProps={{
						id: 'new-chat-message',
					}}
					edit={(formikBag, _, { loading }) => {
						return <>
							<div className="row">
								<Field name="name">
									{({ field }: FieldProps<string, FormValues>) =>
										<div className="form-group col-sm-12">
											<div className="is-relative input-group">
												<input type="text" className="form-control" {...field} />
												<div className="input-group-btn">
													<Button
														disabled={!(field.value?.trim())}
														isLoading={loading}
														className="btn btn-primary"
													>
														Save
													</Button>
												</div>
											</div>
										</div>
									}
								</Field>
							</div>
						</>;
					}}
				/>
			</ItemProvider>
		</Modal>
	</>;
};

export default ChatNameEditor;
