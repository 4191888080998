import * as React from 'react';

import { ChatPlugin } from '@common/react/components/Chat/Chat';

import TypingComponent from '@common/react/components/Chat/TypingPlugin/TypingComponent';
import TypingWrapper from '@common/react/components/Chat/TypingPlugin/TypingWrapper';

export const TypingPlugin : ChatPlugin = {
	messageControlWrapper: ({ chat, render }) => <TypingWrapper
		chat={chat}
		render={render}
	/>,
	listComponent: ({ chat }) => <>
		<TypingComponent
			chat={chat}
			render={(contacts) => {
				const res = contacts.map((contact) => `${contact.firstName} ${contact.lastName}`).join(', ');
				const text = `${res} ${contacts.length > 1 ? ' are typing' : ' is typing'}`;

				return <div className="tag-chat__typings-container">
					{!!contacts.length && <div className="tag-chat__typing" title={text}>
						{text}
					</div>}
				</div>;
			}}
		/>
	</>,
	options: {
		delay: 5000, // ms
	},
};
