import React from 'react';
import { useDispatch } from 'react-redux';

import { bindActionCreators } from 'redux';

import { NotificationAction } from '@common/typescript/objects/NotificationAction';
import { ItemProviderContext, useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Notification } from '@common/typescript/objects/Notification';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { getActionCreators } from '@common/react/store/Item';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

interface ItemProviderSynchronizerProps {
	objectType: string;
	objectSubtype?: string;
	getId?: (item) => any;
	customHandler?: (notification: any, context: ItemProviderContext<WithDeleted>) => void;
	handle?: (notification: Notification<BaseUser>, context: ItemProviderContext<any>) => void;
	deleteHandler?: (item: any, notification: Notification<BaseUser>) => void;
	storeName?: string;
	storeHandler?: (notification: Notification<BaseUser>, actions: { updateItem: any }) => void;
}

const ItemProviderSynchronizer: React.FC<ItemProviderSynchronizerProps> = (props) => {
	const {
		getId = (value) => value?.id, objectType, objectSubtype, customHandler, handle, deleteHandler,
		storeName, storeHandler: storeHandlerProps,
	} = props;
	const context = useItemProviderContext<any>();
	const dispatch = useDispatch();
	const actions = bindActionCreators(getActionCreators(), dispatch);
	const { subscribe } = useApplicationContext();

	if (!context.state) throw 'Need ItemProvider context!';

	const {
		state: { item },
		actions: { setItem },
	} = context;

	const storeHandlerDefault = (notification, actions) => {
		if (storeName) {
			const data = notification.data;
			if (notification.objectType === objectType) {
				const item = data.entity ? { ...data.entity, id: data.id } : data;
				if (notification.action === NotificationAction.Update && (data?.entity || !objectType.toLowerCase().includes('redux'))) {
					actions.updateItem(storeName, item);
				}
			}
		}
	};

	const storeHandler = storeHandlerProps || storeHandlerDefault;

	const defaultHandler = (notification) => {
		if (getId(notification.data) === getId(item)) {
			if (notification.action === NotificationAction.Update) {
				return setItem((prev) => ({ ...prev, ...notification.data }));
			}

			if (notification.action === NotificationAction.Delete) {
				deleteHandler && deleteHandler(item, notification);
			}
		}
	};

	const byObjectSubtype = (notification: Notification<BaseUser>) =>
		notification.objectType === objectType && notification.service && objectSubtype && notification.objectSubtype === objectSubtype;

	const _handle = (notification: Notification<BaseUser>) => {
		if (handle) {
			handle(notification, context);

			return;
		}

		if (byObjectSubtype(notification)) {
			if (notification.data) {
				customHandler ? customHandler(notification, context) : defaultHandler(notification);
				storeHandler(notification, actions);
			}
			return;
		}

		if (notification.objectType === objectType && notification.service && !objectSubtype) {
			if (notification.data) {
				customHandler ? customHandler(notification, context) : defaultHandler(notification);
				storeHandler(notification, actions);
			}
		}
	};

	React.useEffect(subscribe(_handle), [item]);

	return <></>;
};

export default React.memo(ItemProviderSynchronizer);
