import * as React from 'react';

import { FormikProps } from 'formik';
import Tag from 'antd/lib/tag';
import loadable from '@loadable/component';

import {
	ChatFormButtonsProps,
	ChatMessageType,
	NewMessage,
	ChatPlugin,
	ChatMessage,
} from '@common/react/components/Chat/Chat';
import VoiceMessageRecorder from '@common/react/components/UI/VoiceMessage/VoiceMessageRecorder';
import VoiceMessageChatPlayer from '@common/react/components/UI/VoiceMessage/VoiceMessageChatPlayer';
import { loadableDelay } from '@common/react/loadable/loadableSettings';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

const params = {
	fallback: <Loader />,
};

const TextChatMessage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TextChatMessage" */ '@common/react/components/Chat/TextChatMessage')), params);

const VoiceMessageButton = (formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) =>
	<React.Fragment key="voice">
		<VoiceMessageRecorder
			disabled={props.isEditMessage}
			shape="default"
			waveColor={props.waveColor}
			className="btn-default chat-message-item"
			recordAudioResult={undefined}
			setRecordAudioResult={(recordAudioResult) => props.setState((prev) => ({ ...prev, recordAudioResult }))}
			onChange={(value) => {
				props.setState((prev) => ({ ...prev, recordVideoResult: undefined }));

				if (!value) {
					formikBag.setFieldValue('chatMessageType', ChatMessageType.Regular);
					formikBag.setFieldValue('bytes', []);
					return;
				}

				value?.blob.arrayBuffer()
					.then((buffer) => {
						const byteArray = new Uint8Array(buffer);
						formikBag.setFieldValue('bytes', Array.from(byteArray));
						formikBag.setFieldValue('chatMessageType', ChatMessageType.VoiceMessage);
					});
			}}
		/>
	</React.Fragment>;

const VoiceMessage = ({
	message, contacts, withRemoteId, onMouseEnter,
}) => <div>
	<VoiceMessageChatPlayer
		voiceMessageUrl={message.files.find((f) => f.file.name === 'voice_message.mp3')
			? `${withRemoteId ? '/remote' : ''}/${message.files.find((f) => f.file.name === 'voice_message.mp3')?.file.src}` : undefined}
		onPlayed={() => onMouseEnter && onMouseEnter(message)}
	/>
	<TextChatMessage text={message.text} contacts={contacts} className="voice_message__text" />
</div>;

const LastMessage = ({ message, userId }) => <div className="chat-list__item-last-message">
	{message.userId === userId ? 'You: ' : ''}
	<i className="fa fa-file-audio-o" style={{ marginRight: 5, marginLeft: message.userId === userId ? 5 : 0 }} />
	voice
	message
</div>;

const FormTag = (formikBag: FormikProps<NewMessage>, { state, setState, waveColor }) => state.recordAudioResult
	&& <Tag
		className="chat-file-tag chat-audio-tag"
		title="Voice Message"
	>
		<VoiceMessageRecorder
			shape="default"
			waveColor={waveColor}
			className="btn-default"
			recordAudioResult={state.recordAudioResult}
			setRecordAudioResult={(recordAudioResult) => setState((prev) => ({ ...prev, recordAudioResult }))}
			onChange={(value) => {
				setState((prev) => ({ ...prev, recordVideoResult: undefined }));

				if (!value) {
					formikBag.setFieldValue('bytes', []);
					formikBag.setFieldValue('chatMessageType', ChatMessageType.Regular);
					return;
				}

				value?.blob.arrayBuffer()
					.then((buffer) => {
						const byteArray = new Uint8Array(buffer);
						formikBag.setFieldValue('bytes', Array.from(byteArray));
						formikBag.setFieldValue('chatMessageType', ChatMessageType.VoiceMessage);
					});
			}}
		/>
	</Tag>;

export const VoiceMessagePlugin: ChatPlugin = {
	formButton: VoiceMessageButton,
	message: {
		render: ({
			message,
			contacts,
			withRemoteId,
			onMouseEnter,
		}) => {
			const src = message.files.find((f) => f.file.name === 'voice_message.mp3')?.file.src;
			return <>
				{VoiceMessagePlugin.options.transcribeButton(src, message)}
				{VoiceMessage({
					message,
					contacts,
					withRemoteId,
					onMouseEnter,
				})}
			</>;
		},
		lastMessage: LastMessage,
		notification: ({ message, withRemoteId, contacts }) => {
			return <>Voice Message</>;
		},
	},
	formTag: FormTag,
	options: {
		transcribeButton: (source: string, chatMessage: ChatMessage) => null,
	},
};
