import { Nullable } from '@common/typescript/objects/Nullable';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';

import { DrugClass } from '@commonTuna/react/objects/DrugClass';

import { RxDrug } from '@app/objects/RxDrug';

export enum AllergyTypeEnum {
	SpecificDrug,
	NonDrug,
	NoKnown,
	SpecificDrugClass
}

export enum AllergyReactionType {
	SkinRash,
	Itching,
	Swelling,
	Wheezing,
	Anaphylaxis,
	Other
}

export interface Allergy extends WithDeleted {
	patientId: number;
	allergyType: AllergyTypeEnum;

	rxDrug: Nullable<RxDrug>;
	rxDrugId: Nullable<number>;

	drugClassId: Nullable<number>;
	drugClass: Nullable<DrugClass>;

	nonDrugDescription: string;
	notes: string;
	reactionType: AllergyReactionType;
	reactionTypeNotes: string;
	lifeThreatening: boolean;
}

export const AllergyTypeName = {
	[AllergyTypeEnum.SpecificDrug]: 'Specific Drug Allergy',
	[AllergyTypeEnum.NonDrug]: 'Non-Drug Allergy',
	[AllergyTypeEnum.NoKnown]: 'No Known Drug Allergies',
	[AllergyTypeEnum.SpecificDrugClass]: 'Specific Drug Class',
};

export const AllergyReactionTypeName = {
	[AllergyReactionType.Anaphylaxis]: 'Anaphylaxis',
	[AllergyReactionType.Itching]: 'Itching',
	[AllergyReactionType.Other]: 'Other',
	[AllergyReactionType.SkinRash]: 'Skin Rash',
	[AllergyReactionType.Swelling]: 'Swelling',
	[AllergyReactionType.Wheezing]: 'Wheezing',
};
