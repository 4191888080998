import React from 'react';

import { ChatSettingsProvider, ChatSettingsProviderProps } from '@common/react/components/Chat/ChatSettingsProvider';
import { ChatPlugins } from '@common/react/components/Chat/Chat';
import { VideoMessagePlugin } from '@common/react/components/Chat/VideoMessagePlugin/VideoMessagePlugin';
import { VoiceMessagePlugin } from '@common/react/components/Chat/VoiceMessage/VoiceMessagePlugin';
import { TypingPlugin } from '@common/react/components/Chat/TypingPlugin/TypingPlugin';
import { MentionPlugin } from '@common/react/components/Chat/MentionPlugin/MentionPlugin';
import { FilePlugin } from '@common/react/components/Chat/FilesPlugin/FilesPlugin';
import { EmojiPlugin } from '@common/react/components/Chat/EmojiPlugin/EmojiPlugin';
import { ColorboxPlugin } from '@common/react/components/Chat/ColorboxPlugin/ColorboxPlugin';
import { LinkPreviewPlugin } from '@common/react/components/Chat/LinkPreviewPlugin/LinkPreviewPlugin';
import { EmailPlugin } from '@common/react/components/Chat/Email/EmailPlugin';

type Props = ChatSettingsProviderProps

const ChatSettingsProviderWithMorePlugins: React.FC<Props> = (props) => {
	return <ChatSettingsProvider
		otherComponents={[ChatPlugins.ColorBox]}
		formTags={[ChatPlugins.VideoMessage, ChatPlugins.VoiceMessage]}
		listComponent={[ChatPlugins.Typing]}
		messageControlWrappers={[ChatPlugins.Typing]}
		messageControl={ChatPlugins.Mentions}
		{...props}
		plugins={{
			[ChatPlugins.VideoMessage]: VideoMessagePlugin,
			[ChatPlugins.VoiceMessage]: VoiceMessagePlugin,
			[ChatPlugins.Typing]: TypingPlugin,
			[ChatPlugins.Mentions]: MentionPlugin,
			[ChatPlugins.Files]: FilePlugin,
			[ChatPlugins.Emoji]: EmojiPlugin,
			[ChatPlugins.ColorBox]: ColorboxPlugin,
			[ChatPlugins.LinkPreview]: LinkPreviewPlugin,
			[ChatPlugins.Email]: EmailPlugin,
			...props.plugins,
		} as any}
	>
		{props.children}
	</ChatSettingsProvider>;
};

export default ChatSettingsProviderWithMorePlugins;
