import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import once from 'lodash/once';

import ItemsProviderWithStore from '@common/react/components/Core/ItemsProviderWithStore/ItemsProviderWithStore';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';

import { AppointmentDotSetting, HiddenAppointmentDots } from '@app/objects/HiddenAppointmentDots';

export interface HiddenAppointmentDotsProviderContext {
	isDotVisible: (setting: AppointmentDotSetting) => boolean;
}

export const createHiddenAppointmentDotsProviderContext = once(() => React.createContext({} as HiddenAppointmentDotsProviderContext));

export const useHiddenAppointmentDotsProviderContext: () => HiddenAppointmentDotsProviderContext = () =>
	React.useContext(createHiddenAppointmentDotsProviderContext());

const HiddenAppointmentDotsSetter: React.FC = ({ children }) => {
	const HiddenAppointmentDotsProviderContext = createHiddenAppointmentDotsProviderContext();
	const user : BaseUser | null = useSelector((state: BaseApplicationState<BaseUser>) => state.login.user, shallowEqual);

	return <ItemsProviderWithStore<HiddenAppointmentDots>
		storeName="hiddenAppointmentDots"
		type="hiddenAppointmentDots"
		loadRequest="hiddenAppointmentDotsList"
		unhandledFilters={{ userId: user ? user.id : undefined }}
		pagination={{ pageSize: 10, total: 10 }}
		skipInitLoad
	>
		{
			(state) => {
				const isDotVisible = (setting: AppointmentDotSetting) => state.items
					.map((item) => item.appointmentDotSetting)
					.indexOf(setting) === -1;

				return (
					<HiddenAppointmentDotsProviderContext.Provider value={{ isDotVisible }}>
						{children}
					</HiddenAppointmentDotsProviderContext.Provider>
				);
			}
		}
	</ItemsProviderWithStore>;
};

export default HiddenAppointmentDotsSetter;
