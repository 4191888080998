import React from 'react';

import Popover from 'antd/lib/popover';

import 'emoji-mart/css/emoji-mart.css';
import loadable from '@loadable/component';

import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { loadableDelay } from '@common/react/loadable/loadableSettings';

const params = {
	fallback: <Loader />,
};

const EmojiPicker = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EmojiPicker" */ '@common/react/components/UI/EmojiPicker/EmojiPicker')), params);

interface Props {
	onSelect: (emoji) => void;
	className?: string;
	triggerNode?: React.ReactNode;
	getPopupContainer?: (node) => HTMLElement;
}

const EmojiComponent: React.FC<Props> = (props) => {
	const { className = 'chat-message-item', onSelect, getPopupContainer } = props;
	const { triggerNode = <i className="btn btn-primary fa fa-smile-o fa-2x chat-message-item-ptr" aria-hidden="true" /> } = props;
	return (
		<Popover
			className={className}
			placement="topLeft"
			trigger="click"
			getPopupContainer={getPopupContainer}
			content={
				<EmojiPicker
					emojiSize={24}
					showPreview
					showSkinTones={false}
					native={false}
					set="apple"
					emoji="point_up"
					title="Pick your emoji…"
					emojisToShowFilter={(emoji: any) => !emoji.unified.includes('-') && emoji.added_in === '2.0'}
					onSelect={onSelect}
				/>
			}
		>
			{triggerNode}
		</Popover>
	);
};

export default EmojiComponent;
