import React from 'react';

import Switch from 'antd/lib/switch';

interface ComponentProps {
	checked: boolean;
	onClick: (checked: boolean, event: any) => void;
	style?: any;
}

const AccrualSwitcher: React.FC<ComponentProps> = ({
	checked,
	onClick,
	style,
}) => {
	return (
		<>
			<Switch
				className="accrual-switch"
				title="Changing received value type"
				checked={checked}
				style={style}
				onClick={onClick}
				unCheckedChildren="Cash-Based"
				checkedChildren="Accrual"
			/>
		</>
	);
};

export default AccrualSwitcher;
