import React from 'react';

interface Options {
	title?: string;
	interval?: number;
}

interface TimerRef {
	title?: string;
	timer?: number;
	toggle?: boolean;
}

const useFlashingTitle = ({ title, interval = 800 }: Options) => {
	const timerRef = React.useRef<TimerRef>({});

	React.useEffect(() => {
		if (title) {
			const ms = interval > 100 ? interval : 100;
			timerRef.current.title = document.title;
			timerRef.current.timer = window.setInterval(() => {
				if ((timerRef.current.toggle && title !== document.title) || !timerRef.current.toggle) {
					timerRef.current.title = document.title;
				}
				if (timerRef.current.title) {
					document.title = !timerRef.current.toggle ? title : timerRef.current.title;
				}
				timerRef.current.toggle = !timerRef.current.toggle;
			}, ms);

			return () => {
				if (timerRef.current.title) {
					document.title = timerRef.current.title;
				}
				window.clearTimeout(timerRef.current.timer);
				timerRef.current.toggle = false;
				return undefined;
			};
		}
		return undefined;
	}, [title, interval]);
};

export default useFlashingTitle;
