import React from 'react';

import Select, { SelectProps } from 'antd/lib/select';

import Autocomplete, { AutocompleteProps } from '@common/react/components/Forms/Autocomplete/Autocomplete';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import ItemsSelect from '@common/react/components/UI/ItemsSelect/ItemsSelect';
import { useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { BaseParams } from '@common/react/objects/BaseParams';
import ItemsProviderWithStore from '@common/react/components/Core/ItemsProviderWithStore/ItemsProviderWithStore';
import ItemsProviderSynchronizer from '@common/react/components/Core/AdvancedItemsProvider/ItemsProviderSynchronizer';
import { SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';

import { Location } from '@app/objects/Location';
import { NullableLocation } from '@app/components/Pages/Admin/BaseReport/BaseReport';

interface Props {
	onChange: (evt: { currentTarget: { name: string, value } }, location?: Location | null) => void;
	asSelect?: boolean;
	withEye?: boolean;
	location?: Location | null;
	request?: string;
	params?: any;
	autocompleteProps?: Partial<AutocompleteProps>;
	selectProps?: Partial<Omit<SelectProps, 'onChange' | 'getPopupContainer'>>;
	onLocationsLoaded?: (locations: Array<Location>) => void;
	loadRequest?: string;
	withoutAllLocation?: boolean;
	param?: string;
	skipInitLoad?: boolean;
	locations?: Array<Location>;
	allValueName?: string;
	withNullableLocation?: boolean;
	nullableLocationName?: string;
	getPopupContainer?: (node) => HTMLElement;
	withoutStore?: boolean;
	loading?: boolean;
	storeName?: string;
	pagination?: any;
	ignoreServerPage?: boolean;
	additionalFilters?: BaseParams;
}

const defaultStorageName = 'locations';

export const LocationListSynchronizer: React.FC<{ render?: any, withStore?: boolean }> = ({ render, withStore = true }) => {
	return <ItemsProviderWithStore<Location>
		defaultSort={['id', SortingDirection.Ascending]}
		filterHandler={() => false}
		storeName={defaultStorageName}
		type="location"
		pagination={{ current: 1, pageSize: 100 }}
	>
		<ItemsProviderSynchronizer
			objectType="LocationRedux"
			storeName={defaultStorageName}
			getItemsFromInit={(notification) => notification.data.userLocations}
		/>
	</ItemsProviderWithStore>;
};

const { Option } = Select;

const LocationSelect: React.FC<Props> = ({
	asSelect = true, location, withEye, params, ...rest
}) => {
	const {
		selectProps, autocompleteProps, onChange, loadRequest = 'locationViewList', withoutAllLocation,
		param = 'locationId', loading, locations: defaultLocations, ignoreServerPage = true, onLocationsLoaded,
		storeName, withoutStore, skipInitLoad,
		allValueName = 'All Locations',
		pagination = { pageSize: 100, pageSizeOptions: [100] },
		withNullableLocation = false,
		nullableLocationName = 'No location',
		additionalFilters = { pageSize: 100 },
	} = rest;
	const context = useItemProviderContext<any>(false);
	const pageData = context?.state?.item;
	const serverPageLocations = ignoreServerPage ? undefined
		: pageData?.innerPage?.filterProps?.locations || pageData?.filterProps?.locations;

	const component = asSelect
		? (
			<ItemsSelect<Location>
				getOptionProps={(item) => ({
					value: item.id,
					item,
					children: item.nameEn,
				})}
				selectProps={{
					placeholder: 'Location...',
					...selectProps,
					value: selectProps?.value || +(params?.[param] || (withoutAllLocation ? 0 : -1)) || undefined,
					getPopupContainer: rest.getPopupContainer,
					onChange: (value, option: any) => onChange({ currentTarget: { name: param, value } }, option?.item || null),
				}}
				pagination={pagination}
				onLoad={(res) => onLocationsLoaded && onLocationsLoaded(res.list)}
				loading={loading}
				loadingPlaceholder="Location..."
				filters={additionalFilters}
				items={defaultLocations || serverPageLocations}
				storeName={storeName || (withoutStore ? undefined : defaultStorageName)}
				type="location"
				loadRequest={loadRequest}
				skipInitLoad={skipInitLoad}
				additionalOptions={[
					withoutAllLocation ? null : <Option key="-1" value={-1}>{allValueName}</Option>,
					withNullableLocation ? <Option key="empty" value={NullableLocation}>{nullableLocationName}</Option> : null,
				]}
				itemsProviderSynchronizerProps={{
					objectType: 'LocationRedux',
				}}
				defaultSort={['id', SortingDirection.Ascending]}
				filterHandler={() => true}
				sortHandler={(a, b) => a.id - b.id}
			/>
		)
		: (
			<Autocomplete<Location>
				loadOnFocus
				{...autocompleteProps}
				params={params}
				type={loadRequest}
				renderTitle="nameEn"
				onSelect={(value, option) => onChange({ currentTarget: { name: param, value } }, option?.props?.item)}
				onChange={(value) => !value && onChange({ currentTarget: { name: param, value: null } }, null)}
			/>
		);

	return withEye ? (
		<div className="input-group">
			{component}
			<div className="input-group-btn">
				<LinkWithPrevLocation
					to={location ? {
						pathname: `/location-editor/${location.id}`,
						search: 'mode=view',
					} : ''}
					className={`btn btn-sm btn-primary ${!location ? 'disabled' : ''}`}
					title="View Location"
				>
					<i className="fa fa-eye" />
				</LinkWithPrevLocation>
			</div>
		</div>
	) : <>{component}</>;
};

export default LocationSelect;
