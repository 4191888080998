import React from 'react';

import Radio, { RadioChangeEvent } from 'antd/lib/radio';

import { objectByWorkingTimes } from '@app/components/Pages/Admin/BaseReport/BaseReport';

interface Props {
	value: boolean | undefined;
	onChange: (value: boolean | undefined) => void;
	withIcons?: boolean;
	className?: string;
	disabled?: boolean;
}

const WorkingTimeFilter: React.FC<Props> = (props) => {
	const {
		value, onChange, withIcons, className = 'pull-right ml10 mb10 custom-radio', children, disabled,
	} = props;
	const handleChange = (e: RadioChangeEvent) => {
		onChange(e.target.value);
	};

	return (
		<Radio.Group
			defaultValue={false}
			className={className}
			name="byWorkingTimes"
			value={value ?? undefined}
			onChange={handleChange}
			disabled={disabled}
		>
			{children || objectByWorkingTimes.map((item, index) => (
				<Radio.Button value={item.value} key={item.caption}>
					<div className="custom-radio-button" title={item.caption}>
						{withIcons && !item.value ? <div className="">All</div>
							: (
								<div title={item.caption} className="">
									<i
										className="fa fa-clock-o"
										title={item.caption}
										aria-hidden="true"
									/>
								</div>
							)}
					</div>
				</Radio.Button>
			))}
		</Radio.Group>
	);
};

export default WorkingTimeFilter;
