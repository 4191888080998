import React from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Actions } from '@common/react/components/Chat/SignalRChats';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';

import { User } from '@app/objects/User';
import { MedicalTranscriptionNotificationData } from '@app/components/Pages/Admin/Chats/MedicalTranscribeButton';

const TranscriptionNotificationHandler: React.FC = () => {
	const { subscribeUntilLogout, getUser } = useApplicationContext();

	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const {
		state: {
			storageName,
			chatStoreSettings: {
				getActionCreators,
			},
		},
	} = context;

	const dispatch = useDispatch();
	const actions: Actions = React.useMemo(() => bindActionCreators(getActionCreators(), dispatch), []);
	const user = getUser<User>();

	const notificationHandler = (notification: any) => {
		if (notification.objectType === 'MedicalTranscription' && notification.service && user?.id === notification.user) {
			const transcription = notification.data as MedicalTranscriptionNotificationData;

			if (transcription.objectType === 'ChatMessage' && transcription.objectField === 'text'
				&& transcription?.data?.chatId) {
				actions.updateMessageContent({
					id: transcription.objectId,
					text: transcription.text,
					chatId: +transcription.data?.chatId,
				}, storageName);
			}
		}
	};

	subscribeUntilLogout(notificationHandler);

	return null;
};

export default TranscriptionNotificationHandler;
