import * as React from 'react';

// eslint-disable-next-line
import once from 'lodash/once';
// eslint-disable-next-line
import isEmpty from 'lodash/isEmpty';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import { UserGroupType } from '@commonTuna/react/objects/UserGroup';

import { User } from '@app/objects/User';

export interface UserGroupContext {
	checkUserGroupAccess: (userGroup: UserGroupType | Array<UserGroupType>, anyMatch?: boolean) => boolean;
}

export const createUserContext = once(() => React.createContext({} as UserGroupContext));

export const useUserGroupContext: () => UserGroupContext = () => {
	const context: UserGroupContext = React.useContext(createUserContext());

	if (isEmpty(context)) throw Error('Need UserGroup context!');

	return context;
};

const UserGroupProvider: React.FC = ({
	children,
}) => {
	const UserGroupContext = createUserContext();
	const { getUser } = useApplicationContext();
	const user = getUser<User>();

	const checkUserGroupAccess = React.useMemo(() =>
		(userGroup: UserGroupType | Array<UserGroupType>, anyMatch?: boolean) => {
			return (Array.isArray(userGroup)
				? anyMatch
					? userGroup.some((ug) => user?.userGroups.find((c) => c.userGroupType === ug))
					: userGroup.every((ug) => user?.userGroups.find((c) => c.userGroupType === ug))
				: user?.userGroups.some((ug) => ug.userGroupType === userGroup) ?? false);
		}, [UserGroupContext, user]);
	const value = React.useMemo(() => {
		return { checkUserGroupAccess };
	}, [checkUserGroupAccess]);

	return (
		<>
			<UserGroupContext.Provider value={value}>
				{children}
			</UserGroupContext.Provider>
		</>
	);
};

export default UserGroupProvider;
