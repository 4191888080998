import * as React from 'react';

import { SelectProps } from 'antd/lib/select';

import { useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import ItemsProviderWithStore from '@common/react/components/Core/ItemsProviderWithStore/ItemsProviderWithStore';
import { SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import ItemsSelect from '@common/react/components/UI/ItemsSelect/ItemsSelect';
import { BaseParams } from '@common/react/objects/BaseParams';
import ItemsProviderSynchronizer from '@common/react/components/Core/AdvancedItemsProvider/ItemsProviderSynchronizer';

import { SystemAppointmentStatus } from '@commonTuna/react/objects/AppointmentStatus';

import { AppointmentStatus, defaultStatusesFilters } from '@app/objects/Appointment';
import '@app/scss/components/statusFilter.scss';
import ColorLine from '@app/components/UI/ColorLine/ColorLine';

interface LoadStatusesProps {
	value: Array<number> | undefined;
	// eslint-disable-next-line
	onChange: (value: any, option?: any) => void;
	initStatuses?: Array<AppointmentStatus>;
}

interface ComponentProps extends LoadStatusesProps{
	maxTagCount?: number;
	maxTagTextLength?: number;
	allowClear?: boolean;
	antdProps?: SelectProps;
	skipInitLoad?: boolean;
	getPopupContainer?: (node) => HTMLElement;
	additionalOptions?: React.ReactNode;
	additionalFilters?: BaseParams;
	withoutStore?: boolean;
	defaultValueIsEmpty?: boolean;
}

interface StatusFilterInnerProps {
	value: Array<number> | undefined;
	// eslint-disable-next-line
	onChange: (value: any) => void;
	statuses: Array<AppointmentStatus>;
}

export const getDefaultStatuses = (statuses: Array<AppointmentStatus>) => {
	return statuses.filter((value) =>
		SystemAppointmentStatus.Canceled !== value.saStatus && SystemAppointmentStatus.Rescheduled !== value.saStatus);
};

const StatusFilterInner: React.FC<StatusFilterInnerProps> = ({ statuses, onChange, value }) => {
	React.useEffect(() => {
		if (!value && statuses.length > 0) {
			onChange(getDefaultStatuses(statuses).map((value) => value.id));
		}
	}, [statuses]);

	return <></>;
};

const storageName = 'appointmentStatuses';

const StatusFilter: React.FC<ComponentProps> = ({
	value, onChange,
	maxTagCount = 2, maxTagTextLength = 20, allowClear = true, initStatuses, ...rest
}) => {
	const {
		getPopupContainer = ((node) => node.closest('.status-filter') || document.body),
		antdProps,
		additionalOptions,
		additionalFilters,
		withoutStore,
		defaultValueIsEmpty = false,
	} = rest;
	// eslint-disable-next-line
	const context = useItemProviderContext<any>(false);
	const serverStatuses = initStatuses || context?.state?.item?.filterProps?.statuses || undefined;

	return (
		<div className="status-filter">
			<ItemsSelect<AppointmentStatus>
				sortHandler={(a, b) => a.sortOrder - b.sortOrder}
				defaultSort={withoutStore ? undefined : ['sortOrder', SortingDirection.Ascending]}
				filterHandler={withoutStore ? undefined : () => true}
				storeName={withoutStore ? undefined : storageName}
				type="appointmentStatus"
				items={serverStatuses}
				pagination={{ current: 1, pageSize: 100 }}
				filters={{ ...defaultStatusesFilters, ...additionalFilters }}
				selectProps={{
					mode: 'multiple',
					placeholder: 'Statuses...',
					className: 'w100',
					...antdProps,
					value: value || [],
					onChange,
					maxTagCount,
					maxTagTextLength,
					allowClear,
					getPopupContainer,
					filterOption: (input, option) => (option?.item?.nameEn ?? '').toLowerCase().includes(input.toLowerCase()),
				}}
				itemsProviderSynchronizerProps={{ objectType: 'AppointmentStatusRedux' }}
				additionalOptions={additionalOptions}
				getOptionProps={(item) => ({
					value: item.id,
					children: <div>
						<ColorLine color={item.color} />
						{item.nameEn}
					</div>,
					item,
				})}
			>
				{(state) =>
					(!antdProps || antdProps?.mode === 'multiple') && !defaultValueIsEmpty
					&& <StatusFilterInner value={value} onChange={onChange} statuses={state.items} />}
			</ItemsSelect>
		</div>
	);
};

const LoadStatuses: React.FC<LoadStatusesProps> = ({ value, onChange, initStatuses }) => {
	// eslint-disable-next-line
	const context = useItemProviderContext<any>(false);
	const serverStatuses = initStatuses || context?.state?.item?.filterProps?.statuses;

	return (
		<ItemsProviderWithStore<AppointmentStatus>
			defaultSort={['sortOrder', SortingDirection.Ascending]}
			filterHandler={() => false}
			storeName={storageName}
			type="appointmentStatus"
			items={serverStatuses}
			pagination={{ current: 1, pageSize: 100 }}
			filters={defaultStatusesFilters}
		>
			{(state) => <>
				<ItemsProviderSynchronizer
					objectType="AppointmentStatusRedux"
					storeName={storageName}
					storeSortAfterAdd={(items) => [...items].sort((a, b) => a.sortOrder - b.sortOrder)}
					getItemsFromInit={(notification) => notification.data.appointmentStatuses}
				/>
				<StatusFilterInner value={value} onChange={onChange} statuses={state.items} />
			</>}
		</ItemsProviderWithStore>
	);
};

export const LoadDefaultStatuses = LoadStatuses;

export default StatusFilter;
