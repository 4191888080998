import * as React from 'react';

interface Props {
	title: string;
	value?: string | number | null | React.ReactElement;
	className?: string;
	node?: React.ReactNode;
}

const ReadOnlyControlValue: React.FC<Props> = ({
	title,
	className = 'form-group col-sm-6',
	value,
	node,
}) => {
	const text = value !== null && typeof value !== 'undefined' && value !== '' ? value : '-';
	return (
		<div className={`${className} readonly-control`}>
			<label>{title}</label>
			{node && (
				<div>
					{node}
				</div>
			)}
			<div title={typeof text === 'string' ? text : undefined}>
				{text}
			</div>
		</div>
	);
};

export default ReadOnlyControlValue;
