import { Views } from 'react-big-calendar';

import { Appointment, SystemAppointmentStatus } from '@app/objects/Appointment';
import { Doctor } from '@app/objects/Doctor';
import { Room } from '@app/objects/Room';

export enum CalendarMode {
	Daily,
	Weekly,
	Agenda,
	Month,
	WorkWeek,
}

export const CalendarModeName = {
	[CalendarMode.Daily]: Views.DAY,
	[CalendarMode.Weekly]: Views.WEEK,
	[CalendarMode.Agenda]: Views.AGENDA,
	[CalendarMode.Month]: Views.MONTH,
	[CalendarMode.WorkWeek]: Views.WORK_WEEK,
};

export interface CalendarEvent {
	start: Date;
	end: Date;
	title: string;
	data: Appointment;
	roomId: number;
	rightColor: string;
	backgroundColor: string;
	procedureName: string;
	appointmentProcedureId?: number;
	animationForNewAppointment: boolean;
	animationForSearchAppointment?: boolean;
	isFirstProcedure?: boolean;
	room?: Room | null;
}
export const getContrast = (hexcolor) => {
	if (!hexcolor) {
		return '#000';
	}

	let hex = hexcolor;

	if (!/^#[0-9A-F]{6}$/i.test(hex)) return '#000';

	// If a leading # is provided, remove it
	if (hex.slice(0, 1) === '#') {
		hex = hexcolor.slice(1);
	}

	// If a three-character hexcode, make six-character
	if (hex.length === 3) {
		hex = hexcolor.split('').map((hex) => hex + hex).join('');
	}

	// Convert to RGB value
	const r = parseInt(hex.substr(0, 2), 16);
	const g = parseInt(hex.substr(2, 2), 16);
	const b = parseInt(hex.substr(4, 2), 16);

	// Get YIQ ratio
	const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

	// Check contrast
	return (yiq >= 128) ? '#000' : '#fff';
};

export const lighten = (hexcolor, amount) => {
	let hex = hexcolor;

	// If a leading # is provided, remove it
	if (hex.slice(0, 1) === '#') {
		hex = hexcolor.slice(1);
	}

	// If a three-character hexcode, make six-character
	if (hex.length === 3) {
		hex = hexcolor.split('').map((hex) => hex + hex).join('');
	}

	// Convert to RGB value
	const r = parseInt(hex.substr(0, 2), 16);
	const g = parseInt(hex.substr(2, 2), 16);
	const b = parseInt(hex.substr(4, 2), 16);
	let newRed = Math.ceil(amount > 0 ? r + ((255 - r) * amount) : r * (1 + amount));
	let newGreen = Math.ceil(amount > 0 ? g + ((255 - g) * amount) : g * (1 + amount));
	let newBlue = Math.ceil(amount > 0 ? b + ((255 - b) * amount) : b * (1 + amount));
	newRed = Math.min(Math.max(0, newRed), 255);
	newGreen = Math.min(Math.max(0, newGreen), 255);
	newBlue = Math.min(Math.max(0, newBlue), 255);

	return `#${newRed < 16 ? '0' : ''}${
		newRed.toString(16)}${newGreen < 16 ? '0' : ''}${
		newGreen.toString(16)}${newBlue < 16 ? '0' : ''}${
		newBlue.toString(16)}`;
};

export const getEventStyles = (event: CalendarEvent) => {
	const { status, doctor, statusId } = event.data;
	const isCompleted = status?.saStatus === SystemAppointmentStatus.Completed;
	const isLocked = event.data.locked;
	const style: {
		background?: string;
		borderRight?: string;
		paddingLeft?: string;
		color?: string;
		animation?: string;
		keyframes?: string;
	} = {
		background: isLocked ? lighten('#3174ad', -0.3)
			: isCompleted ? lighten('#3174ad', 0.3) : '#3174ad',
	};

	/* if (doctor && doctor.color) {
		style.background = doctor.color;
	}

	if (status && status.color) {
		style.background = status.color;
	} */

	if (event.backgroundColor) {
		style.background = isLocked ? lighten(event.backgroundColor, -0.3)
			: isCompleted ? lighten(event.backgroundColor, 0.3) : event.backgroundColor;
	}

	style.color = getContrast(style.background);

	if (event.rightColor) {
		style.paddingLeft = '10px';
	}

	if (event.animationForNewAppointment) {
		style.animation = 'blink 2s 1';
	}

	if (event.animationForSearchAppointment) {
		style.animation = 'blink 2s 5';
	}

	return style;
};

export const getDoctorsByOrder = (orders: Array<number>, doctors: Array<Doctor>) => {
	const resDoctors: Array<Doctor> = orders.map((id) => doctors.find((doc) => doc.id === id)).filter((doc) => !!doc);

	return resDoctors.concat(doctors.filter((doc) => !orders.find((id) => doc.id === id)));
};

export const changeOrder = (orders, prevOrder, newOrder) => {
	const newOrders: Array<any> = [];

	[...orders].forEach((id, i) => {
		if (prevOrder === i) {
			return;
		}
		if (newOrder === i) {
			if (prevOrder < newOrder) {
				newOrders.push(id);
				newOrders.push(orders[prevOrder]);
			} else {
				newOrders.push(orders[prevOrder]);
				newOrders.push(id);
			}

			return;
		}
		newOrders.push(id);
	});

	return newOrders;
};
