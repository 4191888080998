export enum ReviewRequestStrategy {
	None = 0,
	AskAfterCompleteStatus = 1,
	SendAfterCompleteStatus = 2
}

export const ReviewRequestStrategyNames = {
	[ReviewRequestStrategy.None]: 'None',
	[ReviewRequestStrategy.AskAfterCompleteStatus]: 'Ask After Complete Status',
	[ReviewRequestStrategy.SendAfterCompleteStatus]: 'Send After Complete Status',
};
