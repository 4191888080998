import React from 'react';

import Message from 'antd/lib/message';
import Button from 'antd/lib/button';

import { record, RecordResult, RecordControls } from '@common/react/utils/record-audio';
import '@common/react/scss/components/voiceMessageRecorder.scss';

interface VoiceMessageButtonProps {
	onStop?: (value: RecordResult | undefined) => void;
	setRecordAudioResult: (value: RecordResult) => void;
	className?: string;
	shape?: 'circle' | 'default' | 'round';
	disabled?: boolean;
}

const VoiceMessageButton: React.FC<VoiceMessageButtonProps> = ({ onStop, setRecordAudioResult, ...rest }) => {
	const { className = 'btn-primary', shape = 'default', disabled } = rest;
	const stream = React.useRef<MediaStream>();
	const recordActions = React.useRef<RecordControls>();

	const [isRecording, setIsRecording] = React.useState<boolean>(false);

	const getRecordActions = () => {
		if (!stream.current) {
			return navigator.mediaDevices.getUserMedia({ audio: true })
				.then((res) => stream.current = res)
				.then(() => stream.current && record(stream.current, 'audio/mp3'));
		}
		return record(stream.current, 'audio/mp3');
	};

	const recordAudio = () => {
		if (!recordActions.current) {
			return;
		}

		if (!isRecording) {
			setIsRecording(true);
			recordActions.current.start();
		} else {
			setIsRecording(false);
			recordActions.current.stop()
				.then((result) => {
					stream.current?.getTracks().forEach((track) => track.stop());
					setRecordAudioResult(result);
					onStop && onStop(result);
				})
				.catch(console.error);
		}
	};

	const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault();

		if (!recordActions.current) {
			getRecordActions().then((actions) => {
				recordActions.current = actions;
				recordAudio();
			}).catch((e) => {
				Message.warning(typeof e === 'object' ? e?.message : e);
			});
			return;
		}
		recordAudio();
	};

	return <>
		<Button
			type="primary"
			className={`voice-message-record-btn ${className}`}
			shape={shape}
			icon={!isRecording ? <i className="fa fa-microphone" /> : <i className="fa fa-stop" />}
			onClick={handleClick}
			disabled={disabled}
		/>

	</>;
};

export default VoiceMessageButton;
