import * as React from 'react';

import Tooltip from 'antd/lib/tooltip';

import { ChatMessage, ChatMessageType, ChatPlugin } from '@common/react/components/Chat/Chat';

const copyToClipboard = (value) => {
	const tempInput = document.createElement('input');
	tempInput.value = value;
	document.body.appendChild(tempInput);
	tempInput.select();
	document.execCommand('copy');
	document.body.removeChild(tempInput);
};

const CopyButton: React.FC<{options, message: ChatMessage}> = ({ options, message }) => {
	const [tooltip, setTooltip] = React.useState(options.title);

	const handleClick = (e) => {
		e.preventDefault();
		copyToClipboard(message.text);
		setTooltip('Copied');
	};

	return <Tooltip
		className="edit-plugin-popover"
		placement="topRight"
		afterOpenChange={(visible) => !visible && typeof window !== 'undefined' && setTimeout(() => setTooltip(options.title), 200)}
		title={<div className="text-center">{tooltip}</div>}
		getPopupContainer={(node) => node.closest('.chat-message-list-component__item') || document.body}
	>
		<button
			key="copy"
			className={`message-action btn ${options?.className} btn-sm`}
			type="button"
			onClick={handleClick}
		>
			{options?.children}
		</button>
	</Tooltip>;
};

export const CopyPlugin: ChatPlugin = {
	chatMessageAction: ({ message, options }) =>
		(message.text && message.chatMessageType === ChatMessageType.Regular ? <CopyButton
			options={options}
			message={message}
		/> : null),
	options: {
		className: 'btn-default',
		children: 'Copy',
		title: 'Copy message text',
	},
};
