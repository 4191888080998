import React from 'react';

interface Props {
	color: string | undefined;
	width?: string | number;
	title?: string;
	style?: React.CSSProperties;
}

const ColorLine: React.FC<Props> = (props) => {
	const {
		color,
		width = '3px',
		title,
		style,
	} = props;
	if (!color) {
		return null;
	}

	return <span
		title={title}
		style={{
			...style,
			display: 'inline-block',
			height: '1.2em',
			width,
			margin: '-3px 3px -3px 0',
			background: color,
		}}
	/>;
};

export default ColorLine;
