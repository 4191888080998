import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { WithTime } from '@common/typescript/objects/WithTime';

export enum TemplateType {
	Consent = 0,
	Instruction = 1
}

export enum ConsentFormType
{
	QuoteFooter = 0,
	CompanyConsent = 1,
}

export const ConsentFormTypeNames = {
	[ConsentFormType.QuoteFooter]: 'Quote Footer',
	[ConsentFormType.CompanyConsent]: 'Company Consent',

};

export interface BaseTemplate extends WithDeleted, WithTime {
	name: string;
	description: string;
	html: string;

	templateType: TemplateType;

	enableInitials: boolean;
	enableWitnessSignature: boolean;
	enablePatientSignature: boolean;
}
