import React from 'react';

import Select from 'antd/lib/select';

import { BaseParams } from '@common/react/objects/BaseParams';
import SelectFilter, { SelectFilterProps } from '@common/react/components/Forms/SelectFilter/SelectFilter';

import { DashboardTimeInterval, intervals } from '@commonTuna/react/components/Utils';

import { TimeIntervals } from '@app/components/Pages/Admin/BaseReport/BaseReport';

export const getExcludeIntervals = (partition: DashboardTimeInterval) => {
	return {
		[DashboardTimeInterval.Month]: [DashboardTimeInterval.Year],
		[DashboardTimeInterval.Week]: [DashboardTimeInterval.Year, DashboardTimeInterval.Quarter],
		[DashboardTimeInterval.Day]: [
			DashboardTimeInterval.Year,
			DashboardTimeInterval.Quarter,
			DashboardTimeInterval.Month,
		],
	}[partition];
};

interface Props {
	filters: BaseParams;
	handleChange: (evt) => void;
	selectFiltersProps?: SelectFilterProps;
	excludeIntervals?: Array<DashboardTimeInterval>;
}

const PartitionFilter: React.FC<Props> = ({
	filters, handleChange, selectFiltersProps, excludeIntervals,
}) => {
	const timeIntervals = React.useMemo(() => {
		return excludeIntervals ? TimeIntervals.filter((item) => !excludeIntervals?.includes(item)) : TimeIntervals;
	}, [excludeIntervals]);
	return (
		<SelectFilter
			additionalParams={filters}
			param="partition"
			onChange={handleChange}
			className="pull-right ml10"
			defaultValue={intervals.find((q) => q.interval === DashboardTimeInterval.Year)?.caption}
			currentValue={+filters.partition}
			selectProps={{ style: { width: '85px' } }}
			{...selectFiltersProps}
		>
			{timeIntervals.map((item, index) => (
				<Select.Option
					key={item}
					value={item}
				>
					{intervals.find((q) => q.interval === item)?.caption}
				</Select.Option>
			))}
		</SelectFilter>
	);
};

export default PartitionFilter;
