import { Action, Reducer } from 'redux';

import { UserFavorite } from '@app/objects/User';

export interface UserFavoriteState {
	favorites: Array<UserFavorite>;
}

export enum TypeKeys {
	INIT = 'INIT_FAVORITE',
	ADDFAVORITE = 'ADD_FAVORITE',
	REMOVEFAVORITE = 'REMOVE_FAVORITE'
}

export interface InitAction {
	type: TypeKeys.INIT;
	init: Array<UserFavorite>;
}

export interface AddFavoriteAction {
	type: TypeKeys.ADDFAVORITE;
	userFavorite: UserFavorite;
}

export interface RemoveFavoriteAction {
	type: TypeKeys.REMOVEFAVORITE;
	id: number;
}

type KnownUserFavoriteActions = AddFavoriteAction | RemoveFavoriteAction | InitAction;

const initialState: UserFavoriteState = {
	favorites: [],
};

export function getReducer(): Reducer<UserFavoriteState> {
	return (state: UserFavoriteState = initialState, incomingAction: Action = { type: '' }) => {
		const action = incomingAction as KnownUserFavoriteActions;

		switch (action.type) {
			case TypeKeys.INIT:
				return { ...state, favorites: action.init };
			case TypeKeys.ADDFAVORITE:
				if (state.favorites.filter((favorite) => favorite.id === action.userFavorite.id).length > 0) return state;
				return { ...state, favorites: [...state.favorites, action.userFavorite] };
			case TypeKeys.REMOVEFAVORITE:
				return { ...state, favorites: state.favorites.filter((userFavorite) => userFavorite.id !== action.id) };
			default:
				return state;
		}
	};
}
