import * as React from 'react';

import { ChatKind, ChatPlugin } from '@common/react/components/Chat/Chat';
import Button from '@common/react/components/Forms/Button';

export const LeaveChatPlugin : ChatPlugin = {
	messagesHeaderAction: ({
		leaveChat, currentChat, withRemoteId, user,
	}) =>
		currentChat.kind === ChatKind.Group
		&& (!withRemoteId || currentChat.contacts.some((item) => item.remoteId === user.id))
		&& <Button
			onClick={leaveChat}
			className="btn btn-sm btn-danger pull-right"
			title="Leave the chat"
		>
			<i className="fa fa-times" />
		</Button>,
};
