import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { BaseApplicationState } from '@common/react/store/index';
import { BaseUser } from '@common/react/objects/BaseUser';
import { List, transformArrayToList } from '@common/typescript/objects/List';
import { request } from '@common/react/components/Api';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { BaseParams } from '@common/typescript/objects/BaseParams';

export interface Comment<TUser extends BaseUser> extends WithDeleted {
	objectId: number;
	objectType: string;
	text: string;
	time?: number;
	update?: number;
	parentComment?: Comment<TUser>;
	parent: number;
	level?: number;
	rating?: number;
	children?: List<Comment<TUser>>;
	childrenIds?: List<number>;
	usr: TUser;
	user: number;
	commentType?: number;
	color?: string;
	error?: true;
}

export interface ItemComments<TUser extends BaseUser> {
	root: Comment<TUser>;
	[id: number]: Comment<TUser>;
}

export interface TypeComments<TUser extends BaseUser> {
	objectType: string;
	[id: number]: ItemComments<TUser>;
}

export interface CommentsState<TUser extends BaseUser> {
	[objectType: string]: TypeComments<TUser> | CommentsState<TUser>;
}

export enum TypeKeys {
	RECIEVECOMMENTTREE = 'RECIEVECOMMENTTREE',
	ADDCOMMENT = 'ADDCOMMENT',
	UPDATECOMMENT = 'UPDATECOMMENT',
	DELETECOMMENT = 'DELETECOMMENT',
}

interface SetTree<TUser extends BaseUser> {
	type: TypeKeys.RECIEVECOMMENTTREE;
	item: Comment<TUser>;
	objectId: number; // we cant get item.objectId when use objectSubType
	storageName: string;
}

interface ReceiveTreeAction<TUser extends BaseUser> {
	type: TypeKeys.RECIEVECOMMENTTREE;
	item: Comment<TUser>;
	storageName: string;
}

interface AddItem<TUser extends BaseUser> {
	type: TypeKeys.ADDCOMMENT;
	item: Comment<TUser>;
	objectId: number; // we cant get item.objectId when use objectSubType
	storageName: string;
	insertBefore?: boolean;
}

interface UpdateItem<TUser extends BaseUser> {
	type: TypeKeys.UPDATECOMMENT;
	item: Comment<TUser>;
	objectId: number; // we cant get item.objectId when use objectSubType
	storageName: string;
}

interface DeleteItem<TUser extends BaseUser> {
	type: TypeKeys.DELETECOMMENT;
	item: Comment<TUser>;
	objectId: number; // we cant get item.objectId when use objectSubType
	storageName: string;
}

type KnownPageAction<TUser extends BaseUser> = SetTree<TUser>
	| ReceiveTreeAction<TUser>
	| AddItem<TUser>
	| UpdateItem<TUser>
	| DeleteItem<TUser>;

export interface CommentsActionCreators<TUser extends BaseUser> {
	setTree: (comment: Comment<TUser>, objectSubType: string, objectId: number, store?: string)
		=> ThunkAction<void, BaseApplicationState<TUser>, object, SetTree<TUser>>;
	loadTree: (requestType: string, objectType: string, objectId: number, store?: string, additionalParams?: BaseParams)
		=> ThunkAction<void, BaseApplicationState<TUser>, object, ReceiveTreeAction<TUser>>;
	addComment: (comment: Comment<TUser>, objectSubType: string, objectId: number, insertBefore?: boolean, store?: string)
		=> ThunkAction<void, BaseApplicationState<TUser>, object, AddItem<TUser>>;
	updateComment: (comment: Comment<TUser>, objectSubType: string, objectId: number, store?: string)
		=> ThunkAction<void, BaseApplicationState<TUser>, object, UpdateItem<TUser>>;
	deleteComment: (comment: Comment<TUser>, objectSubType: string, objectId: number, store?: string)
		=> ThunkAction<void, BaseApplicationState<TUser>, object, DeleteItem<TUser>>;
}

export const getObjectTypeByCommentType = (objectType: string, commentType?: number) => {
	return commentType && commentType > 0 ? `${objectType}${commentType}` : objectType;
};

export const baseNotificationCondition = <TUser extends BaseUser>(
	comment: Comment<TUser>,
	objectId: number,
	objectType: string,
	commentType: number | null = null,
) => {
	return comment.objectId === objectId && comment.objectType === objectType && comment.commentType === commentType;
};

export function getCommentState(state: any, ownProps: any, root?: boolean) {
	if (!ownProps) { return {}; }

	const {
		objectId, objectType, commentType, objectSubType = objectType,
	} = ownProps;

	const objectTypeWithCommentType = getObjectTypeByCommentType(objectSubType, commentType);

	const stateName = ownProps.stateName || 'comments';

	return {
		[root ? 'root' : 'item']: state[stateName][objectTypeWithCommentType]
			&& state[stateName][objectTypeWithCommentType][objectId]
			&& state[stateName][objectTypeWithCommentType][objectId][root ? 'root' : ownProps.id],
		user: state.login.user,
	};
}

export function getActionCreators<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>() {
	return {
		setTree: (
			comment: Comment<TUser>,
			objectSubType: string,
			objectId: number,
			store: string = 'comments',
		): ThunkAction<void, BaseApplicationState<TUser>, object, ReceiveTreeAction<TUser>> => (dispatch, getState) => {
			dispatch({
				type: TypeKeys.RECIEVECOMMENTTREE,
				item: {
					...comment,
					objectType: getObjectTypeByCommentType(objectSubType, comment?.commentType),
				} as Comment<TUser>,
				storageName: store,
			});
		},
		loadTree: (
			requestType: string,
			objectSubType: string,
			objectId: number,
			store: string = 'comments',
			additionalParams?,
		): ThunkAction<void, BaseApplicationState<TUser>, object, ReceiveTreeAction<TUser>> => (dispatch, getState) => {
			const commentType = additionalParams?.commentType;
			const comments = getState()[`${store}`]
				?.[`${getObjectTypeByCommentType(objectSubType, commentType)}`]
				?.[`${objectId}`]
				?.root
				?.childrenIds;

			if (comments && comments.list.length >= 0) {
				return;
			}

			const fetchTask = request(
				requestType,
				{
					objectType: objectSubType,
					objectId,
					...additionalParams,
				},
				getState(),
			).then((data: any) => {
				dispatch({
					type: TypeKeys.RECIEVECOMMENTTREE,
					item: {
						...data,
						objectType: getObjectTypeByCommentType(objectSubType, data.commentType ?? additionalParams?.commentType),
					} as Comment<TUser>,
					storageName: store,
				});
			})
				.catch((e) => {
					dispatch({
						type: TypeKeys.RECIEVECOMMENTTREE,
						item: {
							objectId,
							...additionalParams,
							error: true,
							objectType: getObjectTypeByCommentType(objectSubType, additionalParams?.commentType),
						} as Comment<TUser>,
						storageName: store,
					});
				});

			addTask(fetchTask);

			return fetchTask;
		},
		updateComment: (
			comment: Comment<TUser>,
			objectSubType: string,
			objectId: number,
			store: string = 'comments',
		): ThunkAction<void, BaseApplicationState<TUser>, object, UpdateItem<TUser>> => (dispatch, getState) => {
			dispatch({
				type: TypeKeys.UPDATECOMMENT,
				item: {
					...comment,
					objectType: getObjectTypeByCommentType(objectSubType, comment.commentType),
				} as Comment<TUser>,
				objectId,
				storageName: store,
			});
		},
		addComment: (
			comment: Comment<TUser>,
			objectSubType: string,
			objectId: number,
			insertBefore: boolean = false,
			store: string = 'comments',
		): ThunkAction<void, BaseApplicationState<TUser>, object, AddItem<TUser>> => (dispatch, getState) => {
			dispatch({
				type: TypeKeys.ADDCOMMENT,
				item: {
					...comment,
					objectType: getObjectTypeByCommentType(objectSubType, comment.commentType),
				} as Comment<TUser>,
				objectId,
				insertBefore,
				storageName: store,
			});
		},
		deleteComment: (
			comment: Comment<TUser>,
			objectSubType: string,
			objectId: number,
			store: string = 'comments',
		): ThunkAction<void, BaseApplicationState<TUser>, object, DeleteItem<TUser>> => (dispatch, getState) => {
			dispatch({
				type: TypeKeys.DELETECOMMENT,
				item: {
					...comment,
					objectType: getObjectTypeByCommentType(objectSubType, comment.commentType),
				} as Comment<TUser>,
				objectId,
				storageName: store,
			});
		},
	};
}

export function getReducer<TUser extends BaseUser>(storageName: string = 'comments'):Reducer<CommentsState<TUser>> {
	return (state: CommentsState<TUser> = {}, incomingAction: Action) => {
		const action = incomingAction as KnownPageAction<TUser>;

		if (!(action.type in TypeKeys)) {
			return state;
		}

		if (!action.storageName || action.storageName === storageName) {
			const typeComments = state[action.item.objectType]
				? { ...state[action.item.objectType] }
				: { objectType: action.item.objectType };

			const updateState = (obj: any) => {
				return {
					...state,
					[action.item.objectType]: {
						...typeComments,
						...obj,
					},
				} as any;
			};

			const itemComments = typeComments[('objectId' in action ? action.objectId : undefined) ?? action.item.objectId]
				&& (action.type !== TypeKeys.RECIEVECOMMENTTREE)
				? { ...typeComments[action.objectId ?? action.item.objectId] }
				: { root: { ...action.item } };

			switch (action.type) {
				case TypeKeys.RECIEVECOMMENTTREE:
					const processNextComment = (nextComment: Comment<TUser>) => {
						if (nextComment.id > 0) {
							itemComments[nextComment.id] = nextComment;
						}

						if (nextComment.children) {
							nextComment.childrenIds = {
								...nextComment.children,
								list: nextComment.children.list.map((child) => child.id),
							};

							nextComment.children.list.forEach((child) => processNextComment({ ...child }));

							nextComment.children = undefined;
						}
					};

					processNextComment(itemComments.root);

					return updateState({
						[action.item.objectId]: itemComments as ItemComments<TUser>,
					});
				case TypeKeys.ADDCOMMENT: {
					const parentComment = action.item.parent > 0
						? { ...itemComments[action.item.parent] }
						: { ...itemComments.root };

					if (!parentComment.childrenIds) {
						parentComment.childrenIds = transformArrayToList([action.item.id]);
					} else {
						parentComment.childrenIds = transformArrayToList(
							action.insertBefore
								? [action.item.id, ...parentComment.childrenIds.list]
								: [...parentComment.childrenIds.list, action.item.id],
						);
					}

					return updateState({
						[action.objectId]: {
							...itemComments,
							[action.item.id]: action.item,
							[action.item.parent || 'root']: parentComment,
						},
					});
				}
				case TypeKeys.UPDATECOMMENT:
					return updateState({
						[action.objectId]: {
							...itemComments,
							[action.item.id]: {
								...itemComments[action.item.id],
								...action.item,
							},
						},
					});
				case TypeKeys.DELETECOMMENT: {
					const parentComment = action.item.parent > 0
						? { ...itemComments[action.item.parent] }
						: { ...itemComments.root };

					const parentCommentChildren = parentComment.childrenIds as List<number>;

					const oldChildrenList = parentCommentChildren.list;

					const newChildrenList = oldChildrenList.slice();

					newChildrenList.splice(oldChildrenList.indexOf(action.item.id), 1);

					parentComment.childrenIds = {
						offset: 0,
						count: parentCommentChildren.count - 1,
						execution: 0,
						list: newChildrenList,
					};

					return updateState({
						[action.objectId]: {
							...itemComments,
							[action.item.id]: undefined,
							[action.item.parent || 'root']: parentComment,
						},
					});
				}
				default:
					const exhaustiveCheck: never = action;
			}
		}

		return state;
	};
}
