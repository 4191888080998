import { Reducer, Action } from 'redux';

export enum TypeKeys {
	UPDATE = 'UPDATE',
	SETISCALLING = 'SET_IS_CALLING',
	SETISBUSY = 'SET_IS_BUSY',
	SETTOKEN = 'SET_TOKEN',
	CLEAR = 'CLEAR'
}

export interface PhoneCallState {
	isActive: boolean;
	isBusy: boolean;
	token: string;
	patientId?: number;
	inquiryId?: number;
	portalId?: number;
	chatId?: number;
	patientName: string;
	patientAvatar: string;
	patientNumber: string;
}

export interface UpdateAction {
	type: TypeKeys.UPDATE;
	data: Partial<PhoneCallState>;
}

export interface SetIsCallingAction {
	type: TypeKeys.SETISCALLING;
	value: boolean;
}

export interface SetIsBusyAction {
	type: TypeKeys.SETISBUSY;
	value: boolean;
}

export interface SetTokenAction {
	type: TypeKeys.SETTOKEN;
	token: string;
}

export interface ClearAction {
	type: TypeKeys.CLEAR;
}

type KnownPhoneCallActions = UpdateAction | SetIsCallingAction | SetTokenAction | SetIsBusyAction | ClearAction;

const initialState = {
	isActive: false, isBusy: false, token: '', patientName: '', patientAvatar: '', patientNumber: '',
};

export function getReducer(): Reducer<PhoneCallState> {
	return (state: PhoneCallState = initialState, incomingAction: Action = { type: '' }) => {
		const action = incomingAction as KnownPhoneCallActions;
		switch (action.type) {
			case TypeKeys.UPDATE:
				return { ...state, ...action.data };
			case TypeKeys.SETISCALLING:
				return { ...state, isActive: action.value };
			case TypeKeys.SETISBUSY:
				return { ...state, isBusy: action.value };
			case TypeKeys.SETTOKEN:
				return { ...state, token: action.token };
			case TypeKeys.CLEAR:
				return initialState;
			default:
				return state;
		}
	};
}
