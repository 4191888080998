import React from 'react';

import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { SelectProps } from 'antd/lib/select';

import Autocomplete, { OptionType, AutocompleteProps } from '@common/react/components/Forms/Autocomplete/Autocomplete';
import { HandleChangeEventElement } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import { BaseParams } from '@common/typescript/objects/BaseParams';

import '@app/scss/components/doctorAutocompleteFilter.scss';
import { Template } from '@app/objects/Template';

interface ComponentProps {
	currentValue: string | number;
	type?: string;
	renderTitle?: (item: Template) => React.ReactNode | string;
	renderOption?: (item: Template) => OptionType;
	handleChangeAndRefresh?: (target: HandleChangeEventElement, option?) => void;
	param?: string;
	multiple?: boolean;
	placeholder?: string | undefined;
	onSelect?: (value, option) => void;
	onChange?: (value) => void;
	value?: string | undefined;
	loadOnFocus?: boolean;
	antdProps?: AutoCompleteProps & Partial<SelectProps>;
	params?: BaseParams;
	isClear?: boolean;
	disabled?: boolean;
	paramName?: string;
	templateSigned?: boolean;
	withoutDisabled?: boolean;
	autocompleteProps?: Partial<AutocompleteProps>;
}

const TemplateAutocompleteFilter: React.FC<ComponentProps> = ({
	type,
	renderTitle,
	renderOption,
	placeholder,
	onSelect,
	onChange,
	handleChangeAndRefresh,
	param,
	value,
	loadOnFocus,
	antdProps,
	params,
	isClear,
	disabled,
	paramName,
	withoutDisabled,
	templateSigned: isSignedProps,
	autocompleteProps,
	currentValue,
}) => {
	const [templateSigned, setTemplateSigned] = React.useState<boolean>(isSignedProps || true);
	const usedParams = withoutDisabled ? params : { ...params, templateSigned };

	return (
		<div className="doctor-filter-wrapper pull-right">
			{!withoutDisabled && (
				<div
					className="doctor-filter-switch"
				>
					<button
						type="button"
						title={!templateSigned ? 'search signed Consent Forms' : 'search not signed Consent Forms'}
						className={`btn btn-default ${!templateSigned ? '' : 'doctor-filter__checked'}`}
						onClick={() => setTemplateSigned((v) => !v)}
					>
						<i className="fa fa-check" />
					</button>
				</div>
			)}
			<Autocomplete<Template>
				{...autocompleteProps}
				type="templateViewList"
				renderTitle={renderTitle ?? 'name'}
				renderOption={renderOption}
				onSelect={(value, option) => {
					if (onSelect) {
						onSelect(value, option);
					} else if (handleChangeAndRefresh && param) {
						handleChangeAndRefresh({
							currentTarget: {
								name: param,
								value: `${value}`,
							},
						}, option);
					}
				}}
				onChange={(value = '') => {
					if (onChange) {
						onChange(value);
					} else if (handleChangeAndRefresh && param && value === '' && currentValue !== -1) {
						handleChangeAndRefresh({
							currentTarget: {
								name: param,
								value: -1,
							},
						});
					}
				}}
				value={value}
				disabled={disabled}
				loadOnFocus={loadOnFocus ?? true}
				antdProps={antdProps ?? {
					placeholder: placeholder ?? 'Consent Forms...',
				}}
				params={usedParams}
				isClear={isClear ?? false}
				paramName={paramName}
			/>
		</div>
	);
};

export default TemplateAutocompleteFilter;
