import React from 'react';

import Select from 'antd/lib/select';

import { SelectFilterProps } from '@common/react/components/Forms/SelectFilter/SelectFilter';
import { useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import ItemsSelect from '@common/react/components/UI/ItemsSelect/ItemsSelect';

import { Ethnicity } from '@commonTuna/react/objects/Ethnicity';

const Option = Select.Option;

interface Props {
	param?: string;
	getPopupContainer?: (node) => HTMLElement;
	initEthnicities?: Array<Ethnicity> | undefined;
	defaultSelectName?: string;
	id?: string;
	withAdditionalOption?: boolean;
	loadRequest?: string;
	additionalOption?: JSX.Element;
}

type EthnicitySelectProps = Omit<SelectFilterProps, 'param'> & Props;

const EthnicitySelect: React.FC<EthnicitySelectProps> = (props) => {
	const {
		additionalParams,
		onChange,
		param = 'ethnicityId',
		initEthnicities,
		getPopupContainer,
		defaultValue = -1,
		defaultSelectName = 'All Ethnicities',
		withAdditionalOption = true,
		loadRequest = 'ethnicityList',
		additionalOption,
		...selectProps
	} = props;

	const context = useItemProviderContext<any>(false);
	const serverEthnicities = initEthnicities || context?.state?.item?.filterProps?.ethnicities;

	return (
		<ItemsSelect<Ethnicity>
			type="ethnicity"
			loadRequest={loadRequest}
			items={serverEthnicities}
			getOptionProps={(item) => ({
				value: item.id,
				children: item.nameEn,
				item,
			})}
			selectProps={{
				...selectProps,
				value: additionalParams[param] ?? defaultValue,
				getPopupContainer,
				onChange,
			}}
			additionalOptions={withAdditionalOption
				? <>
					<Option key="null" value={defaultValue}>{defaultSelectName}</Option>
					{additionalOption}
				</>
				: null}
		/>
	);
};

export default EthnicitySelect;
