import React from 'react';

import VideoMessageRecorder from '@common/react/components/UI/VideoMessage/VideoMessageRecorder';
import VideoMessagePreview from '@common/react/components/UI/VideoMessage/VideoMessagePreview';
import { RecordResult } from '@common/react/utils/record-audio';

interface VideoMessageInputProps {
	recordVideoResult?: RecordResult;
	setRecordVideoResult: (value: RecordResult | undefined) => void;
	onStop?: (value: RecordResult | undefined) => void;
	onReset?: () => void;
	className?: string;
	shape?: 'circle' | 'default' | 'round';
	containerClassName?: string;
	waveColor?: string;
	disabled?: boolean;
}

const VideoMessageInput: React.FC<VideoMessageInputProps> = ({
	recordVideoResult, setRecordVideoResult, onStop, onReset, ...rest
}) => {
	const [key, setKey] = React.useState<string | number>('default');

	React.useEffect(() => {
		setKey(Math.random());
	}, [recordVideoResult]);

	const handleStop = (value) => {
		onStop && onStop(value);
		setKey(Math.random());
	};

	return (
		<div className={`video-message-button ${rest.containerClassName || 'mr10'}`}>
			{
				!recordVideoResult
					? (
						<VideoMessageRecorder
							setRecordResult={setRecordVideoResult}
							onStop={handleStop}
							{...rest}
						/>
					)
					: (
						<VideoMessagePreview
							key={key}
							recordResult={recordVideoResult}
							setRecordResult={setRecordVideoResult}
							onReset={onReset}
							buttonStyle={rest.shape === 'default' ? { borderRadius: 3 } : {}}
							{...rest}
						/>
					)
			}
		</div>
	);
};

export default VideoMessageInput;
