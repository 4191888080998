import * as React from 'react';

import { ChatPlugin } from '@common/react/components/Chat/Chat';
import Button from '@common/react/components/Forms/Button';

export const AllChatsPlugin : ChatPlugin = {
	chatsListHeaderComponent: ({ handleChange, filters }) =>
		<Button
			className="btn btn-primary load-chats-btn icon-btn"
			title={`Load ${filters.loadAllChats ? 'only my chats' : 'all company chats'}`}
			onClick={() => handleChange({ loadAllChats: !filters.loadAllChats, chatId: null })}
		>
			<i className={`fa fa-comments${filters.loadAllChats ? '' : '-o'}`} aria-hidden="true" />
		</Button>,
};
