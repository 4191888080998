import React from 'react';
import { Router, useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import once from 'lodash/once';
import { createMemoryHistory } from 'history';

import Sizable from '@common/react/components/UI/Sizable/Sizable';
import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';
import { parseQuery } from '@common/typescript/utils/url';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { addPrefix, filterByPrefix, getKeysByPrefix } from '@common/react/utils/ObjectKeysPrefix/objectKeysPrefix';
import {
	getSearchParamsFromUrl,
	urlHandleItems,
} from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';

interface SizableWithServerPageProps {
	urlKey: string;
	getRightServerPageState?: (state) => any;
	sizableClassName?: string;
	additionalParams?: BaseParams;
	getSearchFromParams?: (params: BaseParams, key?: string) => BaseParams;
	rightComponent?: React.ReactNode;
	innerHistoryListen?: (location, action, history) => void;
	getPathnamePostfix?: (pathname: string, params: BaseParams, urlKey: string) => string;
}

const defaultGetRightServerPageState = (state) =>
	({ page: state.serverPages?.pages?.length > 1 ? state.serverPages.pages[1] : null, path: 'right-tabs' });

export interface SizableProviderContext {
	state: object;
}

export const createSizableProviderContext = once(() => React.createContext({} as SizableProviderContext));

export const useSizableProviderContext: () => SizableProviderContext = () =>
	React.useContext(createSizableProviderContext());

const defaultGetSearchFromParams: (BaseParams, key) => string = (params, key) => {
	if (!key) {
		return '';
	}
	const searchObj = { id: params.id, ...getKeysByPrefix(params, `${key}-`) };
	const emptyValues = ['', 'undefined', '-1', undefined, -1];

	return Object.keys(searchObj)
		.filter((k) => emptyValues.indexOf(searchObj[k]) === -1)
		.map((k) => urlHandleItems(searchObj, k, 0)).join('&');
};

const defaultGetPathnamePostfix = (pathname, params, urlKey) => {
	if (!pathname) return '';

	const pageOrId = params.id || params[`${urlKey}-id`] || params[`${urlKey}-page`];
	return pageOrId ? `/${pageOrId}` : '';
};

const getPath = (location) => {
	return `${location.pathname}${location.search ? `?${location.search}` : ''}`;
};

export const defaultInnerHistoryListen = (location, action, prefix, history) => {
	if (action === 'REPLACE' && (!location.state || !location.state.withoutUpdate)) {
		const id = location.pathname?.match(/\/-?(\d+)/)?.[1];
		const leftParams = getSearchParamsFromUrl(history.location);
		const empty = filterByPrefix(leftParams, prefix);
		Object.keys(empty).forEach((key) => empty[key] = undefined);

		const searchObj = {
			...getSearchParamsFromUrl(history.location),
			...empty,
			...addPrefix(parseQuery(location.search), prefix),
			id,
			[`${prefix}page`]: id,
		};

		const emptyValues = ['', 'undefined', '-1', undefined, -1];
		const search = Object.keys(searchObj)
			.filter((k) => emptyValues.indexOf(searchObj[k]) === -1)
			.map((k) => urlHandleItems(searchObj, k, 0)).join('&');

		history.replace({
			...history.location,
			search,
			state: { rightUpdate: true },
		});
	}
};

const SizableWithServerPage: React.FC<SizableWithServerPageProps> = ({ children, ...props }) => {
	const Context = createSizableProviderContext();
	const context = useSizableProviderContext();
	const {
		urlKey, rightComponent, getRightServerPageState = defaultGetRightServerPageState, sizableClassName, additionalParams,
		getSearchFromParams = defaultGetSearchFromParams,
		innerHistoryListen = defaultInnerHistoryListen,
		getPathnamePostfix = defaultGetPathnamePostfix,
	} = props;
	const history = useHistory<any>();
	const params = parseQuery(history.location.search);
	const [key, setKey] = React.useState<string | undefined>(() => params[urlKey]);
	const serverPages = useSelector((state: any) => state.serverPages, shallowEqual);

	const showRightComponent = React.useMemo(() => {
		return !context.state && typeof params[urlKey] !== 'undefined' && typeof key !== 'undefined';
	}, [params?.[urlKey], key, context.state]);
	const newLocation = React.useMemo(() => ({
		...history.location,
		pathname: `/${key}${getPathnamePostfix(`${key}`, params, urlKey)}`,
		search: getSearchFromParams(params, urlKey),
	}), [key, history.location]);
	const path = React.useMemo(() => getPath(newLocation), [newLocation]);

	const memoryHistory = React.useRef(createMemoryHistory({
		initialEntries: [path],
	}));

	React.useEffect(() => {
		const newActiveKey = params[urlKey];

		if (newActiveKey !== key) {
			setKey(newActiveKey);
			const location = {
				pathname: `/${newActiveKey}${getPathnamePostfix(`${newActiveKey}`, params, urlKey)}`,
				search: getSearchFromParams(params, urlKey),
			};
			memoryHistory.current = createMemoryHistory({
				initialEntries: [getPath(location)],
			});
		}
	}, [history.location]);

	React.useEffect(() => {
		memoryHistory.current?.listen((location, action) => {
			innerHistoryListen(location, action, `${urlKey}-`, history);
		});
		history.listen((location: any, action) => {
			if (!location.state || !location.state.rightUpdate) {
				const params = parseQuery(location.search);
				const key = params[urlKey];
				memoryHistory.current?.replace({
					...history.location,
					pathname: `/${key}${getPathnamePostfix(`${key}`, params, urlKey)}`,
					search: getSearchFromParams(params, key) as string,
					state: { withoutUpdate: true },
				});
			}
			if (location?.state?.rightUpdate) {
				location.state.rightUpdate = false;
			}
		});
	}, []);

	React.useEffect(() => {
		if (serverPages?.pages) {
			serverPages.pages = null;
		}
	}, []);

	return <Context.Provider value={{ state: {} }}>
		<Sizable
			className={sizableClassName}
			showRightComponent={showRightComponent}
			leftComponent={children}
			rightComponent={<Router
				key={urlKey}
				history={{
					...memoryHistory.current,
					replace: (location: any) => memoryHistory.current.replace({
						...location,
						state: location.state ? { ...location.state, withoutUpdate: false } : undefined,
					}),
					push: history.push,
					go: history.go,
					goBack: history.goBack,
					goForward: history.goForward,
				}}
			>
				<>
					<ServerPageProvider
						getInnerPageFromContext
						transformToItem={(item) => {
							return item ? { ...item, innerPage: item } : undefined;
						}}
						additionalParams={{
							...additionalParams,
							path,
							rightKey: urlKey,
						}}
						getState={getRightServerPageState}
						inner={() => rightComponent}
					/>
				</>
			</Router>
			}
		/>
	</Context.Provider>;
};

export default SizableWithServerPage;
