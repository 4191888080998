import * as React from 'react';

import { FormikProps } from 'formik';
import Tag from 'antd/lib/tag';
import loadable from '@loadable/component';

import {
	ChatFormButtonsProps, ChatMessageType, NewMessage, ChatPlugin,
} from '@common/react/components/Chat/Chat';
import VideoMessageChatPlayer from '@common/react/components/UI/VideoMessage/VideoMessageChatPlayer';
import VideoMessageInput from '@common/react/components/UI/VideoMessage/VideoMessageInput';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { loadableDelay } from '@common/react/loadable/loadableSettings';

const params = {
	fallback: <Loader />,
};

const TextChatMessage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TextChatMessage" */ '@common/react/components/Chat/TextChatMessage')), params);

const Message = ({ message, contacts, withRemoteId }) => <div>
	<VideoMessageChatPlayer
		videoMessageUrl={message.files.find((f) => f.file.name === 'video_message.webm')
			? `${withRemoteId ? '/remote' : ''}/${message.files.find((f) => f.file.name === 'video_message.webm')?.file.src}` : undefined}
	/>
	<TextChatMessage text={message.text} contacts={contacts} />
</div>;

const LastMessage = ({ message, userId }) => <div className="chat-list__item-last-message">
	{message.userId === userId ? 'You: ' : ''}
	<i className="fa fa-video-camera" style={{ marginRight: 5, marginLeft: message.userId === userId ? 5 : 0 }} />
	video
	message
</div>;

const FormTag = (formikBag: FormikProps<NewMessage>, { state, setState, waveColor }) => state.recordVideoResult
	&& <Tag
		className="chat-file-tag chat-video-tag"
		title="Video Message"
	>
		<VideoMessageInput
			className="btn-default"
			containerClassName=" "
			waveColor={waveColor}
			recordVideoResult={state.recordVideoResult}
			setRecordVideoResult={(recordVideoResult) => setState((prev) => ({ ...prev, recordVideoResult }))}
			onStop={(value) => {
				setState((prev) => ({ ...prev, recordAudioResult: undefined }));

				if (!value) {
					formikBag.setFieldValue('bytes', []);
					formikBag.setFieldValue('chatMessageType', ChatMessageType.Regular);
					return;
				}

				value?.blob.arrayBuffer()
					.then((buffer) => {
						const byteArray = new Uint8Array(buffer);
						formikBag.setFieldValue('bytes', Array.from(byteArray));
						formikBag.setFieldValue('chatMessageType', ChatMessageType.VideoMessage);
					});
			}}
			onReset={() => {
				formikBag.setFieldValue('bytes', []);
				formikBag.setFieldValue('chatMessageType', ChatMessageType.Regular);
			}}
		/>
	</Tag>;

const formButton = (formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) => <React.Fragment key="video">
	<VideoMessageInput
		disabled={props.isEditMessage}
		waveColor={props.waveColor}
		className="btn-default ml10"
		containerClassName=" "
		setRecordVideoResult={(recordVideoResult) => props.setState((prev) => ({ ...prev, recordVideoResult }))}
		onStop={(value) => {
			props.setState((prev) => ({ ...prev, recordAudioResult: undefined }));

			if (!value) {
				formikBag.setFieldValue('bytes', []);
				formikBag.setFieldValue('chatMessageType', ChatMessageType.Regular);
				return;
			}

			value?.blob.arrayBuffer()
				.then((buffer) => {
					const byteArray = new Uint8Array(buffer);
					formikBag.setFieldValue('bytes', Array.from(byteArray));
					formikBag.setFieldValue('chatMessageType', ChatMessageType.VideoMessage);
				});
		}}
		onReset={() => {
			formikBag.setFieldValue('bytes', []);
			formikBag.setFieldValue('chatMessageType', ChatMessageType.Regular);
		}}
	/>
</React.Fragment>;

export const VideoMessagePlugin: ChatPlugin = {
	message: {
		render: Message,
		lastMessage: LastMessage,
		notification: ({ message, withRemoteId, contacts }) => {
			return <>Video Message</>;
		},
	},
	formTag: FormTag,
	formButton,
};
