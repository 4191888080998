import * as React from 'react';
import { connect } from 'react-redux';

import { Notification } from '@common/typescript/objects/Notification';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { NotificationAction } from '@common/typescript/objects/NotificationAction';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import { TypeKeys } from '@app/store/UserFavorites';
import { UserFavorite } from '@app/objects/User';

interface Actions {
	userFavorites: Array<UserFavorite>;
	addFavorite: (userFavorite: UserFavorite) => void;
	removeFavorite: (id: number) => void;
}

const UserFavoritesSetter: React.FC<Actions> = ({ userFavorites, addFavorite, removeFavorite }) => {
	const { subscribe } = useApplicationContext();
	const handle = (notification: Notification<BaseUser>) => {
		if (notification.objectType === 'UserFavorite') {
			const data = notification.data as UserFavorite;

			const isNotExist = userFavorites.map((favorite) => favorite.url).indexOf(data.url) === -1;

			if (notification.action === NotificationAction.Add && isNotExist) {
				addFavorite(data);
			} else if (notification.action === NotificationAction.Delete) {
				removeFavorite(data.id);
			}
		}
	};

	React.useEffect(subscribe(handle), [userFavorites]);

	return null;
};

const mapUserFavoriteSetterState = (state) => ({
	userFavorites: state.userFavorites.favorites,
});

const mapUserFavoriteSetterDispatch = (dispatch) => ({
	addFavorite: (newUserFavorite: UserFavorite) =>
		dispatch({ type: TypeKeys.ADDFAVORITE, userFavorite: newUserFavorite }),
	removeFavorite: (id: number) =>
		dispatch({ type: TypeKeys.REMOVEFAVORITE, id }),
	dispatch,
});

export default connect(mapUserFavoriteSetterState, mapUserFavoriteSetterDispatch)(UserFavoritesSetter);
