import { Action, Reducer } from 'redux';
import { addTask } from 'domain-task';

import { BaseAppThunkAction } from '@common/react/store';

import { DashboardTimeInterval } from '@commonTuna/react/components/Utils';

import { ApplicationState, AppThunkAction } from '@app/store/index';
import { request } from '@app/components/Api';
import { User } from '@app/objects/User';
import { ReviewCounters } from '@app/objects/ReviewCounters';
import { PaymentType } from '@app/objects/Order';
import { TimeKey } from '@app/components/Pages/Admin/BaseReport/BaseReport';
import { EmailTemplate } from '@app/objects/EmailTemplate';
import { AnalysisCounters } from '@app/objects/AnalysisCounters';
import { AppointmentInsuranceStatusEnum } from '@app/objects/AppointmentInsurance';

interface IWithLoading {
	isLoading: boolean;
	error?: boolean;
}

interface IWithCounterItem {
	name: string;
	color: string;
	value: number;
	id: number;
}

interface InquiryCountersResponse {
	items: Array<InquiryCounterItem>;
	inquiryCountersInfoState: InquiryCountersInfoState;
	inquiriesCount: number;
}

interface EmailMarketingCountersResponse {
	items: Array<EmailCounterItem>;
	emailMarketingState: EmailMarketingState;
	emailTemplates: Array<EmailTemplate>;
	emailsCount: number;
}

interface EmailMarketingPerformanceResponse {
	engagementEventItems: any;
	deliveryEventItems: any;
	emailTemplates: Array<EmailTemplate>;
	total: number;
}

interface ProfitWidgetResponse {
	itemsTotals: ProfitWidgetItemsTotals;
	paymentTotals: ProfitWidgetPaymentTotals;
}

interface ProfitWidgetPaymentTotals {
	total: number;
	cash: number;
	creditCard: number;
	debitCard: number;
	check: number;
	electronicPayment: number;
}

interface ProfitWidgetItemsTotals {
	services: number;
	products: number;
	specials: number;
	debts: number;
	balances: number;
}

interface PatientCounters extends IWithLoading {
	items: Array<PatientCounterItem>;
	patientCounterInfoState: PatientCounterInfoState;
	patientsCount: number;
}

interface AppointmentCounters extends IWithLoading {
	items: Array<AppointmentCounterItem>;
	appointmentCounterInfoState: AppointmentCounterInfoState;
	appointmentsCount: number;
}

interface ClaimCounters extends IWithLoading {
	items: Array<ClaimCounterItem>;
	claimCounterInfoState: ClaimCounterInfoState;
	claimsCount: number;
}

interface CaseCounters extends IWithLoading {
	items: Array<CaseCounterItem>;
	caseCounterInfoState: CaseCounterInfoState;
	casesCount: number;
}

interface AppointmentCounterItem extends IWithCounterItem {
	systemType: SystemTypes;
}

interface ClaimCounterItem extends IWithCounterItem {
	status: AppointmentInsuranceStatusEnum;
}

interface OrderCounters extends IWithLoading {
	list: Array<OrderCounterItem>;
	count: number;
}

interface OrderCounterItem {
	name: string;
	color: string;
	value: number;
	id: number;
	orderCounterInfoState: OrderCounterInfoState;
}

type InquiryCounterItem = IWithCounterItem
type EmailCounterItem = IWithCounterItem
type PatientCounterItem = IWithCounterItem
type CaseCounterItem = IWithCounterItem

interface FinancialItem {
	id: number;
	name: string;
	appointmentsMade: number;
	totalBilled: number;
	totalPayed: number;
}

interface FinancialCounters extends IWithLoading {
	items: Array<FinancialItem>;
}

interface CounterDataSet {
	label: string;
	id: number;
	color: string;
	data: Array<number>;
}

interface DoctorMetricsCounter extends IWithLoading {
	dataSets: Array<CounterDataSet>;
	labels: Array<TimeKey>;
	text: string;
}

/* interface PaymentCounters extends IWithLoading {
	cash: number;
	creditCard: number;
	debitCard: number;
	check: number;
	electronicPayment: number;
	balance: number;
	credit: number;
	balanceCount: number;
	cashCount: number;
	checkCount: number;
	creditCardCount: number;
	creditCount: number;
	debitCardCount: number;
	electronicPaymentCount: number;

	// giftCard: number;
} */

interface PaymentCounter {
	paymentType: PaymentType;
	sum: number;
	count: number;
}

interface PaymentCounters {
	items: Array<PaymentCounter>;
}

interface TotalCountersResponse {
	faqs: number;
	cases: number;
	reviews: number;
	services: number;
	inquiries: number;
	doctors: number;
	honors: number;
	locations: number;
	patients: number;
	appointments: number;
	emails: number;
	newEmails: number;
	inboundEmails: number;
	newInboundEmails: number;
	returningPatients: number;
}

interface MetricWidgetReport {
	value: number;
	patientBalance: number;
	payments: number;
}

interface MetricStatisticDataSet{
	id: number;
	label: string;
	color: string;
	data: Array<number>;
}

interface MetricStatisticCounterResponse {
	dataSets: Array<MetricStatisticDataSet>;
	labels: Array<TimeKey>;
	isLoading: boolean;
}

export type InquiryCountersState = InquiryCountersResponse & IWithLoading;
export type ReviewCountersState = ReviewCounters & IWithLoading;
export type CaseCountersState = CaseCounters & IWithLoading;
export type OrderCountersState = OrderCounters & IWithLoading;
export type PatientCountersState = PatientCounters & IWithLoading;
export type AppointmentCountersState = AppointmentCounters & IWithLoading;
export type ClaimCountersState = ClaimCounters & IWithLoading;
export type DoctorMetricsState = DoctorMetricsCounter & IWithLoading;
export type TotalCountersState = TotalCountersResponse & IWithLoading;
export type FinancialCountersState = FinancialCounters & IWithLoading;
export type PaymentCountersState = PaymentCounters & IWithLoading;
export type EmailMarketingCountersState = EmailMarketingCountersResponse & IWithLoading;
export type EmailMarketingPerformanceState = EmailMarketingPerformanceResponse & IWithLoading;
export type MetricStatisticCounterState = MetricStatisticCounterResponse & IWithLoading;
export type ProfitWidgetState = ProfitWidgetResponse & IWithLoading;
export type AnalysisCountersState = AnalysisCounters & IWithLoading;
export type SpecialsSaleState = IWithLoading;

export interface DashboardState {
	reviewCounters: ReviewCountersState;
	patientCounters: PatientCounters;
	totalCounters: TotalCountersState;
	metricWidgetReport: Array<MetricWidgetReport>;
	emailMarketingCounters: EmailMarketingCountersState;
	emailMarketingPerformance: EmailMarketingPerformanceState;
	caseCounters: CaseCountersState;
	analysisCounters: AnalysisCountersState;
	specialsSaleState: SpecialsSaleState;
}

export enum EmailMarketingState
{
	Outgoing = 0,
	Delivery = 1,
	Engagement = 2,
	Type = 3,
}

export enum PatientCounterInfoState
{
	Sources = 0,
	Genders = 1,
	Ethnicities = 2,
	Inventories = 3,
}

export enum AppointmentCounterInfoState {
	Sources = 0,
	Statuses = 1,
	Types = 2,
	Doctors = 3,
}

export enum ClaimCounterInfoState {
	Statuses = 0,
	Doctors = 1
}

export enum SpecialsSaleInfoState {
	Specials = 0,
	Sale = 1,
}

export enum CaseCounterInfoState
{
	Doctors = 0,
	Genders = 1,
	Ethnicities = 2,
	Stars = 3,
	Services = 4,
}

export enum InquiryCountersInfoState {
	Statuses = 0,
	Genders = 1,
	Devices = 2,
	Doctors = 3,
	Locations = 4,
}

export enum ReviewsCounterInfoState {
	Sources = 0,
	Statuses = 1,
	Doctors = 2,
	Stars = 3,
}

export enum OrderCounterInfoState
{
	Statuses = 0,
	Types = 1,
}

export enum AnalyzesCounterInfoState {
	Statuses = 0,
	Doctors = 1,
	MatchedUnmatched = 2,
}

export const AppointmentCounterInfoStateNames = {
	[AppointmentCounterInfoState.Sources]: 'Sources',
	[AppointmentCounterInfoState.Statuses]: 'Statuses',
	[AppointmentCounterInfoState.Types]: 'Types',
	[AppointmentCounterInfoState.Doctors]: 'Doctors',
};

export const EmailMarketingStateNames = {
	[EmailMarketingState.Outgoing]: 'Outgoing',
	[EmailMarketingState.Delivery]: 'Delivery',
	[EmailMarketingState.Engagement]: 'Engagement',
	[EmailMarketingState.Type]: 'Type',
};

export const ClaimCounterInfoStateNames = {
	[ClaimCounterInfoState.Statuses]: 'Statuses',
	[ClaimCounterInfoState.Doctors]: 'Doctors',
};

export const PatientCounterInfoStateNames = {
	[PatientCounterInfoState.Sources]: 'Sources',
	[PatientCounterInfoState.Ethnicities]: 'Ethnicities',
	[PatientCounterInfoState.Genders]: 'Genders',
	[PatientCounterInfoState.Inventories]: 'Inventories',
};

export const InquiryCounterInfoStateNames = {
	[InquiryCountersInfoState.Statuses]: 'Statuses',
	[InquiryCountersInfoState.Doctors]: 'Doctors',
	[InquiryCountersInfoState.Genders]: 'Genders',
	[InquiryCountersInfoState.Devices]: 'Devices',
	[InquiryCountersInfoState.Locations]: 'Locations',
};

export const ReviewCounterInfoStateNames = {
	[ReviewsCounterInfoState.Sources]: 'Sources',
	[ReviewsCounterInfoState.Statuses]: 'Statuses',
	[ReviewsCounterInfoState.Doctors]: 'Doctors',
	[ReviewsCounterInfoState.Stars]: 'Stars',
};

export const CaseCounterInfoStateNames = {
	[CaseCounterInfoState.Doctors]: 'Providers',
	[CaseCounterInfoState.Stars]: 'Stars',
	[CaseCounterInfoState.Genders]: 'Genders',
	[CaseCounterInfoState.Ethnicities]: 'Ethnicities',
	[CaseCounterInfoState.Services]: 'Services',
};

export const OrderCounterInfoStateNames = {
	[OrderCounterInfoState.Statuses]: 'Statuses',
	[OrderCounterInfoState.Types]: 'Types',
};

export const AnalysisCounterInfoStateNames = {
	[AnalyzesCounterInfoState.Statuses]: 'Statuses',
	[AnalyzesCounterInfoState.Doctors]: 'Doctors',
	[AnalyzesCounterInfoState.MatchedUnmatched]: 'Matched / Unmatched',
};

export enum TypeKeys {
	REQUESTCOUNTERS = 'REQUESTCOUNTERS',
	RECEIVECOUNTERS = 'RECEIVECOUNTERS',
	RECEIVEERRORCOUNTERS = 'RECEIVEERRORCOUNTERS'
}

export enum SystemTypes
{
	all = -1,
	Tuna = 0,
	Chrono = 1,
	Nopali = 2,
	ZocDoc = 3,
	PhoneCall = 4,
	Inquiry = 5
}

export interface SystemTypeGroupElem {
	image?: any;
	caption: string;
	value: SystemTypes;
}

export const systemTypeGroup: Array<SystemTypeGroupElem> = [
	{ image: require('@app/images/tuna.png'), caption: 'NorthernTuna Patient Portal', value: SystemTypes.Tuna },
	{ image: require('@app/images/drchrono.jpg'), caption: 'Chrono', value: SystemTypes.Chrono },
	{ image: require('@app/images/nopaliLogo.jpg'), caption: 'Nopali', value: SystemTypes.Nopali },
	{ image: require('@app/images/zocDoc.png'), caption: 'ZocDoc', value: SystemTypes.ZocDoc },
	{ image: require('@app/images/phoneCall.jpg'), caption: 'Phone Call', value: SystemTypes.PhoneCall },
	{ image: require('@app/images/inquiry.png'), caption: 'Inquiry', value: SystemTypes.Inquiry },
	{ caption: 'All', value: SystemTypes.all },
];

export type CounterType = keyof DashboardState;

const types: Array<CounterType> = [
	'reviewCounters',
	'patientCounters',
	'totalCounters',
	'analysisCounters',
];

interface RequestCountersAction {
	type: TypeKeys.REQUESTCOUNTERS;
	counterType: CounterType | undefined;
}

interface RequestErrorCountersAction {
	type: TypeKeys.RECEIVEERRORCOUNTERS;
	error: boolean;
	counterType: CounterType | undefined;
}

interface ReceiveCountersAction {
	type: TypeKeys.RECEIVECOUNTERS;
	data: InquiryCountersResponse | ReviewCounters | OrderCounters | PatientCounters |
		AppointmentCounters | DoctorMetricsCounter | FinancialCounters | TotalCountersResponse | MetricStatisticCounterResponse |
		AnalysisCounters;
	counterType: CounterType | undefined;
}

type KnownDashboardAction = RequestCountersAction | ReceiveCountersAction | RequestErrorCountersAction;

const initialState: DashboardState = {
	metricWidgetReport: [],
	specialsSaleState: {
		isLoading: false,
	},
	emailMarketingCounters: {
		items: [],
		emailsCount: 0,
		emailTemplates: [],
		emailMarketingState: EmailMarketingState.Outgoing,
		isLoading: false,
	},
	emailMarketingPerformance: {
		engagementEventItems: 0,
		deliveryEventItems: 0,
		emailTemplates: [],
		total: 0,
		isLoading: false,
	},
	reviewCounters: {
		items: [],
		reviewsCounterInfoState: ReviewsCounterInfoState.Sources,
		reviewsCount: 0,
		isLoading: false,
	},
	patientCounters: {
		items: [],
		patientCounterInfoState: PatientCounterInfoState.Sources,
		patientsCount: 0,
		isLoading: false,
	},
	caseCounters: {
		items: [],
		caseCounterInfoState: CaseCounterInfoState.Stars,
		casesCount: 0,
		isLoading: false,
	},
	analysisCounters: {
		items: [],
		analyzesCounterInfoState: AnalyzesCounterInfoState.Statuses,
		analyzesCount: 0,
		isLoading: false,
	},
	totalCounters: {
		faqs: 0,
		cases: 0,
		reviews: 0,
		services: 0,
		doctors: 0,
		honors: 0,
		locations: 0,
		inquiries: 0,
		patients: 0,
		appointments: 0,
		emails: 0,
		newEmails: 0,
		inboundEmails: 0,
		newInboundEmails: 0,
		returningPatients: 0,
		isLoading: false,
	},
};

export interface DashboardActionCreators {
	loadCounter: (params: CounterRequestMessage, counterType: CounterType | undefined) =>
		BaseAppThunkAction<KnownDashboardAction, User, ApplicationState>;
	loadAllCounters: (params: CounterRequestMessage) => BaseAppThunkAction<KnownDashboardAction, User, ApplicationState>;
	setCounter: (data: CounterType, counterType: CounterType | undefined)
		=> BaseAppThunkAction<KnownDashboardAction, User, ApplicationState>;
}

interface CounterRequestMessage {
	timeInterval?: DashboardTimeInterval;
	timeMin?: number | null;
	timeMax?: number | null;
	locationId?: number;
	doctorId?: number;
	partition?: number;
}

const loadCounter = (
	params: CounterRequestMessage,
	counterType: CounterType | undefined,
	signal: AbortSignal | undefined,
	dispatch,
	getState,
	transformResponse?: (data) => any,
) => {
	if (!counterType) {
		return;
	}

	const state = getState();

	if (state.dashboard[counterType].isLoading) {
		return;
	}

	const fetchTask = request<InquiryCountersResponse>(counterType, params, state, signal).then((data) => {
		dispatch({ type: TypeKeys.RECEIVECOUNTERS, data: transformResponse ? transformResponse(data) : data, counterType });
	})
		.catch(() => {
			dispatch({ type: TypeKeys.RECEIVEERRORCOUNTERS, error: true, counterType });
		});

	addTask(fetchTask);
	dispatch({ type: TypeKeys.REQUESTCOUNTERS, counterType });
};

const setCounter = (data: any, counterType: CounterType | undefined, dispatch, getState) => {
	if (!counterType) {
		return;
	}

	const state = getState();

	if (state.dashboard[counterType].isLoading) {
		return;
	}

	dispatch({ type: TypeKeys.RECEIVECOUNTERS, data, counterType });
};

export const actionCreators = ({
	loadCounter: (
		params: CounterRequestMessage,
		counterType: CounterType | undefined,
		signal?: AbortSignal,
		transformResponse?: (data) => any,
	): AppThunkAction<KnownDashboardAction> => (dispatch, getState) => {
		loadCounter(params, counterType, signal, dispatch, getState, transformResponse);
	},
	loadAllCounters: (params: CounterRequestMessage, signal?: AbortSignal): AppThunkAction<KnownDashboardAction> => (dispatch, getState) => {
		types.forEach((item) => {
			loadCounter(params, item, signal, undefined, dispatch, getState);
		});
	},
	setCounter: (data: any, counterType: CounterType | undefined): AppThunkAction<KnownDashboardAction> => (dispatch, getState) => {
		setCounter(data, counterType, dispatch, getState);
	},
});

export const reducer: Reducer<DashboardState> = (state: DashboardState = initialState, incomingAction: Action = { type: '' }) => {
	const action = incomingAction as KnownDashboardAction;

	const counterType = action.counterType;

	switch (action.type) {
		case TypeKeys.REQUESTCOUNTERS:
			return counterType ? { ...state, [counterType]: { ...state[counterType], error: false, isLoading: true } } : state;
		case TypeKeys.RECEIVECOUNTERS:
			return counterType ? { ...state, [counterType]: { ...action.data, error: false, isLoading: false } } : state;
		case TypeKeys.RECEIVEERRORCOUNTERS:
			return action.error && counterType
				? { ...state, [counterType]: { ...state[counterType], error: true, isLoading: false } } : state;
		default:
			return state;
	}
};
