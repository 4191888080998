import React from 'react';
import { useHistory } from 'react-router-dom';

import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';

interface ArchiveButtonProps {
	handleChange: any;
	selectChat: any;
	filters: any;
	pageSettings: any;
}

const ArchiveButton: React.FC<ArchiveButtonProps> = ({
	selectChat, handleChange, filters, pageSettings,
}) => {
	const history = useHistory();

	const { state: { chatsFilters }, actions: { setChatsFilters } } = useChatSettingsProviderContext();

	const handleClick = React.useCallback(() => {
		handleChange({ chatId: undefined, archive: !chatsFilters?.archive })
			.then((res) => {
				selectChat(res.list[0]);
				history.replace({
					pathname: history.location.pathname,
					search: res.list[0]
						? `?${pageSettings.chatIdUrlKey}=${res.list[0].id}&${pageSettings.archive}=${!chatsFilters?.archive}`
						: `?${pageSettings.archive}=${!chatsFilters?.archive}`,
				});
			});
		setChatsFilters({ archive: !chatsFilters?.archive });
	}, [chatsFilters]);
	const isArchive = chatsFilters?.archive;

	return (
		<button
			type="button"
			className={`btn btn-${isArchive ? 'danger' : 'primary'} icon-btn is-relative`}
			title={isArchive ? 'Archive' : 'Active'}
			onClick={handleClick}
		>
			<i className="fa fa-archive" aria-hidden="true" />
		</button>
	);
};

export default ArchiveButton;
