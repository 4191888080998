import * as React from 'react';

import once from 'lodash/once';

import { ChatStickerCollection } from '@common/react/components/Chat/Chat';
import { equal } from '@common/typescript/Utils';

export interface StickerProviderProps {
	children: any;
	collections: Array<ChatStickerCollection>;
	skeleton?: string;
}

export interface StickerProviderContextState {
	collections: Array<ChatStickerCollection>;
	skeleton?: string;
}

export interface StickerProviderContext {
	state: StickerProviderContextState;
}

export const createStickerProviderContext = once(() => React.createContext({} as StickerProviderContext));

export const useStickerProviderContext: () => StickerProviderContext = () =>
	React.useContext(createStickerProviderContext());

export const StickerProvider: (p: StickerProviderProps) => React.ReactElement = ({
	children,
	collections,
	skeleton,
}) => {
	const StickerProviderContext = createStickerProviderContext();

	const [value, setValue] = React.useState<{state: StickerProviderContextState}>(() => ({
		state: {
			collections: collections.filter((collection) => collection.enable === undefined || collection.enable),
			skeleton,
		},
	}));

	React.useEffect(() => {
		if (!equal(collections, value.state.collections)) {
			setValue((prev) => ({
				...prev,
				state: {
					...prev.state,
					collections: collections.filter((collection) => collection.enable === undefined || collection.enable),
				},
			}));
		}
	}, [collections]);

	return (
		<StickerProviderContext.Provider value={value}>
			{children}
		</StickerProviderContext.Provider>
	);
};
