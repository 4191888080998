import { addTask } from 'domain-task';
import { Action, Reducer, Dispatch } from 'redux';

import { List } from '@common/typescript/objects/List';

import { ApplicationState, AppThunkAction } from '@app/store/index';

import { request } from '@app/components/Api';
import { Page } from '@app/objects/Page';

export interface MenuState {
	isLoading: boolean;
	loaded: boolean;
	items: Array<Page>;
}

export enum TypeKeys {
	REQUESTPAGES = 'HOMEREQUESTPAGES',
	RECEIVEPAGES = 'HOMERECEIVEPAGES'
}

export interface RequestMenuAction {
	type: TypeKeys.REQUESTPAGES;
}

export interface ReceiveMenuAction {
	type: TypeKeys.RECEIVEPAGES;
	items: Array<Page>;
}

type KnownPageAction = RequestMenuAction | ReceiveMenuAction;

function loadItems(
	dispatch: (action: KnownPageAction) => void,
	getState: () => ApplicationState,
	type: string,
	path: string,
	requestType: TypeKeys,
	receiveType: TypeKeys,
) {
	if (!getState().menu.loaded) {
		const fetchTask = request<List<Page>>(path, {

		}, getState()).then((data) => {
			dispatch({ type: receiveType, items: data.list });
		});

		addTask(fetchTask);
		dispatch({ type: requestType, items: [] });
	}
}

export const actionCreators = {
	reqPages: (): AppThunkAction<KnownPageAction> => (dispatch, getState) => {
		loadItems(dispatch, getState, 'pages', 'menuList', TypeKeys.REQUESTPAGES, TypeKeys.RECEIVEPAGES);
	},
};

export const reducer: Reducer<MenuState> = (
	state: MenuState = { isLoading: false, loaded: false, items: [] },
	incomingAction: Action = { type: '' },
) => {
	const action = incomingAction as KnownPageAction;
	switch (action.type) {
		case TypeKeys.REQUESTPAGES:
			return { ...state, isLoading: true };
		case TypeKeys.RECEIVEPAGES:
			return { isLoading: false, items: action.items, loaded: true };
		default:
			return state;
	}
};
