import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Notification } from '@common/typescript/objects/Notification';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { NotificationAction } from '@common/typescript/objects/NotificationAction';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import { TypeKeys } from '@app/store/PhoneCall';
import { ApplicationState } from '@app/store';

const PhoneCallSetter: React.FC = () => {
	const dispatch = useDispatch();
	const isActive = useSelector((state: ApplicationState) => state.phoneCall.isActive);
	const { subscribe } = useApplicationContext();

	const notificationHandle = (notification: Notification<BaseUser>) => {
		if (notification.objectType === 'PhoneCall') {
			if (notification.action === NotificationAction.Add) {
				dispatch({ type: TypeKeys.SETISBUSY, value: true });
			}

			if (notification.action === NotificationAction.Update && !isActive) {
				dispatch({ type: TypeKeys.SETISBUSY, value: false });
			}
		}
	};

	React.useEffect(subscribe(notificationHandle), []);

	return null;
};

export default PhoneCallSetter;
