export interface RecordControls {
	start: () => void;
	stop: () => Promise<RecordResult>;
}

export interface RecordResult {
	blob: Blob;
	url: string;
}

type RecordType = 'audio/mp3' | 'video/webm';

export const record = (stream: MediaStream, type: RecordType) => (new Promise<RecordControls>((resolve) => {
	const mediaRecorder = new MediaRecorder(stream);
	let audioChunks: Array<Blob> = [];

	mediaRecorder.addEventListener('dataavailable', (event) => {
		audioChunks.push(event.data);
	});

	const start = () => mediaRecorder.start();

	const stop = () => (new Promise<RecordResult>((resolveStop) => {
		mediaRecorder.addEventListener('stop', () => {
			const blob = new Blob(audioChunks, { type });
			const url = URL.createObjectURL(blob);
			resolveStop({ blob, url });
		});

		audioChunks = [];
		mediaRecorder.stop();
	}));

	resolve({ start, stop });
}));
