import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Message from 'antd/lib/message';

import { ItemsProvider, useItemsProviderContext } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import { getChatName } from '@common/react/components/Chat/ChatUtils';
import { ChatKind, ChatPlugins } from '@common/react/components/Chat/Chat';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import Chats from '@common/react/components/Chat/Chats';
import { getUserName } from '@common/react/utils/utils';
import { BaseUserWithAvatar } from '@common/typescript/objects/BaseUser';
import { AllChatsPlugin } from '@common/react/components/Chat/AllChatsPlugin/AllChatsPlugin';
import { OnlineFilterPlugin } from '@common/react/components/Chat/OnlineFilterPlugin/OnlineFilterPlugin';
import { TemplatePlugin } from '@common/react/components/Chat/TemplatePlugin/TemplatePlugin';
import { SendLater } from '@common/react/components/Chat/SendLater/SendLater';
import Avatar from '@common/react/components/Forms/Avatar';

import { UserRole } from '@app/objects/User';
import AddSupportChatPopup from '@app/components/Pages/Admin/SupportChats/AddSupportChatPopup';
import SupportChatUsersSettings from '@app/components/Pages/Admin/SupportChats/SupportChatUsersSettings';
import CallPatient from '@app/components/UI/CallPatient/CallPatient';
import SendEmailModal from '@app/components/UI/SendEmailModal/SendEmailModal';
import { getAvatar } from '@app/components/Utils';
import SimpleChatSettingsProvider from '@app/components/Pages/Admin/Chats/SimpleChatSettingsProvider/SimpleChatSettingsProvider';
import { Location } from '@app/objects/Location';

import '@app/scss/components/supportChats.scss';
import '@app/scss/pages/chatsPage.scss';

const getUserPickSupport = (chat, userId): JSX.Element => {
	const users = chat.contacts.filter((user) => user.remoteId !== userId);
	if (users.length === 1) {
		return (
			<div
				className="chat-list__item-avatar"
				title={getUserName(users[0])}
			>
				<Avatar
					object={{ avatar: users[0].avatar ? getAvatar(users[0], undefined, true) : '' }}
					type=""
					readonly
					className="avatar-container"
					showOpenIcon={false}
					showDownload={false}
					withModal={false}
					firstName={users[0].firstName}
					lastName={users[0].lastName}
					color={users[0].color}
				/>
			</div>
		);
	}

	return (
		<div className="chat-list__item-avatar-multiple">
			{users.slice(0, 4).map((user: BaseUserWithAvatar & {color?: string}) => (
				<div
					className="chat-list__item-avatar chat-list__item-avatar_min"
					key={user.id}
					title={getUserName(user)}
				>
					<Avatar
						object={{ avatar: user.avatar ? getAvatar(user, undefined, true) : '' }}
						type=""
						readonly
						className="avatar-container"
						showOpenIcon={false}
						showDownload={false}
						withModal={false}
						firstName={user.firstName}
						lastName={user.lastName}
						color={user.color}
					/>
				</div>
			))}
		</div>
	);
};

export const SupportChatSettingsProvider: React.FC = ({ children }) => {
	const { checkUserAccess, request } = useApplicationContext();
	const history = useHistory();

	const context = useItemsProviderContext<Location>();

	const { state } = context;

	const templatePlugin = React.useMemo(() => {
		return {
			...TemplatePlugin,
			options: {
				saveRequest: 'supportChatTemplate',
				loadRequest: 'supportChatTemplateList',
				options: [
					'user_firstName',
					'user_lastName',
					'location_name',
					'location_address',
					'location_phone',
				],
				getData: (chat, user) => {
					const patient = chat.contacts.find((q) => q.remoteId === null);
					const location = state.items.find((q) => q.id === chat.locationId);

					/* eslint-disable */
					// @ts-ignore
					return {
						user_firstName: patient?.firstName,
						user_lastName: patient?.lastName,
						location_name: location?.nameEn,
						location_address: location?.addressEn,
						location_phone: location?.phone,
					};
					/* eslint-enable */
				},
			},
		};
	}, [state.items]);

	const getPatientId = (portalId: number, history, patientId) => {
		request<number>('getPatientId', {
			id: portalId,
		}).then((res) => {
			if (res > 0) {
				history.replace({
					pathname: `/patient-editor/${res}`,
					search: 'mode=view',
				});
				patientId.current = res;
			}
		}).catch(Message.error);
	};

	const chatListHeaderComponents = [
		({ user }) => (
			<AddSupportChatPopup
				user={user}
				render={(show) => (
					<button
						className="btn btn-primary"
						title="Add Chat"
						onClick={show}
						type="button"
					>
						<i className="fa fa-plus" />
					</button>
				)}
			/>
		),
		ChatPlugins.Searching,
		ChatPlugins.OnlineFilter,
		ChatPlugins.Archive,
		ChatPlugins.AllChats,
	].slice(0, checkUserAccess([UserRole.Admin]) ? 5 : 4);

	return (
		<SimpleChatSettingsProvider
			avatarSettings={{
				getChatAvatar: getUserPickSupport,
				getUserAvatar: (user) => (
					<Avatar
						object={{ avatar: getAvatar(user, '', !user.role) }}
						type=""
						readonly
						showOpenIcon={false}
						showDownload={false}
						withModal={false}
						firstName={user.firstName}
						lastName={user.lastName}
						color={user.color}
						className="avatar-container chat-message-list-component__item-avatar"
					/>
				),
				notificationAvatar: (state) => (
					<Avatar
						object={{ avatar: `/remote/${state.avatar}` }}
						type=""
						readonly
						showOpenIcon={false}
						showDownload={false}
						withModal={false}
						className="avatar-container chat-message-list-component__item-avatar"
						firstName={state.firstName ?? 'N'}
						lastName={state.lastName ?? 'N'}
					/>
				),
			}}
			plugins={{
				[ChatPlugins.AllChats]: AllChatsPlugin,
				[ChatPlugins.OnlineFilter]: OnlineFilterPlugin,
				[ChatPlugins.Typing]: undefined,
				[ChatPlugins.Template]: templatePlugin,
				[ChatPlugins.SendLater]: SendLater,
			}}
			listComponent={[
				ChatPlugins.SendLater,
			]}
			messageAttachmentsBefore={[ChatPlugins.SendLater]}
			withRemoteId
			chatListHeaderSettings={chatListHeaderComponents}
			sendButtonWrapper={ChatPlugins.SendLater}
			messagesHeaderComponents={[
				({ currentChat }) => {
					const patientContact = currentChat
						? currentChat.contacts.find((contact) => !contact.remoteId || !(contact.remoteId > 0)) : undefined;
					return patientContact && currentChat
				&& (
					<SendEmailModal
						initialEmail={patientContact.email}
						portalId={patientContact.id}
						button={(onClick, disabled, title) => {
							return (
								<button
									type="button"
									className="btn btn-sm btn-primary pull-right ml10"
									title={title || 'Send Email'}
									onClick={onClick}
									disabled={disabled}
								>
									<i className="fa fa-envelope" />
								</button>
							);
						}}
					/>
				);
				},
				({ currentChat }) => {
					const patientContact = currentChat
						? currentChat.contacts.find((contact) => !contact.remoteId || !(contact.remoteId > 0)) : undefined;
					return patientContact && currentChat && (
						<CallPatient
							className="btn btn-sm btn-primary pull-right"
							patientAvatar={patientContact.avatar}
							patientName={`${patientContact.firstName} ${patientContact.lastName}`}
							portalId={patientContact.id}
							chatId={currentChat.id}
						>
							<i className="fa fa-phone" />
						</CallPatient>
					);
				},
				ChatPlugins.VideoChat,
				ChatPlugins.VoiceCall,
				ChatPlugins.CompleteChat,
				ChatPlugins.LeaveChat,
				({ patientId, currentChat, user }) => currentChat.kind === ChatKind.Group && user.role === UserRole.Admin
				&& currentChat.contacts.some((item) => item.remoteId === user.id)
				&& (
					<SupportChatUsersSettings
						user={user}
						currentSupportChat={currentChat}
						getPatientId={(id) => getPatientId(id, history, patientId)}
					/>
				),
			]}
			renderChatName={({ currentChat: currentSupportChat, user, patientId }) => {
				const patientContact = currentSupportChat
					? currentSupportChat.contacts.find((contact) => !contact.remoteId || !(contact.remoteId > 0)) : undefined;
				return (
					<>
						<LinkWithPrevLocation
							to={{}}
							onClick={() => {
								patientContact && getPatientId(patientContact.id, history, patientId);
							}}
						>
							{getChatName(currentSupportChat, user.id)}
						</LinkWithPrevLocation>
					</>
				);
			}}
			pageSettings={{
				path: '/support-chats',
				chatIdUrlKey: 'supportChatId',
				archive: 'supportArchive',
			}}
			storageName="supportChats"
			requests={{
				chat: 'supportChat',
				chatUser: 'supportChatUser',
				chatMessage: 'supportChatMessage',
				chatMessageAccess: 'supportChatMessageAccess',
				typing: 'supportTyping',
				completeChat: 'supportChatArchiveRemote',
				getChat: 'getSupportChat',
				loadMessages: 'supportChatMessageList',
				loadChats: 'supportChatList',
				getOrCreatePersonalChat: 'getOrCreatePersonalChat',
			}}
			notificationTypes={{
				chat: 'SupportChat',
				chatUser: 'ChatUser',
				chatMessage: 'SupportChatMessage',
				chatReaction: 'SupportChatEmojiReaction',
				chatMessageAccess: 'SupportChatMessageAccess',
				typing: 'SupportTyping',
				updateChatCounterNotification: 'UpdateSupportChatCounterNotification',
				updateUserMessagesCountNotification: 'UpdateUserSupportMessagesCountNotification',
			}}
			chatsFilters={{
				loadAllChats: false,
				archive: false,
				online: true,
			}}
			formSettings={{
				underFormLabel: 'Press Ctrl+Enter to send a message',
				maxMessageLength: 1000,
				maxAttachmentsCount: 5,
				allowPasteImages: false,
				sendByEnter: false,
			}}
			formButtons={[
				ChatPlugins.Template,
				ChatPlugins.VideoMessage,
				ChatPlugins.VoiceMessage,
			]}
			chatStoreSettings={{
				getCurrentChat: (state) => state.supportChats.currentChat,
				getChatStore: (state) => ({
					chats: state.supportChats.chats,
					chatsLoaded: state.supportChats.chatsLoaded,
				}),
				getMessages: (chatId) => (state) => state.supportChats.messages[chatId] || undefined,
				userUnviewedMessagesCountName: 'unviewedSupportMessagesCount',
			}}
			title="Support Chats"
			basePath="/remote/"
		>
			{children}
		</SimpleChatSettingsProvider>
	);
};

const SupportChats: React.FC = () => {
	return (
		<ItemsProvider<Location>
			type="locationPage"
			skipInitLoad={false}
		>
			<SupportChatSettingsProvider>
				<Chats />
			</SupportChatSettingsProvider>
		</ItemsProvider>
	);
};

export default SupportChats;
