import React from 'react';

import { html } from '@codemirror/lang-html';
import CodeMirror, { ReactCodeMirrorProps } from '@uiw/react-codemirror';
import { html as htmlBeautify } from 'js-beautify';

import Wysiwyg, { WysiwygProps } from '@common/react/components/Forms/Wysiwyg/Wysiwyg';

import '@common/react/scss/components/wysiwygWithCodeMirror.scss';

interface Props extends Omit<WysiwygProps, 'customHtmlViewer'> {
	codeMirrorProps?: Omit<ReactCodeMirrorProps, 'value' | 'onChange' | 'readOnly'>;
}

const CodeMirrorWithBeautify: React.FC<ReactCodeMirrorProps & { beautifyDependency?: number; }> = (props) => {
	const {
		beautifyDependency,
		...rest
	} = props;
	const [beautifyValue, setBeautifyValue] = React.useState(() => {
		return htmlBeautify(props.value);
	});
	React.useEffect(() => {
		if (props.value !== beautifyValue) {
			const newValue = htmlBeautify(props.value);
			props.onChange?.(newValue, {} as any);
			setBeautifyValue(newValue);
		}
	}, [beautifyDependency]);
	return <CodeMirror
		className="code-mirror"
		height="274px"
		theme="dark"
		extensions={[html({})]}
		{...rest}
	/>;
};

const WysiwygWithCodeMirror: React.FC<Props> = (props) => {
	const {
		codeMirrorProps,
		...rest
	} = props;
	return <Wysiwyg
		{...rest}
		customHtmlViewer={(value, onChange, readonly, beautifyDependency) => <CodeMirrorWithBeautify
			beautifyDependency={beautifyDependency}
			className="code-mirror"
			height="274px"
			theme="dark"
			extensions={[html({})]}
			{...codeMirrorProps}
			onChange={onChange}
			readOnly={readonly}
			value={value}
		/>}
	/>;
};

export default WysiwygWithCodeMirror;
