import * as React from 'react';

import { ChatPlugin } from '@common/react/components/Chat/Chat';
import Button from '@common/react/components/Forms/Button';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';

const ModalButton: React.FC = () => {
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { modalMode }, actions: { setModalMode } } = context;

	const handleClick = () => {
		setModalMode(true);
	};
	if (modalMode) {
		return null;
	}
	return <Button
		onClick={handleClick}
		className="btn btn-sm btn-primary pull-right"
		title={modalMode ? 'Close modal' : 'Open as modal'}
	>
		<i className={`fa fa-${modalMode ? 'times' : 'window-restore'}`} />
	</Button>;
};

export const ModalPlugin : ChatPlugin = {
	messagesHeaderAction: () => <ModalButton />,
};
