import React from 'react';

import Button from 'antd/lib/button';

import { RecordResult } from '@common/react/utils/record-audio';
import VoiceMessageButton from '@common/react/components/UI/VoiceMessage/VoiceMessageButton';
import VoiceMessagePlayer from '@common/react/components/UI/VoiceMessage/VoiceMessagePlayer';

import '@common/react/scss/components/voiceMessageRecorder.scss';

interface VoiceMessageComponent {
	recordAudioResult: RecordResult | undefined;
	setRecordAudioResult: (r: RecordResult | undefined) => void;
	onChange: (recordAudioResult: RecordResult | undefined) => void;
	className?: string;
	shape?: 'circle' | 'default' | 'round';
	waveColor?: string;
	disabled?: boolean;
}

const VoiceMessageRecorder: React.FC<VoiceMessageComponent> = ({
	onChange, recordAudioResult, setRecordAudioResult, ...rest
}) => {
	const { className = 'btn-primary', shape = 'default', disabled } = rest;
	const reset = () => {
		setRecordAudioResult(undefined);
		onChange(undefined);
	};

	const handleClearClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault();
		reset();
	};

	const [key, setKey] = React.useState<string | number>('default');

	React.useEffect(() => {
		setKey(Math.random());
	}, [recordAudioResult]);

	const handleChange = (value) => {
		setKey(Math.random());
		onChange(value);
	};

	return recordAudioResult ? <div className="voice-message-preview-player">
		<VoiceMessagePlayer
			key={key}
			url={recordAudioResult.url}
			style={{ marginRight: 10 }}
			width={200}
			btnClassName={className}
			shape={shape}
			waveColor={rest.waveColor}
		/>
		<Button
			className={`voice-message-clear-btn ${className}`}
			shape={shape}
			icon={<i className="fa fa-remove" />}
			onClick={handleClearClick}
		/>
	</div> : <VoiceMessageButton
		key={key}
		shape={shape}
		className={className}
		onStop={handleChange}
		disabled={rest.disabled}
		setRecordAudioResult={setRecordAudioResult}
	/>;
};

export default VoiceMessageRecorder;
