import * as React from 'react';
import { useDispatch } from 'react-redux';

import once from 'lodash/once';

import { useApplicationContext, AfterLogout } from '@common/react/components/Core/Application/Application';
import ItemsProviderWithStore from '@common/react/components/Core/ItemsProviderWithStore/ItemsProviderWithStore';
import { Lang } from '@common/typescript/objects/Lang';
import ItemsProviderSynchronizer from '@common/react/components/Core/AdvancedItemsProvider/ItemsProviderSynchronizer';
import { SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import { TypeKeys } from '@common/react/store/ItemsProviderStore';

import { CompanyFeature, Feature } from '@commonTuna/react/objects/CompanyFeature';

import { LanguageInfo, languages } from '@app/objects/Langs';

export interface CompanyFeatureProviderContext {
	checkFeatureAccess: (feature: Feature | Array<Feature>, anyMatch?: boolean) => boolean;
	getAvailableLanguages: () => Array<LanguageInfo>;
}

export const createCompanyFeatureProviderContext = once(() => React.createContext({} as CompanyFeatureProviderContext));

export const useCompanyFeatureProviderContext: () => CompanyFeatureProviderContext = () =>
	React.useContext(createCompanyFeatureProviderContext());

const CompanyFeaturesSetter: React.FC = ({
	children,
}) => {
	const CompanyFeatureProviderContext = createCompanyFeatureProviderContext();
	const { getUser } = useApplicationContext();
	const user = getUser();
	const dispatch = useDispatch();

	return (
		<ItemsProviderWithStore<CompanyFeature>
			defaultSort={['id', SortingDirection.Ascending]}
			filterHandler={() => true}
			sortHandler={(a, b) => a.id - b.id}
			storeName="companyFeatures"
			type="companyFeature"
			loadRequest="companyFeatureInitList"
			pagination={{ pageSize: 100, total: 100 }}
			skipInitLoad={!user}
			syncStoreItems
		>
			{(state, actions) => {
				const checkFeatureAccess = (feature: Feature | Array<Feature>, anyMatch?: boolean) =>
					(Array.isArray(feature)
						? anyMatch
							? feature.some((f) => state.items.find((c) => c.feature === f))
							: feature.every((f) => state.items.find((c) => c.feature === f))
						: !!state.items.find((c) => c.feature === feature));

				const getAvailableLanguages = () => (checkFeatureAccess(Feature.MultipleLanguages)
					? languages : languages.filter((l) => l.language === Lang.En));

				return (
					<>
						<ItemsProviderSynchronizer
							objectType="CompanyFeatureRedux"
							storeName="companyFeatures"
							getItemsFromInit={(notification) => notification.data.companyFeatures}
						/>
						<AfterLogout
							callback={() => dispatch({
								items: [],
								params: {},
								type: TypeKeys.SETITEMS,
								storageName: 'companyFeatures',
								objectType: 'companyFeature',
								total: 0,
								current: 1,
								isEmpty: true,
							})}
						/>
						<CompanyFeatureProviderContext.Provider value={{ checkFeatureAccess, getAvailableLanguages }}>
							{children}
						</CompanyFeatureProviderContext.Provider>
					</>
				);
			}}
		</ItemsProviderWithStore>
	);
};

export default CompanyFeaturesSetter;
