import React from 'react';

import '@common/react/scss/components/forwardMessage.scss';

interface ForwardLastMessageProps {
	message: any;
	chat: any;
	userId: number;
}

const ForwardLastMessage: React.FC<ForwardLastMessageProps> = ({ message, chat, userId }) => {
	return (
		<div className="forward-message__last">
			<div className="chat-list__item-last-message">
				{ message.userId === userId ? `You: ${message.text}` : message.text }
			</div>
			<div className="forward-message__last__icon">
				Forwarded
			</div>
		</div>
	);
};

export default ForwardLastMessage;
