import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { WithFile } from '@common/typescript/objects/WithFile';
import { Nullable } from '@common/typescript/objects/Nullable';

import { numberWithComma } from '@commonTuna/react/utils/NumberWithComma/NumberWithComma';

import { Location } from '@app/objects/Location';
import { Category } from '@app/objects/Product/Category';
import { User } from '@app/objects/User';
import { CommissionType } from '@app/objects/Company';

export interface Product extends WithDeleted {
	name: string;

	path: string;

	shortDescription: string;
	longDescription: string;
	additionalDescription: string;
	avatar: string;

	color: string;

	usable: boolean;
	taxable: boolean;
	isPriceUpcharge: boolean;

	categoryId: Nullable<number>;
	category: Nullable<Category>;

	categories: Array<ProductCategory>;
	locations: Array<ProductLocation>;

	files: Array<ProductImage>;

	parent: Nullable<number>;
	parentObject: Nullable<Product>;

	lowStock: number;

	active: boolean;
	giftCard: boolean;
	_type: string;
}

export interface ProductCategory extends WithDeleted {
	productId: number;
	product?: Product;

	categoryId: number;
	category?: Category;
}

export interface ProductImage extends WithFile<Product> {
	primary: boolean;
}

export interface ProductLocation extends WithDeleted {
	productId: number;
	product?: Product;

	locationId: number;
	location?: Location;

	count: number;
	price: number;
	averagePrice: number;

	productCommission: number;
	commissionType: CommissionType;
	cost: number | null;
	_type: string;
}

export interface UserProduct extends WithDeleted {
	userId: number;
	user: User;

	productId: number | null;
	product: Product | null;

	locationId: number | null;
	location: Location | null;

	commission: number;
	commissionType: CommissionType;
}

export interface WasteProduct extends WithDeleted {
	userId: number;
	user: User;

	productId: number;
	product: Product | null;

	locationId: number;
	location: Location | null;

	count: number;
	note: string;
	created: number;
}

export const formatPrice = (price: number) => numberWithComma(price?.toFixed(2));

export const toFixedNumber = (num: number): number => parseFloat(num.toFixed(2));

export const getTax = (item: ProductLocation, location: Location | null) => {
	return item.product && item.product.taxable && location && location.productTax > 0
		? +formatPrice((item.price / 100) * location.productTax)
		: 0;
};
