import React from 'react';

import Radio, { RadioChangeEvent } from 'antd/lib/radio';

import { disabledFilterGroup } from '@app/components/Pages/Admin/BaseReport/BaseReport';

interface Props {
	value: boolean | undefined;
	onChange: (value: boolean | undefined) => void;
	withIcons?: boolean;
	className?: string;
	disabled?: boolean;
	allAsText?: boolean;
}

const DisabledFilter: React.FC<Props> = (props) => {
	const {
		value, onChange, withIcons, className = 'pull-right ml10 mb10 custom-radio', children, disabled, allAsText,
	} = props;
	const handleChange = (e: RadioChangeEvent) => {
		onChange(e.target.value);
	};

	return (
		<Radio.Group
			className={className}
			name="disabled"
			value={value ?? undefined}
			onChange={handleChange}
			disabled={disabled}
		>
			{children || disabledFilterGroup.map((item) => (
				<Radio.Button value={item.value} key={item.caption}>
					<div className="custom-radio-button" title={item.caption}>
						{withIcons && !(item.value === undefined && allAsText) ? (
							<i
								className={`fa fa-${item.value !== undefined
									? item.value
										? 'user-times'
										: 'user-o'
									: 'users'}`}
								title={item.caption}
								aria-hidden="true"
							/>
						) : item.caption}
					</div>
				</Radio.Button>
			))}
		</Radio.Group>
	);
};

export default DisabledFilter;
