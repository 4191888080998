import React, { KeyboardEventHandler } from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';

import '@common/react/scss/components/simpleInputNumber.scss';

type StringNumber = string | number;

export interface HandleChangeEvent {
	currentTarget: {
		name: string;
		value?: Nullable<StringNumber>;
	};
}

interface SimpleInputNumberInterface {
	field: string;
	placeholder: string;
	className: string;
	onChange: (evt: HandleChangeEvent) => void;
	value: string;
	hasMin?: boolean;
	emptyAsUndefined?: boolean;
	delay?: number; // ms
	title?: string;
	transformValue?: (value) => string;
	onKeyPress?: KeyboardEventHandler<HTMLInputElement> | undefined;
	savePlaceholder?: boolean;
}

export const SimpleInputNumber: React.FC<SimpleInputNumberInterface> = ({
	onChange,
	field,
	placeholder,
	className = 'form-control',
	value: propsValue,
	hasMin = true,
	emptyAsUndefined = true,
	delay = 1000,
	title,
	transformValue,
	onKeyPress,
	savePlaceholder,
}) => {
	const [value, setValue] = React.useState<string>(propsValue);
	const valueRef = React.useRef<HandleChangeEvent | null>(null);

	const handleChange = (e) => {
		const value = transformValue ? transformValue(e.currentTarget.value) : e.currentTarget.value;
		setValue(value);

		valueRef.current = { currentTarget: { value, name: e.currentTarget.name } };
		if (emptyAsUndefined && e.currentTarget.value === '') {
			valueRef.current.currentTarget.value = undefined;
		}

		if (!delay) {
			onChange(valueRef.current);
		} else {
			new Promise((resolve) => {
				setTimeout(resolve, delay);
			})
				.then(() => {
					if (valueRef.current !== null) {
						onChange(valueRef.current);
						valueRef.current = null;
					}
				});
		}
	};

	const input = (<input
		type="number"
		title={title}
		className={className}
		name={field}
		placeholder={placeholder}
		onChange={handleChange}
		min={hasMin ? 0 : undefined}
		value={value || ''}
		onKeyPress={onKeyPress}
	/>);

	return savePlaceholder ? <div
		className={`is-relative fixed-placeholder ${value ? 'fixed-placeholder__top' : ''}`}
		data-placeholder={placeholder}
	>
		{input}
	</div> : input;
};
