import * as React from 'react';

import Mentions from 'antd/lib/mentions';

import { ChatPlugin } from '@common/react/components/Chat/Chat';
import { BaseUserWithAvatar } from '@common/typescript/objects/BaseUser';

export const MentionPlugin: ChatPlugin = {
	formComponent: (props) => <Mentions
		{...props}
	/>,
};
