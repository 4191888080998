import { ReducersMapObject } from 'redux';

import * as Items from '@common/react/store/ItemList';
import * as Item from '@common/react/store/Item';
import { BaseAppThunkAction, baseReducers } from '@common/react/store';
import {
	ApplicationStateWithChats,
	ChatsState,
	getReducer as getChatsReducer,
} from '@common/react/components/Chat/Store/Chats';
import { PageItemState, reducer as PageStateReducer } from '@common/react/store/PageItem';
import { ServerPagesState, reducer as ServerPagesReducer } from '@common/react/store/ServerPages';
import { CommentsState, getReducer as getCommentsReducer } from '@common/react/store/Comments';
import { ItemsState as ItemsProviderStoreState, getReducer as getIPStoreReducer } from '@common/react/store/ItemsProviderStore';
import { VideoChatState, getReducer as getVideoChatReducer } from '@common/react/store/VideoChat';

import { CompanyFeature } from '@commonTuna/react/objects/CompanyFeature';

import { User, UserDashboard, UserStatus } from '@app/objects/User';
import { Inquiry } from '@app/objects/Inquiry';
import { Location } from '@app/objects/Location';
import { Patient, AllergiesProperties, AppointmentTab } from '@app/objects/Patient';
import { Appointment, AppointmentStatus, AppointmentType } from '@app/objects/Appointment';
import { MenuState, reducer as MenuReducer } from '@app/store/Menu';
import { DashboardState, reducer as DashboardReducer } from '@app/store/Dashboard';
import { TestSession } from '@app/objects/TestSession';
import { CompanySettings } from '@app/objects/Company';
import { PatientPharmacy } from '@app/objects/PatientPharmacy';
import { getReducer as getStatusReducer, State as SwitcherState } from '@app/store/SwitcherStore';
import { HostOptions } from '@app/objects/HostOptions';
import { PhoneCallState, getReducer as getPhoneCallReducer } from '@app/store/PhoneCall';
import { getReducer as getUserFavoritesReducer, UserFavoriteState } from '@app/store/UserFavorites';
import { Doctor } from '@app/objects/Doctor';
import { BuildData } from '@app/objects/BuildData';
import { ProcedureGroup } from '@app/objects/ProcedureGroup';
import { SecureRoutesPropsPagesMap } from '@app/components/Routes/SecureRoute/SecureRoutesPropsPagesMap';
import { HiddenAppointmentDots } from '@app/objects/HiddenAppointmentDots';
import { EnumAccesses } from '@app/objects/Init';
import { FacebookOptions } from '@app/objects/FacebookOptions';

// The top-level state object
export interface ApplicationState extends ApplicationStateWithChats<User> {
	serverPage: PageItemState<any>;
	serverPages: ServerPagesState;

	enumAccesses: Item.ItemState<EnumAccesses>;
	routesPropsPagesMap: Item.ItemState<SecureRoutesPropsPagesMap>;

	allergyItems: SwitcherState<AllergiesProperties>;

	appointment: Item.ItemState<Appointment>;

	appointmentTypes: ItemsProviderStoreState<AppointmentType>;
	appointmentStatuses: ItemsProviderStoreState<AppointmentStatus>;

	buildData: Item.ItemState<BuildData>;

	calendar: Items.ItemsState<Appointment>;

	comments: CommentsState<User>;

	// companies: Items.ItemsState<Company>;
	companySettings: Item.ItemState<CompanySettings>;

	companyFeatures: ItemsProviderStoreState<CompanyFeature>;

	dashboard: DashboardState;

	doctorAppointmentPaymentService: ItemsProviderStoreState<Doctor>;

	facebookOptions: Item.ItemState<FacebookOptions>;

	hostOptions: Item.ItemState<HostOptions>;

	locations: ItemsProviderStoreState<Location>;

	menu: MenuState;

	patient: Item.ItemState<Patient<AppointmentTab & Appointment>>;
	patientPharmacies: Items.ItemsState<PatientPharmacy>;

	procedureGroups: ItemsProviderStoreState<ProcedureGroup>;

	testSessions: Items.ItemsState<TestSession>;

	user: Item.ItemState<User>;
	userStatuses: SwitcherState<UserStatus>;
	userDashboardSettings: Items.ItemsState<UserDashboard>;

	userFavorites: UserFavoriteState;

	statusItems: SwitcherState<AppointmentStatus>;
	supportChats: ChatsState;

	phoneCall: PhoneCallState;
	videoChat: VideoChatState;

	inquiryProcesses: ItemsProviderStoreState<Inquiry>;
	hiddenAppointmentDots: ItemsProviderStoreState<HiddenAppointmentDots>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers,

	serverPage: PageStateReducer<any>('serverPage'),
	serverPages: ServerPagesReducer<any>('serverPages'),

	allergyItems: getStatusReducer<AllergiesProperties>('allergiesProperties'),

	appointment: Item.getReducer<Appointment>('appointment'),

	routesPropsPagesMap: Item.getReducer<SecureRoutesPropsPagesMap>('routesPropsPagesMap'),
	enumAccesses: Item.getReducer<EnumAccesses>('enumAccesses'),

	appointmentTypes: getIPStoreReducer<AppointmentType>('appointmentTypes'),
	appointmentStatuses: getIPStoreReducer<AppointmentStatus>('appointmentStatuses'),

	buildData: Item.getReducer<BuildData>('buildData'),

	calendar: Items.getReducer<Appointment>('calendar'),

	chats: getChatsReducer(),

	comments: getCommentsReducer(),

	companySettings: Item.getReducer<CompanySettings>('companySettings'),

	companyFeatures: getIPStoreReducer<CompanyFeature>('companyFeatures'),

	dashboard: DashboardReducer,

	doctorAppointmentPaymentService: getIPStoreReducer<Doctor>('doctorAppointmentPaymentService'),

	facebookOptions: Item.getReducer<FacebookOptions>('facebookOptions'),

	hostOptions: Item.getReducer<HostOptions>('hostOptions'),

	locations: getIPStoreReducer<Location>('locations'),

	menu: MenuReducer,

	patient: Item.getReducer<Patient>('patient'),
	patientPharmacies: Items.getReducer<PatientPharmacy>('patientPharmacies'),

	procedureGroups: getIPStoreReducer<ProcedureGroup>('procedureGroups'),

	testSessions: Items.getReducer<TestSession>('testSessions'),

	user: Item.getReducer<User>('user'),
	userDashboardSettings: Items.getReducer<UserDashboard>('userDashboardSettings'),

	userStatuses: getStatusReducer<UserStatus>('userStatuses'),
	userFavorites: getUserFavoritesReducer(),

	supportChats: getChatsReducer('supportChats'),

	statusItems: getStatusReducer<AppointmentStatus>('statuses'),

	phoneCall: getPhoneCallReducer(),
	videoChat: getVideoChatReducer(),

	inquiryProcesses: getIPStoreReducer<Inquiry>('inquiryProcesses'),
	hiddenAppointmentDots: getIPStoreReducer<HiddenAppointmentDots>('hiddenAppointmentDots'),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = BaseAppThunkAction<TAction, User, ApplicationState>
