import React from 'react';

import Button from 'antd/lib/button';

import { RecordResult } from '@common/react/utils/record-audio';

import '@common/react/scss/components/voiceMessageRecorder.scss';

interface VoiceMessagePlayer {
	url: string;
	width?: number;
	style?: React.CSSProperties;
	className?: string;
	onPlayed?: () => void;
	btnClassName?: string;
	shape?: 'circle' | 'default' | 'round';
	waveColor?: string;
}

const VoiceMessagePlayer: React.FC<VoiceMessagePlayer> = (props) => {
	const {
		url,
		style,
		className = '',
		width,
		onPlayed,
		btnClassName = 'btn-primary',
		shape = 'default',
		waveColor = '#58a0e8',
	} = props;

	const [isPlaying, setIsPlaying] = React.useState<boolean>(false);

	const wavesurfer = React.useRef<any>();
	const wavesurferContainer = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		importWavesurfer()
			.then((wavesurfer) => initializeWavesurfer(wavesurfer.default))
			.catch(console.warn);
	}, []);

	const handlePlay = () => {
		setIsPlaying(true);
		onPlayed && onPlayed();
	};

	const handlePause = () => {
		setIsPlaying(false);
	};

	const handleFinish = () => {
		setIsPlaying(false);
		wavesurfer.current?.stop();
	};

	const importWavesurfer = async () => {
		return import('wavesurfer.js') as any;
	};

	const initializeWavesurfer = (wavesurferBundle) => {
		if (!wavesurferContainer.current) return;

		wavesurfer.current = wavesurferBundle.create({
			container: wavesurferContainer.current,
			waveColor,
			progressColor: '#cacaca',
			barWidth: 2,
			barHeight: 2,
			barMinHeight: 2,
			height: 40,
			cursorColor: 'transparent',
		});

		wavesurfer.current?.on('play', handlePlay);
		wavesurfer.current?.on('pause', handlePause);
		wavesurfer.current?.on('finish', handleFinish);

		wavesurfer.current?.load(url);
	};

	const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault();

		if (!wavesurfer.current) return;

		if (!isPlaying) {
			wavesurfer.current.play();
		} else {
			wavesurfer.current.pause();
		}
	};

	return (
		<div
			className={`voice-message-player ${className}`}
			style={{
				display: 'flex',
				alignItems: 'center',
				...style,
			}}
		>
			<Button
				title={isPlaying ? 'Play' : 'Pause'}
				type="primary"
				className={`voice-message-play-btn ${btnClassName}`}
				shape={shape}
				icon={!isPlaying ? <i className="fa fa-play" /> : <i className="fa fa-pause" />}
				onClick={handleClick}
			/>
			<div className="wavesurfer-container" style={{ width: width ?? 500 }} ref={wavesurferContainer} />
		</div>);
};

export default VoiceMessagePlayer;
