import * as React from 'react';

import { ChatPlugin } from '@common/react/components/Chat/Chat';
import ArchiveButton from '@common/react/components/Chat/ArchivePlugin/ArchiveButton';
import Button from '@common/react/components/Forms/Button';

export const ArchivePlugin : ChatPlugin = {
	chatsListHeaderComponent: ({
		filters, handleChange, selectChat, pageSettings,
	}) =>
		<ArchiveButton filters={filters} handleChange={handleChange} selectChat={selectChat} pageSettings={pageSettings} />,
	messagesHeaderAction: ({
		state, currentChat, user, completeChat, withRemoteId,
	}) =>
		currentChat.contacts.some((item) => (withRemoteId ? item.remoteId === user?.id : item.id === user?.id)) && <Button
			className="btn btn-sm btn-primary pull-right is-relative"
			title={currentChat.archive ? 'Activate chat' : 'Complete chat'}
			isLoading={state.completeChatLoading}
			onClick={() => completeChat(currentChat)}
		>
			<i className="fa fa-archive" aria-hidden="true" />
			{currentChat.archive && <i className="fa fa-times disabled-icon" />}
		</Button>,
};
