import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';

import { MainLayout } from '@app/components/Layouts/MainLayout';

interface Props extends RouteProps {
	component: any;
}

export const MainRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (
			<MainLayout>
				<ErrorBoundaryWithSentry>
					<RequestProvider>
						<Component {...props} />
					</RequestProvider>
				</ErrorBoundaryWithSentry>
			</MainLayout>
		)}
	/>
);
