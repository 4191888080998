import * as React from 'react';

import loadable from '@loadable/component';

import { ChatPlugin } from '@common/react/components/Chat/Chat';
import { loadableDelay } from '@common/react/loadable/loadableSettings';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

const AdaptiveCardsNode = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "AdaptiveCardsNode" */ '@common/react/components/Chat/AdaptiveCards/AdaptiveCardsNode')),
	{ fallback: <div className="text-center"><Loader /></div> },
);

export const AdaptiveCardsPlugin: ChatPlugin = {
	messageAttachment: (message) => {
		return (message.attachment ? <React.Fragment key="file">
			<AdaptiveCardsNode chatId={message.chatId} payload={message.attachment} />
		</React.Fragment> : null);
	},
};
