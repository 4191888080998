import React from 'react';

import { Notification } from '@common/typescript/objects/Notification';
import { BaseUser, BaseUserWithAvatar } from '@common/typescript/objects/BaseUser';
import VideoChatButton from '@common/react/components/UI/VideoChat/VideoChatButton';
import CallSound from '@common/react/components/UI/VideoChat/CallSound';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import '@common/react/scss/components/videoChatGlider.scss';

enum AnimationState {
	StartShow,
	Visible,
	StartHide,
	Hidden
}

interface VideoChatNotify {
	roomIdentifier: string;
	creator: BaseUserWithAvatar;
	restMembers: Array<BaseUserWithAvatar>;
}

interface VideoChatGliderState {
	animationState: AnimationState;
	roomIdentifier: string;
	creator: BaseUserWithAvatar | null;
	restMembers: Array<BaseUserWithAvatar> | null;
	notifyUserIds: Array<number>;
	chatId?: number;
}

interface VideoChatGliderProps {
	renderAvatar: (creator: BaseUserWithAvatar | null, imageSkeleton?: string) => React.ReactNode;
	imageSkeleton?: string;
}

const VideoChatGlider: React.FC<VideoChatGliderProps> = ({ renderAvatar, imageSkeleton }) => {
	const [state, setState] = React.useState<VideoChatGliderState>({
		animationState: AnimationState.Hidden,
		roomIdentifier: '',
		creator: null,
		restMembers: null,
		notifyUserIds: [],
	});
	const timeoutRef = React.useRef<NodeJS.Timeout>();
	const { subscribe } = useApplicationContext();

	const notificationHandle = (notification: Notification<BaseUser>) => {
		if (notification.objectType !== 'VideoChatNotify') return;
		const data = notification.data as VideoChatNotify;

		setState((state) => ({
			...state,
			creator: data.creator,
			animationState: AnimationState.StartShow,
			roomIdentifier: data.roomIdentifier,
			avatar: data.creator,
			chatId: (data as any).chatId,
			notifyUserIds: data.restMembers.map((item) => item.id).concat(data.creator.id),
		}));

		timeoutRef.current = setTimeout(() => {
			setState((state) => ({
				...state,
				animationState: AnimationState.StartHide,
			}));
		}, 10_000);
	};

	React.useEffect(() => {
		subscribe(notificationHandle)();
	}, []);

	const className = React.useMemo(() => {
		const defaultClassName = 'video-chat-glider';

		if (state.animationState === AnimationState.Hidden) {
			return `${defaultClassName} hidden`;
		}

		if (state.animationState === AnimationState.StartHide) {
			return `${defaultClassName} hide-anim`;
		}

		if (state.animationState === AnimationState.StartShow) {
			return `${defaultClassName} visible show-anim`;
		}

		return `${defaultClassName} visible`;
	}, [state.animationState]);

	const handleAnimationEnd: React.AnimationEventHandler = React.useCallback((e) => {
		if (state.animationState === AnimationState.StartShow) {
			setState((state) => ({ ...state, animationState: AnimationState.Visible }));
		}

		if (state.animationState === AnimationState.StartHide) {
			setState((state) => ({
				...state,
				animationState: AnimationState.Hidden,
				roomIdentifier: '',
				avatar: imageSkeleton,
				creator: null,
				notifyUserIds: [],
			}));
		}
	}, [state.animationState]);

	const handleCancelClick: React.MouseEventHandler = (e) => {
		e.preventDefault();

		setState((state) => ({ ...state, animationState: AnimationState.StartHide }));

		timeoutRef.current && clearTimeout(timeoutRef.current);
	};

	const handleAnswerClick = () => {
		setState((state) => ({ ...state, animationState: AnimationState.StartHide }));

		timeoutRef.current && clearTimeout(timeoutRef.current);
	};

	return (
		<div className={className} onAnimationEnd={handleAnimationEnd}>
			{state.roomIdentifier && state.animationState !== AnimationState.StartHide ? <CallSound /> : null}
			<div className="avatar-wrapper">
				{state.creator && renderAvatar(state.creator, imageSkeleton)}
			</div>
			<div className="video-chat-glider-info">
				<div className="info mb10">
					{state.creator?.firstName}
					{state.creator?.lastName ? <>
&nbsp;
						{state.creator?.lastName}
					</> : null}
					<br />
					Room:
					{' '}
					{state.roomIdentifier.split('_')[1]}
				</div>
				<div className="video-chat-glider-controls">
					<VideoChatButton
						notifyUserIds={state.notifyUserIds}
						isVideo={false}
						chatId={state.chatId}
						roomCreateActor="videoChat"
						roomId={state.roomIdentifier}
						roomName={state.roomIdentifier.split('_')[1]}
						button={(onClick, isDisabled) =>
							<button
								type="button"
								onClick={onClick}
								disabled={isDisabled}
								className="video-glider-btn answer"
							>
								<i className="fa fa-phone" />
							</button>}
						onClick={handleAnswerClick}
					/>
					<button
						type="button"
						onClick={handleCancelClick}
						className="video-glider-btn cancel"
					>
						<i className="fa fa-phone" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default VideoChatGlider;
