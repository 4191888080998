import * as React from 'react';
// eslint-disable-next-line
import once from 'lodash/once';

import '@app/scss/components/numberWithComma.scss';

export interface NumberWithCommaProviderProps {
	round?: boolean;
	fractionDigits?: number;
}

export interface NumberWithCommaProviderContextState {
	round: boolean;
	fractionDigits: number;
}

export interface NumberWithCommaProviderContext {
	state: NumberWithCommaProviderContextState;
	actions: {
		toggle: () => void;
		setFractionDigits: (state: React.SetStateAction<number>) => void;
		setRound: (state: React.SetStateAction<boolean>) => void;
	};
}

export const createNumberWithCommaProviderContext = once(() => React.createContext({} as NumberWithCommaProviderContext));

export const useNumberWithCommaProviderContext: () => NumberWithCommaProviderContext = () => React.useContext(createNumberWithCommaProviderContext());

export const NumberWithCommaProvider: React.FC<NumberWithCommaProviderProps> = ({ children, round: roundProps, ...props }) => {
	const ItemContext = createNumberWithCommaProviderContext();

	const [round, setRound] = React.useState<boolean>(roundProps ?? true);
	const [fractionDigits, setFractionDigits] = React.useState(props.fractionDigits ?? 2);
	const toggle = () => setRound((prev) => !prev);

	React.useEffect(() => {
		if (roundProps !== undefined && roundProps !== round) {
			setRound(roundProps);
		}
	}, [roundProps]);

	const value = React.useMemo(() => ({
		state: {
			round,
			fractionDigits,
			setFractionDigits,
		},
		actions: {
			toggle,
			setFractionDigits,
			setRound,
		},
	}), [round, fractionDigits]);

	return (
		<ItemContext.Provider value={value}>
			{children}
		</ItemContext.Provider>
	);
};

interface RoundToggleButtonProps {
	className?: string;
	style?: React.CSSProperties;
}

export const RoundToggleButton: React.FC<RoundToggleButtonProps> = ({ className = 'pull-right ml10', style = { width: 40 } }) => {
	const context = useNumberWithCommaProviderContext();
	if (!context?.state) {
		return <></>;
	}
	const { state: { round = false, fractionDigits }, actions: { toggle, setFractionDigits, setRound } } = context;

	const increase = () => {
		setFractionDigits((fractionDigits) => (round ? 1 : fractionDigits + 1));
		setRound(false);
	};

	const decrease = () => {
		setFractionDigits((fractionDigits) => {
			if (fractionDigits === 1) {
				setRound(true);
				return fractionDigits;
			}
			return fractionDigits - 1;
		});
	};

	return (
		<div className={`btn-group ${className}`}>
			<button
				className="btn btn-primary"
				style={style}
				type="button"
				title={round ? 'without decimal point' : 'with decimal point'}
				onClick={toggle}
			>
				<i className={round ? 'fa fa-circle-o' : 'fa fa-circle-o-notch'} />
			</button>
			<button
				disabled={fractionDigits >= 5}
				className="btn btn-default round-button"
				type="button"
				title="Increase Decimal"
				onClick={increase}
			>
				<span>
					<i className="fa fa-arrow-left" />
					{' '}
					.0
					<br />
					.00
				</span>
			</button>
			<button
				disabled={round || fractionDigits < 1}
				className="btn btn-default round-button"
				type="button"
				title="Decrease Decimal"
				onClick={decrease}
			>
				<span>
					.00
					<br />
					<i className="fa fa-arrow-right" />
					{' '}
					.0
				</span>
			</button>
		</div>
	);
};
