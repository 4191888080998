import * as React from 'react';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import { useItemsProviderContext } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import AutocompleteFilter from '@common/react/components/Forms/AutocompleteFilter/AutocompleteFilter';
import { OptionType } from '@common/react/components/Forms/Autocomplete/Autocomplete';

export interface CompanyFilterProps {
	value: string;
	param?: string;
	className?: string;
	skipRootCheck?: boolean;
	additionalOptions?: Array<OptionType>;
}

export const CompanyFilter: React.FC<CompanyFilterProps> = ({
	value,
	param = 'companyId',
	className = '',
	skipRootCheck,
	additionalOptions,
}) => {
	const { checkUserAccess } = useApplicationContext();
	const { actions } = useItemsProviderContext<any>();

	return (
		<>
			{(checkUserAccess([]) || skipRootCheck) && (
				<AutocompleteFilter
					type="companyList"
					renderTitle="name"
					className={className}
					handleChangeAndRefresh={actions.handleChange}
					param={param}
					value={value}
					placeholder="Company..."
					loadOnFocus
					additionalOptions={additionalOptions}
				/>
			)}
		</>
	);
};
