import * as React from 'react';

import loadable from '@loadable/component';
import { FormikProps } from 'formik';

import { ChatFormButtonsProps, NewMessage, ChatPlugin } from '@common/react/components/Chat/Chat';
import { loadableDelay } from '@common/react/loadable/loadableSettings';

import Button from '@common/react/components/Forms/Button';
import Logo from '@common/react/components/Chat/TenorPlugin/Logo';

const TenorMessage = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "TenorMessage" */ '@common/react/components/Chat/TenorPlugin/TenorMessage')),
	{ fallback: <div className="tenor-message__loader" /> },
);

const TenorButton = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "TenorButton" */ '@common/react/components/Chat/TenorPlugin/TenorButton')),
	{
		fallback: <Button
			title="Gifs"
			className="btn btn-sm btn-default chat-message-item tenor-button"
			isLoading
			disabled
			type="button"
		>
			<Logo />
		</Button>,
	},
);

const LastMessage = ({ message, userId }) => {
	return <div className="chat-list__item-last-message">
		{message.userId === userId ? 'You: sent gif' : 'gif'}
	</div>;
};

export interface TenorOptions {
	apiKey: string;
	skeleton: string;
}

export interface TenorPlugin extends ChatPlugin {
	options: TenorOptions;
}

export const TenorPlugin: TenorPlugin = {
	formButton: (formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) =>
		(props.chatId ? <TenorButton
			getPopupContainer={props.getPopupContainer}
			chatId={props.chatId}
			apiKey={TenorPlugin.options.apiKey}
		/> : <></>),
	message: {
		render: ({ message }) => {
			const [src, description, width, height] = (message.text || '')
				.split('][')
				.map((str) => str.replace(/[\[\]]/g, ''));

			return <div
				className="tenor-message__wrapper"
				style={{ '--padding-top': `${(height / width) * 100}%` } as React.CSSProperties}
			>
				<TenorMessage url={src} description={description} width={width} height={height} {...TenorPlugin.options} />
			</div>;
		},
		lastMessage: LastMessage,
		notification: ({ message, withRemoteId, contacts }) => {
			const [src, description, width, height] = (message.text || '')
				.split('][')
				.map((str) => str.replace(/[\[\]]/g, ''));

			return <div
				className="tenor-message__wrapper"
				style={{ '--padding-top': `${(height / width) * 100}%` } as React.CSSProperties}
			>
				<TenorMessage withoutModal url={src} description={description} width={width} height={height} {...TenorPlugin.options} />
			</div>;
		},
	},
	options: {
		apiKey: '',
		skeleton: '',
	},
};
