import React from 'react';

enum VideoMessageLoadStatus {
	Idle,
	Loading,
	Loaded,
	Error
}

interface VideoMessagePlayerProps {
	videoMessageUrl: string;
}

interface VideoMessagePlayerState {
	maximized: boolean;
	isPlaying: boolean;
	showIcon: boolean;
	status: VideoMessageLoadStatus;
}

const playIconStyle: React.CSSProperties = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	fontSize: '4rem',
	zIndex: 5,
	color: 'rgba(255, 255, 255, 0.5)',
};

const wrapperStyle: React.CSSProperties = {
	position: 'relative',
	width: '100px',
	height: '100px',
	overflow: 'hidden',
	borderRadius: '50%',
};

const videoStyle: React.CSSProperties = {
	width: '100px',
	height: '100px',
	transform: 'scale(1.8)',
};

const wrapperStyleMaximized: React.CSSProperties = {
	position: 'relative',
	width: '200px',
	height: '200px',
	overflow: 'hidden',
	borderRadius: '50%',
};

const videoStyleMaximized: React.CSSProperties = {
	width: '200px',
	height: '200px',
	transform: 'scale(1.8)',
};

const errorStyle: React.CSSProperties = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100px',
	height: '100px',
	borderRadius: '50%',
	backgroundColor: 'black',
	cursor: 'default',
};

const VideoMessagePlayer: React.FC<VideoMessagePlayerProps> = ({ videoMessageUrl }) => {
	const [state, setState] = React.useState<VideoMessagePlayerState>({
		maximized: false,
		isPlaying: false,
		showIcon: false,
		status: VideoMessageLoadStatus.Idle,
	});

	const videoRef = React.useRef<HTMLVideoElement>(null);

	const setMaximized = (maximized: boolean) => {
		setState({ ...state, maximized });
	};

	const setIsPlaying = (isPlaying: boolean) => {
		setState({ ...state, isPlaying });
	};

	const load = () => {
		if (!videoRef.current) return;
		videoRef.current.load();
		setState((state) => ({ ...state, status: VideoMessageLoadStatus.Loading }));
	};

	const replay = () => {
		if (!videoRef.current) return;
		videoRef.current.pause();
		videoRef.current.currentTime = 0;
		videoRef.current.play()
			.catch(console.warn);
	};

	const stop = () => {
		if (!videoRef.current) return;

		videoRef.current.pause();
		videoRef.current.currentTime = 0;
	};

	const handlePlay = () => {
		setState((state) => ({ ...state, isPlaying: true, showIcon: false }));
	};

	const handlePause = () => {
		setIsPlaying(false);
	};

	const handleEnded = () => {
		if (state.maximized) {
			setMaximized(false);
			stop();
		} else {
			setIsPlaying(false);
			replay();
		}
	};

	const handleMouseEnter = () => {
		if (state.isPlaying) return;

		setState((state) => ({ ...state, showIcon: true }));
	};

	const handleMouseLeave = () => {
		if (state.isPlaying) return;

		setState((state) => ({ ...state, showIcon: false }));
	};

	const handleClick = (e) => {
		e.preventDefault();

		if (!state.maximized) {
			if (state.status === VideoMessageLoadStatus.Idle) {
				load();
			}
			setMaximized(true);
			replay();
		} else {
			setMaximized(false);
			stop();
		}
	};

	const handleLoadedData: React.ReactEventHandler = (e) => {
		setState((state) => ({ ...state, status: VideoMessageLoadStatus.Loaded }));
	};

	const handleError: React.ReactEventHandler = (e) => {
		// Message.error('Could not load video message');
		console.error(`Video message source link: ${videoMessageUrl}`, videoRef.current?.error);
		setState((state) => ({ ...state, status: VideoMessageLoadStatus.Error }));
	};

	if (state.status === VideoMessageLoadStatus.Error) {
		return (
			<div style={errorStyle}>
				<i className="fa fa-trash" style={{ fontSize: '2rem', marginBottom: '5px' }} />
				<span style={{ textAlign: 'center', fontSize: '0.8rem' }}>Failed to load video</span>
			</div>
		);
	}

	return (
		state.status === VideoMessageLoadStatus.Loading
			? <div>Loading</div>
			: (
				<div
					style={state.maximized ? wrapperStyleMaximized : wrapperStyle}
					onClick={handleClick}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<i
						className="fa fa-play"
						style={{
							...playIconStyle,
							visibility: state.showIcon ? 'visible' : 'hidden',
						}}
					/>
					{/* eslint-disable */}
					<video
						ref={videoRef}
						preload="metadata"
						style={state.maximized ? videoStyleMaximized : videoStyle}
						onPlay={handlePlay}
						onPause={handlePause}
						onEnded={handleEnded}
						onError={handleError}
						onLoadedData={handleLoadedData}
					>
						<source src={`${videoMessageUrl}#t=0.1`} type="video/webm" />
					</video>
					{/* eslint-enable */}
				</div>
			)
	);
};

export default VideoMessagePlayer;
