import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import once from 'lodash/once';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import { ApplicationState } from '@app/store';
import { useUserGroupContext } from '@app/components/UI/UserGroupProvider/UserGroupProvider';
import { EnumAccess, EnumAccesses, Dictionary } from '@app/objects/Init';
import { User } from '@app/objects/User';
import { useCompanyFeatureProviderContext } from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';

export interface EnumAccessProviderContext {
	checkAny: (name: string, options: Dictionary<string>) => boolean;
	filterOptions: (name: string, options: Dictionary<string>) => Array<string>;
	checkEnumAccess: (name: string, item: number) => boolean;
}

export const createEnumAccessProviderContext = once(() => React.createContext({} as EnumAccessProviderContext));

export const useEnumAccessProviderContext: () => EnumAccessProviderContext = () =>
	React.useContext(createEnumAccessProviderContext());

const EnumAccessProvider: React.FC = ({
	children,
}) => {
	const EnumAccessProviderContext = createEnumAccessProviderContext();

	const enumAccesses = useSelector((state: ApplicationState) => state.enumAccesses.item, shallowEqual) as EnumAccesses;

	const { getUser } = useApplicationContext();
	const user = getUser<User>();
	const { checkUserGroupAccess } = useUserGroupContext();
	const { checkFeatureAccess } = useCompanyFeatureProviderContext();

	const filterKeys = (accesses: Dictionary<EnumAccess>, options: Dictionary<string>) => {
		return Object.keys(options).filter((item) => canAccess(accesses, +item));
	};

	const canAccess = (accesses: Dictionary<EnumAccess>, item: number) => {
		const access = accesses[item];

		if (!access) return true;

		if (access.roles && access.roles.length > 0 && user?.role && access.roles.indexOf(user?.role) === -1) return false;

		if (access.features && access.features.length > 0 && !checkFeatureAccess(access.features)) return false;

		return !(access.userGroupTypes && access.userGroupTypes.length > 0 && !checkUserGroupAccess(access.userGroupTypes));
	};

	const checkEnumAccess = (name: string, item: number) => {
		const accesses = enumAccesses[name];

		if (!accesses || user?.root) return true;

		return canAccess(accesses, item);
	};

	const checkAny = (name: string, options: Dictionary<string>) => {
		const accesses = enumAccesses[name];

		if (!accesses || user?.root) return true;

		return filterKeys(accesses, options).length > 0;
	};

	const filterOptions = (name: string, options: Dictionary<string>) => {
		const accesses = enumAccesses[name];

		if (!accesses) return Object.keys(options);

		return filterKeys(accesses, options);
	};

	return (
		<>
			<EnumAccessProviderContext.Provider value={{ checkAny, filterOptions, checkEnumAccess }}>
				{children}
			</EnumAccessProviderContext.Provider>
		</>
	);
};

export default EnumAccessProvider;
