import React from 'react';

import { useSelector } from 'react-redux';

import moment from 'moment';

import { ApplicationState } from '@app/store';

const collection2Descriptions = [
	'Good Morning', 'Hello', 'Tank You', 'Your Welcome', 'Welcome', 'Good Bye',
];

const collection3Descriptions = [
	'HaVe a NiCe dAY!', 'GOOD MoRniNG', 'Hello!', 'WhAt\'s Up?', 'GooD NiGHt', 'haVe a BlessED --DaY!--',
];

const halloweenDescriptions = [
	'axe',
	'bat',
	'blood',
	'cake',
	'calendar',
	'castle',
	'cauldron',
	'dracula',
	'grave',
	'lantern',
	'pumpkin',
	'skull',
	'witch-hat',
	'zombie',
	'zombie_trick-or-treat',
];

export const useSickerCollections = () => {
	const user = useSelector((state: ApplicationState) => state.login.user);

	const collections = React.useMemo(() => {
		const now = moment();
		return [
			{
				image: '/images/valentine-s-day-sticker/small/sticker_1.png',
				description: 'valentine`s day stickers (mix 1)',
				items: Array.from({ length: 6 }).map((_, i) => ({
					image: `/images/valentine-s-day-sticker/small/sticker_${i + 1}.png`,
					fullImage: `/images/valentine-s-day-sticker/sticker_${i + 1}.png`,
					description: 'Sticker',
				})),
				enable: now.month() === 1 && now.date() >= 14 && now.date() <= 15,
			},
			{
				image: '/images/valentines/small/valentine-1.png',
				description: 'valentine`s day stickers (mix 2)',
				items: Array.from({ length: 12 }).map((_, i) => ({
					image: `/images/valentines/small/valentine-${i + 1}.png`,
					fullImage: `/images/valentines/valentine-${i + 1}.png`,
					description: '',
				})),
				enable: now.month() === 1 && now.date() >= 14 && now.date() <= 15,
			},
			{
				image: '/images/sticker-collection-1/small/thank-you.png',
				description: 'Doctor stickers',
				items: [
					{
						image: '/images/sticker-collection-1/small/thank-you.png',
						fullImage: '/images/sticker-collection-1/thank-you.png',
						description: 'Thank You. You Are Our Heroes',
					},
					{
						image: '/images/sticker-collection-1/small/doctor-day.png',
						fullImage: '/images/sticker-collection-1/doctor-day.png',
						description: 'Doctor Day',
					},
					{
						image: '/images/sticker-collection-1/small/heroes.png',
						fullImage: '/images/sticker-collection-1/heroes.png',
						description: 'Heroes',
					},
					{
						image: '/images/sticker-collection-1/small/real-hero.png',
						fullImage: '/images/sticker-collection-1/real-hero.png',
						description: 'Real Hero',
					},
					{
						image: '/images/sticker-collection-1/small/be-strong.png',
						fullImage: '/images/sticker-collection-1/be-strong.png',
						description: 'Be Strong. Be Happy',
					},
					{
						image: '/images/sticker-collection-1/small/thank-you-doctor.png',
						fullImage: '/images/sticker-collection-1/thank-you-doctor.png',
						description: 'Thank You Doctor',
					},
				],
			},
			{
				image: '/images/sticker-collection-2/small/sticker-1.png',
				description: 'stickers with hand',
				items: Array.from({ length: 6 }).map((_, i) => ({
					image: `/images/sticker-collection-2/small/sticker-${i + 1}.png`,
					fullImage: `/images/sticker-collection-2/sticker-${i + 1}.png`,
					description: collection2Descriptions[i],
				})),
			},
			{
				image: '/images/sticker-collection-3/small/sticker-1.png',
				description: 'sticker collection',
				items: Array.from({ length: 6 }).map((_, i) => ({
					image: `/images/sticker-collection-3/small/sticker-${i + 1}.png`,
					fullImage: `/images/sticker-collection-3/sticker-${i + 1}.png`,
					description: collection3Descriptions[i],
				})),
			},
			{
				image: '/images/halloween/small/halloween-1.png',
				description: 'halloween stickers',
				items: Array.from({ length: 15 }).map((_, i) => ({
					image: `/images/halloween/small/halloween-${i + 1}.png`,
					fullImage: `/images/halloween/halloween-${i + 1}.png`,
					description: halloweenDescriptions[i],
				})),
				enable: (now.month() === 9 && now.date() >= 31)
					|| (now.month() === 10 && now.date() <= 7),
			},
			{
				image: '/images/thanksgiving/small/thanksgiving_1.png',
				description: 'Thanksgiving Stickers',
				items: Array.from({ length: 9 }).map((_, i) => ({
					image: `/images/thanksgiving/thanksgiving_${i + 1}.png`,
					fullImage: `/images/thanksgiving/thanksgiving_${i + 1}.png`,
					description: 'Sticker',
				})),
				enable: (now.month() === 10 && now.date() > 27) || (now.month() === 11 && now.date() === 1),
			},
		];
	}, [user]);

	return collections;
};
