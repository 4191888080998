import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';

export enum AppointmentChronoStatus {
	None = 0,
	Arrived = 1,
	CheckedIn = 2,
	CheckedInOnline = 3,
	InRoom = 4,
	InSession = 5,
	Complete = 6,
	Confirmed = 7,
	NotConfirmed = 8,
	Rescheduled = 9,
	Cancelled = 10,
	NoShow = 11
}

export const AppointmentChronoStatusNames = {
	[AppointmentChronoStatus.None]: 'None',
	[AppointmentChronoStatus.Arrived]: 'Arrived',
	[AppointmentChronoStatus.CheckedIn]: 'Checked In',
	[AppointmentChronoStatus.CheckedInOnline]: 'Checked In Online',
	[AppointmentChronoStatus.InRoom]: 'In Room',
	[AppointmentChronoStatus.InSession]: 'In Session',
	[AppointmentChronoStatus.Complete]: 'Complete',
	[AppointmentChronoStatus.Confirmed]: 'Confirmed',
	[AppointmentChronoStatus.NotConfirmed]: 'Not confirmed',
	[AppointmentChronoStatus.Rescheduled]: 'Rescheduled',
	[AppointmentChronoStatus.Cancelled]: 'Cancelled',
	[AppointmentChronoStatus.NoShow]: 'No Show',
};

export enum SystemAppointmentStatus {
	Unconfirmed = 0,
	Confirmed = 1,
	InProgress = 2,
	Completed = 3,
	Missed = 4,
	Rescheduled = 5,
	Canceled = 6,
	MessageLeft = 7,
	Closed = 8,
	inRoom = 9,
}

export const SAStatusNames = {
	[SystemAppointmentStatus.Unconfirmed]: 'Unconfirmed',
	[SystemAppointmentStatus.Confirmed]: 'Confirmed',
	[SystemAppointmentStatus.InProgress]: 'In Progress',
	[SystemAppointmentStatus.Completed]: 'Completed',
	[SystemAppointmentStatus.Missed]: 'Missed',
	[SystemAppointmentStatus.Rescheduled]: 'Rescheduled',
	[SystemAppointmentStatus.Canceled]: 'Canceled',
	[SystemAppointmentStatus.MessageLeft]: 'Message Left',
	[SystemAppointmentStatus.Closed]: 'Closed',
	[SystemAppointmentStatus.inRoom]: 'In Room',
};

export interface AppointmentStatus extends WithDeleted {
	nameEn: string;
	nameEs: string;

	chronoStatus: Nullable<AppointmentChronoStatus>;
	saStatus: Nullable<SystemAppointmentStatus>;

	color: string;

	showOnCalendar: boolean;

	sortOrder: number;
}
