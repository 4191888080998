import * as React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Select, { SelectProps } from 'antd/lib/select';

import { SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import ItemsSelect from '@common/react/components/UI/ItemsSelect/ItemsSelect';
import { BaseParams } from '@common/react/objects/BaseParams';
import ItemsProviderWithStore from '@common/react/components/Core/ItemsProviderWithStore/ItemsProviderWithStore';
import ItemsProviderSynchronizer from '@common/react/components/Core/AdvancedItemsProvider/ItemsProviderSynchronizer';

import { AppointmentType, defaultStatusesFilters } from '@app/objects/Appointment';
import '@app/scss/components/statusFilter.scss';
import { NullableAppointmentType } from '@app/components/Pages/Admin/BaseReport/BaseReport';
import ColorLine from '@app/components/UI/ColorLine/ColorLine';
import { ApplicationState } from '@app/store';

interface ComponentProps extends SelectProps {
	value: Array<number> | undefined | string | null;
	onChange: (value: any, option) => void;
	appointmentTypes?: Array<AppointmentType>;
	antdProps?: SelectProps;
	withAdditionalOptions?: boolean;
	maxTagCount?: number;
	maxTagTextLength?: number;
	allowClear?: boolean;
	className?: string;
	valueName?: string;
	mode?: SelectProps['mode'];
	filters?: BaseParams;
	clearAfterChange?: boolean;
	filterItem?: (item) => boolean;
}

const appointmentTypeFilterOptions = (input, option: any) => {
	return (option?.item?.name ?? option?.label ?? '').toLowerCase().includes(input.toLowerCase());
};

const Option = Select.Option;

export const AppointmentTypesSynchronizer: React.FC = () => {
	const serverPage = useSelector((state: ApplicationState) => state.serverPage, shallowEqual);
	const [items] = React.useState(() => {
		return serverPage?.page?.filterProps?.appointmentTypes
			|| serverPage?.page?.innerPage?.appointmentFilters?.appointmentTypes;
	});

	return <ItemsProviderWithStore<AppointmentType>
		items={items}
		defaultSort={['sortOrder', SortingDirection.Ascending]}
		filterHandler={() => true}
		sortHandler={(a, b) => a.sortOrder - b.sortOrder}
		storeName="appointmentTypes"
		type="appointmentType"
		pagination={{ current: 1, pageSize: 100 }}
		filters={defaultStatusesFilters}
	>
		<ItemsProviderSynchronizer
			objectType="AppointmentTypeRedux"
			storeName="appointmentTypes"
		/>
	</ItemsProviderWithStore>;
};

const AppointmentTypesFilter: React.FC<ComponentProps> = (props) => {
	const {
		value, onChange, maxTagCount, maxTagTextLength, antdProps, valueName = 'appointmentTypeIds', filters, clearAfterChange,
	} = props;
	const {
		withAdditionalOptions, appointmentTypes, allowClear = true, className = 'ml10 pull-right', mode = 'multiple', filterItem,
	} = props;
	const isMultiple = mode === 'multiple';

	const [innerValue, setValue] = React.useState(null);

	const handleChange = (value, option) => {
		onChange(isMultiple ? { [valueName]: value } : value, option);
		setValue(null);
	};

	return (
		<div className={`status-filter ${className}`}>
			<ItemsSelect<AppointmentType>
				getOptionProps={(item) => ({
					value: item.id,
					children: <>
						<ColorLine color={item.color} />
						{item.name}
					</>,
					item,
					label: item.name,
				})}
				filterItem={filterItem}
				selectProps={{
					...antdProps,
					allowClear,
					mode: isMultiple ? 'multiple' : undefined,
					placeholder: `Appointment Type${isMultiple ? 's' : ''}...`,
					value: clearAfterChange ? innerValue : value || (isMultiple ? [] : undefined),
					onChange: handleChange,
					maxTagCount,
					maxTagTextLength,
					className: 'w100',
					filterOption: appointmentTypeFilterOptions,
					showSearch: true,
				}}
				defaultSort={['sortOrder', SortingDirection.Ascending]}
				filterHandler={() => true}
				sortHandler={(a, b) => a.sortOrder - b.sortOrder}
				storeName="appointmentTypes"
				type="appointmentType"
				itemsProviderSynchronizerProps={{
					objectType: 'AppointmentTypeRedux',
				}}
				items={appointmentTypes}
				pagination={{ current: 1, pageSize: 100 }}
				filters={{ ...defaultStatusesFilters, ...filters }}
				additionalOptions={withAdditionalOptions
					? (
						<Option key={NullableAppointmentType} value={NullableAppointmentType}>
							Without type
						</Option>
					) : null}
			/>
		</div>
	);
};

export default AppointmentTypesFilter;
