import { WithDeleted } from '@common/typescript/objects/WithDeleted';

import { Company } from '@commonTuna/react/objects/Company';

export enum Feature {
	Site,
	Portal,
	QuickBooks,
	ZocDoc,
	CoverMyMeds,
	Stripe,
	Labs,
	Aurora,
	Sagis,
	Quest,
	Labcorp,
	Podium,
	VirtualAppointment,
	Nopali,
	DrChrono,
	Honors,
	Cases,
	Faqs,
	Pages,
	Services,
	Reviews,
	Contacts,
	Tasks,
	Inquiries,
	AnswersHistory,
	Trizetto,
	TwoFactorAuthentication,
	Claims,
	SupportChats,
	PaymentsProcessing,
	ERx,
	Tuna,
	EFax,
	PhoneCall,
	InquiryProcess,
	DPath,
	CustomMailBox,
	VideoChat,
	VideoMessage,
	DrDx,
	SonicHealthcare,
	IpFilter,
	PageImages,
	EmailTemplates,
	QrCodePayments,
	MultipleLanguages,
	SMS,
	Email,
	GiftCard = 48,
	GoogleReviewScraper = 49,
	Instagram = 50,
	Aesthetic = 51,
	Availity = 52,
	MedicalTranscribe = 53,
	QuestLab = 54,
	Subscriptions = 55,
}

export const FeatureNames = {
	[Feature.Site]: 'Site',
	[Feature.Portal]: 'Portal',
	[Feature.QuickBooks]: 'QuickBooks',
	[Feature.ZocDoc]: 'ZocDoc',
	[Feature.CoverMyMeds]: 'CoverMyMeds',
	[Feature.Stripe]: 'Stripe',
	[Feature.Labs]: 'Laboratories',
	[Feature.Aurora]: 'Aurora',
	[Feature.Sagis]: 'Sagis',
	[Feature.Quest]: 'Quest',
	[Feature.Labcorp]: 'Labcorp',
	[Feature.Podium]: 'Podium',
	[Feature.VirtualAppointment]: 'Virtual Appointment',
	[Feature.Nopali]: 'Nopali',
	[Feature.DrChrono]: 'Dr Chrono',
	[Feature.Honors]: 'Awards',
	[Feature.Cases]: 'Before-Afters',
	[Feature.Faqs]: 'Faqs',
	[Feature.Pages]: 'General Pages',
	[Feature.Services]: 'Services Pages',
	[Feature.Reviews]: 'Reviews',
	[Feature.Contacts]: 'Contacts',
	[Feature.Tasks]: 'Tasks',
	[Feature.Inquiries]: 'Inquiries',
	[Feature.AnswersHistory]: 'Answers History',
	[Feature.Trizetto]: 'Trizetto',
	[Feature.TwoFactorAuthentication]: 'Two-Factor Authentication',
	[Feature.Claims]: 'Claims',
	[Feature.SupportChats]: 'Support Chats',
	[Feature.PaymentsProcessing]: 'Payments Processing',
	[Feature.ERx]: 'eRx',
	[Feature.Tuna]: 'Northern Tuna',
	[Feature.EFax]: 'eFax',
	[Feature.PhoneCall]: 'Phone Call',
	[Feature.InquiryProcess]: 'Inquiry Processing',
	[Feature.DPath]: 'DPath',
	[Feature.CustomMailBox]: 'Custom Mailbox',
	[Feature.VideoChat]: 'Video Chat',
	[Feature.VideoMessage]: 'Video Message',
	[Feature.DrDx]: 'drDx',
	[Feature.SonicHealthcare]: 'Sonic Healthcare',
	[Feature.IpFilter]: 'IP Filter',
	[Feature.PageImages]: 'Images',
	[Feature.EmailTemplates]: 'Email Templates',
	[Feature.QrCodePayments]: 'Qr Code Payments',
	[Feature.MultipleLanguages]: 'Multiple Languages',
	[Feature.SMS]: 'SMS',
	[Feature.Email]: 'Email',
	[Feature.GiftCard]: 'Gift Cards',
	[Feature.GoogleReviewScraper]: 'Google Review Scraper',
	[Feature.Instagram]: 'Instagram',
	[Feature.Aesthetic]: 'Aesthetic',
	[Feature.Availity]: 'Availity',
	[Feature.MedicalTranscribe]: 'Medical Transcribe',
	[Feature.QuestLab]: 'Quest Laboratory',
	[Feature.Subscriptions]: 'Subscriptions',
};

export interface CompanyFeature extends WithDeleted {
	company: Company;
	companyId: number;

	time: number;
	feature: Feature;
	amount: number;

	parent: CompanyFeature | null;
	parentId: number;
}
