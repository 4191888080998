import React from 'react';

import moment from 'moment';

import Tag from 'antd/lib/tag';

interface Props {
	time: number | string | null;
	duration?: number;
}

const checkIsNotZero = (value, dimension) => {
	return value !== 0 ? `${value}${dimension}` : '';
};

const TimeFrom: React.FC<Props> = (props) => {
	const date = moment.utc(props.time).format('YYYY-MM-DD HH:mm:ss');
	const stillUtc = moment(date).toDate();
	const local = moment.utc(stillUtc);
	const diff = moment.duration(moment().diff(local));
	const weeks = Math.floor(Math.abs(diff.days()) / 7);
	let value: string = '';

	if (props.time) {
		if (diff.years() !== 0) {
			value = `${diff.years()}y ${checkIsNotZero(diff.months(), 'm')}`;
		} else if (diff.months() !== 0) {
			value = `${diff.months()}m ${checkIsNotZero(diff.days(), 'd')}`;
		} else if (weeks !== 0) {
			value = `${weeks}w ${checkIsNotZero(diff.days() % 7, 'd')}`;
		} else if (diff.days()) {
			value = `${diff.days()}d ${checkIsNotZero(diff.hours(), 'h')}`;
		} else {
			value = `${checkIsNotZero(diff.hours(), 'h')} ${diff.minutes()}${diff.hours() !== 0 ? 'm' : 'min'}`;
		}
	}

	const diff1 = moment().diff(local);
	const diff2 = props.duration ? moment().diff(local.add('minutes', props.duration)) : 0;
	const title = diff1 < 0 ? 'future'
		: diff2 > 0 ? 'past' : 'ongoing';
	const color = diff1 < 0 ? 'green'
		: diff2 > 0 ? undefined : 'orange';

	return (
		<div className="time-form">
			<Tag
				color={color}
				title={title}
			>
				{value.replace(/-/g, '')}
			</Tag>
		</div>
	);
};

export default TimeFrom;
