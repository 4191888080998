import * as React from 'react';

import { BaseUser } from '@common/react/objects/BaseUser';
import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

export interface BanProps {
	user: BaseUser;
	disabled?: boolean;
	requestPath?: string;
}

const Ban: React.FC<BanProps> = (props) => {
	const { user, disabled, requestPath = 'ban' } = props;
	const [lockoutEnabled, setLockoutEnabled] = React.useState<boolean>(user.lockoutEnabled);
	const { getUser } = useApplicationContext();

	return <RequestButton
		requestType={requestPath}
		requestProps={{
			id: user.id,
			ban: !lockoutEnabled,
		}}
		onSuccess={() => {
			setLockoutEnabled(!lockoutEnabled);
		}}
		title="Ban user"
		className={`btn btn-sm ${lockoutEnabled ? 'btn-danger' : 'btn-default'}`}
		disabled={user.id === getUser<BaseUser>()?.id || disabled}
	>
		<i className="fa fa-ban" aria-hidden="true" />
	</RequestButton>;
};

export default Ban;
