import React from 'react';

import Message from 'antd/lib/message';

import useRequest from '@common/react/hooks/useRequest';
import { ItemsProvider } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import BaseFilterComponent from '@common/react/components/Core/BaseFilterComponent/BaseFilterComponent';

import { UserFilters } from '@app/objects/UserFilters';
import AddUserFiltersButton from '@app/components/UI/UserFiltersComponent/AddUserFiltersButton';

import '@app/scss/components/userFiltersComponent.scss';
import RemoveUserFiltersButton from '@app/components/UI/UserFiltersComponent/RemoveUserFiltersButton';

interface ComponentProps {
	actorType: string;
	filters: any;
	onSelect: (message: any) => void;
	setSelectedUserFilters?: (userFilters: UserFilters | undefined) => void;
	selectedUserFilters?: UserFilters;
	userFilters?: Array<UserFilters>;
	messageGroup?: string;
}

interface InnerComponentProps {
	actorType: string;
	onSelect: (userFilters, jsonMessage: string) => void;
	onDelete: (id: number) => void;
	selected?: number;
	messageGroup?: string;
}

const UserFiltersList: React.FC<InnerComponentProps> = ({
	selected,
	onSelect,
	onDelete,
	actorType,
	messageGroup,
}) => {
	return <BaseFilterComponent<UserFilters> render={({ state: { items }, actions: { updateItem } }) => <>
		{items?.map((item, index) => <span
			className="is-relative user-filter-action__wrapper"
			key={index} // eslint-disable-line
		>
			<button
				type="button"
				title={item.name}
				className={`user-filter btn btn-sm btn-${selected === item.id ? 'primary' : 'default'}`}
				onClick={() => onSelect(item, item.jsonMessage)}
			>
				{item.name}
			</button>
			{selected === item.id && <>
				<RemoveUserFiltersButton onDelete={() => onDelete(item.id)} />
				<AddUserFiltersButton
					className="user-filter-action user-filter-action__edit"
					actorType={actorType}
					messageGroup={messageGroup}
					afterSave={(res) => updateItem({ name: res.name, id: res.id })}
					userFilters={item}
				/>
			</>
			}
		</span>)}
	</>}
	/>;
};

const UserFiltersComponent: React.FC<ComponentProps> = ({
	actorType = 'appointmentFilters',
	filters,
	userFilters,
	onSelect,
	messageGroup,
	selectedUserFilters,
	setSelectedUserFilters,
}) => {
	const request = useRequest();
	const [defaultFilters, setDefaultFilters] = React.useState<any>();

	React.useEffect(() => {
		setDefaultFilters(filters);
	}, []);

	const deleteCurrentFilters = (id: number) => request('userFilters', {
		id,
		deleted: true,
	});

	return <>
		<ItemsProvider
			type="userFilters"
			loadRequest="userFiltersList"
			filters={{
				actorType,
				messageGroup,
			}}
			pagination={{
				current: 1,
				pageSize: 50,
			}}
			items={userFilters}
			skipInitLoad={false}
		>
			{(context) => (
				<>
					<UserFiltersList
						messageGroup={messageGroup}
						actorType={actorType}
						selected={selectedUserFilters?.id}
						onSelect={(userFilters, jsonMessage: string) => {
							if (userFilters.id !== selectedUserFilters?.id) {
								onSelect(JSON.parse(jsonMessage));
								setSelectedUserFilters && setSelectedUserFilters(userFilters);
							} else {
								onSelect(defaultFilters);
								setSelectedUserFilters && setSelectedUserFilters(undefined);
							}
						}}
						onDelete={(id: number) => {
							deleteCurrentFilters(id)
								.then((res) => {
									context.actions.reload();
									if (id === selectedUserFilters?.id) {
										setSelectedUserFilters && setSelectedUserFilters(undefined);
									}
								}).catch((message) => Message.error(message));
						}}
					/>
					<AddUserFiltersButton
						filters={filters}
						afterSave={() => {
							context.actions.reload();
						}}
						messageGroup={messageGroup}
						actorType={actorType}
					/>
				</>
			)}
		</ItemsProvider>
	</>;
};

export default UserFiltersComponent;
