import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';
import { SimpleInputNumber } from '@common/react/components/Forms/SimpleInputNumber/SimpleInputNumber';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import { BuildData } from '@app/objects/BuildData';

type StringNumber = string | number;

export interface HandleChangeEvent {
	currentTarget: {
		name: string;
		value?: Nullable<StringNumber>;
	};
}

interface RangeInputsInterface {
	placeholder: string;
	onChange: (evt: HandleChangeEvent) => void;
	valueName: string;
	filters: BaseParams;
	className?: string;
	hasMin?: boolean;
	emptyAsUndefined?: boolean;
	delay?: number;
	title?: string;
	firstValueName?: string;
	secondValueName?: string;
	max?: number;
}

export const RangeInputs: React.FC<RangeInputsInterface> = (props) => {
	const { getBuildData } = useApplicationContext();
	const maxNumberLimit = getBuildData<BuildData>().maxNumberLimit;
	const {
		valueName, firstValueName, secondValueName, onChange, filters, placeholder, className, title, hasMin, max = maxNumberLimit, ...rest
	} = props;
	const transformValue = (value) => {
		const result = value !== undefined && value > max ? max : value;

		if (hasMin) {
			return result < 0 ? 0 : result;
		}

		return result;
	};

	return (
		<>
			<SimpleInputNumber
				savePlaceholder
				onChange={onChange}
				transformValue={transformValue}
				field={firstValueName || `${valueName}Min`}
				placeholder={`${placeholder} Min`}
				className={`form-control br-right-none ${className}`}
				value={`${filters[firstValueName || `${valueName}Min`] ?? ''}`}
				title={title ? `${title} Min` : undefined}
				onKeyPress={(event) => {
					if (event.key === '-' || event.key === '+' || event.key === 'e') {
						event.preventDefault();
					}
				}}
				{...rest}
			/>
			<SimpleInputNumber
				savePlaceholder
				onChange={onChange}
				transformValue={transformValue}
				field={secondValueName || `${valueName}Max`}
				placeholder={`${placeholder} Max`}
				className={`form-control br-left-none ${className}`}
				value={`${filters[secondValueName || `${valueName}Max`] ?? ''}`}
				title={title ? `${title} Max` : undefined}
				onKeyPress={(event) => {
					if (event.key === '-' || event.key === '+' || event.key === 'e') {
						event.preventDefault();
					}
				}}
				{...rest}
			/>
		</>
	);
};
