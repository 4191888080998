import React from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import SimpleSearchInput, {
	ComponentProps,
} from '@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput';
import { HandleChangeEventElement } from '@common//react/components/Core/ItemsProvider/ItemsProvider';

import {
	getAllCountriesPhoneMask,
	phoneReplace,
} from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';

const removePhoneFormat = (value) => phoneReplace(value).replace(/[_+]/gi, '');

const PhoneSimpleSearch: React.FC<ComponentProps<MaskedInputProps>> = (props) => {
	const { name = 'text' } = props;

	const onChange = (e: HandleChangeEventElement) => {
		props.onChange && props.onChange({ currentTarget: { value: removePhoneFormat(e.currentTarget.value), name } });
	};

	const onSubmit = (e: HandleChangeEventElement) => {
		props.onSubmit && props.onSubmit({ currentTarget: { value: removePhoneFormat(e.currentTarget.value), name } });
	};

	return (
		<SimpleSearchInput<MaskedInputProps>
			{...props}
			name={name}
			onChange={onChange}
			onSubmit={onSubmit}
			inputComponent={MaskedInput}
			otherInputProps={{
				guide: true,
				mask: getAllCountriesPhoneMask,
			}}
		/>
	);
};

export default PhoneSimpleSearch;
