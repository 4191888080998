import * as React from 'react';

import 'trumbowyg/dist/ui/trumbowyg.min.css';

import '@common/react/scss/components/wysiwyg.scss';
import { FileType } from '@common/typescript/objects/FileInterface';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

export interface WysiwygProps {
	options?: any;
	fileObjectId?: number;
	fileObjectType?: string;
	data?: any;
	withFileUpload?: boolean;
	value?: string;
	onChange?: (event: any) => void;
	customPlugins?: Array<string>;
	russianLang?: boolean;
	loadCustomPlugins?: () => Promise<any>;
	params?: any; // params to reset value
	getResetCondition?: (prevParams, params) => boolean;
	imagePasteBase?: string;
	customHtmlViewer?: (value: string, onChange: (value: string) => void, readonly: boolean, key: number) => React.ReactNode;
}

interface WysiwygState {
	Trumbowyg: any;
	buttons: any;
}

export const defaultOptions = {
	buttons: [
		['viewHTML'],
		['undo', 'redo'],
		['formatting'],
		['fontfamily', 'fontsize', 'lineheight'],
		['strong', 'em', 'strikethrough', 'underline'],
		['superscript', 'subscript'],
		['link'],
		['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
		['unorderedList', 'orderedList'],
		['outdent'],
		['indent'],
		['horizontalRule'],
		['removeformat'],
		['fullscreen'],
		['table'],
		['quote'],
	],
	plugins: {
		upload: {
			fileFieldName: 'name',
			serverPath: 'fineUploader',
			urlPropertyName: 'response.src',
		},
		table: {
			styler: 'editor-table',
		},
		pasteimage: {
			objectType: '',
			objectId: '',
			fileType: FileType.File,
			basePath: '/',
		},
	},
	btnsDef: {
		strikethrough: {
			fn: 'strikethrough',
			tag: 'strike',
			title: 'Deleted',
		},
	},
};

class Wysiwyg extends React.Component<WysiwygProps, WysiwygState> {
	public static defaultProps: Partial<WysiwygProps> = {
		options: defaultOptions,
		value: '',
	};

	static id = 0;

	elementId: string = '';

	constructor(props: WysiwygProps) {
		super(props);

		this.onChange = this.onChange.bind(this);
		const buttons = props.options.buttons;

		this.state = {
			Trumbowyg: null,
			buttons: props.withFileUpload && props.fileObjectType && props.fileObjectId ? buttons.concat([['upload']]) : buttons,
		};

		this.elementId = `trumbowyg${Wysiwyg.id++}`;

		if (this.props.russianLang) Wysiwyg.defaultProps.options.lang = 'ru';
	}

	componentDidMount() {
		const props = this.props;

		import('jquery' /* webpackChunkName: "jquery" */).then((jquery) => {
			if ((jquery as any).default) {
				(window as any).jQuery = (jquery as any).default;
				(window as any).$ = (jquery as any).default;
			} else {
				(window as any).jQuery = jquery;
				(window as any).$ = jquery;
			}

			import('@common/react/components/Forms/Wysiwyg/Trumbowyg' /* webpackChunkName: "trumbowyg" */).then((Trumbowyg) => {
				const component = Trumbowyg.default;

				const promises: any = [];

				if (props.russianLang) {
					promises.push(
						import('trumbowyg/dist/langs/ru.min' /* webpackChunkName: "trumbowyg-ru" */),
					);
				}

				if (props.customPlugins && props.customPlugins.length > 0) {
					props.customPlugins.forEach((plugin) => {
						promises.push(
							import(`./customPlugins/trumbowyg.${plugin}` /* webpackChunkName: "trumbowyg-plugins-[request]" */),
						);
					});
				}

				promises.push(
					import('@common/react/components/Forms/Wysiwyg/TrumbowygPlugins' /* webpackChunkName: "trumbowyg-plugins" */),
				);

				if (props.loadCustomPlugins) {
					promises.push(props.loadCustomPlugins());
				}

				Promise.all(promises).then(() => {
					this.setState({
						Trumbowyg: component,
					});
				});
			});
		});
	}

	componentDidUpdate(prevProps: Readonly<WysiwygProps>, prevState: Readonly<WysiwygState>, snapshot?: any) {
		const f = this.props.getResetCondition || ((p, n) => p !== n);
		if (this.props.params !== undefined && prevProps.params !== undefined && f(prevProps.params, this.props.params)) {
			const jQElement = ($(`#${this.elementId}`) as any);

			if (jQElement.html() !== this.props.value) {
				jQElement.trumbowyg('html', this.props.value);
			}
		}
	}

	onChange(html) {
		this.props.onChange
		&& this.props.onChange(
			html.replace(/<a href=/igm, '<a target="_blank" rel="noopener noreferrer" href=')
				.replace('<blockquote style="', '<blockquote style="font-size: 14px; ')
				.replace('trumbowyg-image-selected', ''),
		);
	}

	public render() {
		const Trumbowyg = this.state.Trumbowyg;

		let props: WysiwygProps = this.props;

		if (props.withFileUpload && props.fileObjectType && props.fileObjectId) {
			props = { ...props };

			props.options.plugins.upload.data = [
				{
					name: 'objectType',
					value: props.fileObjectType,
				},
				{
					name: 'objectId',
					value: props.fileObjectId,
				},
			];

			if (props.data) {
				Object.keys(props.data).forEach((item) => {
					props.options.plugins.upload.data.push({
						name: `data[${item}]`,
						value: props.data[item],
					});
				});
			}
		}

		if (props.options.plugins && props.fileObjectType && props.fileObjectId) {
			props.options.plugins.pasteimage.objectId = props.fileObjectId;
			props.options.plugins.pasteimage.objectType = props.fileObjectType;
			props.options.plugins.pasteimage.basePath = props.imagePasteBase;
		}

		return (
			<div className="wysiwyg-component" spellCheck="false">
				{Trumbowyg ? <Trumbowyg
					{...props.options}
					customHtmlViewer={props.customHtmlViewer}
					data={props.value}
					buttons={this.state.buttons}
					onBlur={this.onChange}
					onChange={this.onChange}
					id={this.elementId}
					semantic={{
						b: 'strong',
						i: 'em',
						s: 'strike',
						del: 'strike',
						strike: 'strike',
						div: 'p',
					}}
				/> : ''}
			</div>
		);
	}
}

const WysiwygWithBase: React.FC<WysiwygProps> = (props) => {
	const { getHostOptions } = useApplicationContext();
	const hostOptions = getHostOptions();

	return <Wysiwyg
		imagePasteBase={props.imagePasteBase || hostOptions.hostServer}
		{...props}
	/>;
};

export default WysiwygWithBase;
