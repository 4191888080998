import { Nullable } from '@common/typescript/objects/Nullable';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';

import { Gender } from '@commonTuna/react/objects/Enums';
import { UserGroup } from '@commonTuna/react/objects/UserGroup';
import { NTBaseUser } from '@commonTuna/react/objects/NTBaseUser';
import { ColorSchema } from '@commonTuna/react/objects/ColorSchema';

import { Doctor } from '@app/objects/Doctor';
import { Location } from '@app/objects/Location';
import { Company } from '@app/objects/Company';
import { WorkShift } from '@app/objects/WorkShift';
import { Patient } from '@app/objects/Patient';
import { AppointmentStatus, AppointmentType } from '@app/objects/Appointment';

export enum UserRole {
	Admin = 1,
	User = 2,
	ContentManager = 3,
	Staff = 4,
	Intern = 5,
	Doctor = 6,
	Provider = 7,
}

export const UserRoleNames = {
	[UserRole.Admin]: 'Admin',
	[UserRole.User]: 'User',
	[UserRole.ContentManager]: 'Content Manager',
	[UserRole.Staff]: 'Staff',
	[UserRole.Intern]: 'Intern',
	[UserRole.Doctor]: 'Office Admin',
	[UserRole.Provider]: 'Provider',
};

export enum UserSource {
	SocialNetwork,
	WebSearch,
	FriendsOrFamily,
	Other
}

export const UserSourceNames = {
	[UserSource.SocialNetwork]: 'Social Network',
	[UserSource.WebSearch]: 'Web Search',
	[UserSource.FriendsOrFamily]: 'Friends Or Family',
	[UserSource.Other]: 'Other',
};

export const BannedFilterGroup = [
	{ caption: 'Banned', value: true },
	{ caption: 'Not banned', value: false },
	{ caption: 'All', value: null },
];

export enum DashboardInfo {
	Inquiry = 0,
	Review = 1,
	Patient = 2,
	Finances = 3,
	FaqCasesInquiresReviewBlock = 4,
	PatientsAppointmentsBlock = 5,
	EmailsNewEmailsBlock = 6,
	Payments = 7,
	Appointments = 8,
	DoctorAppointments = 9,
	StaticMetricWidget = 10,
	DynamicMetricWidget = 11,
	UserStatus = 12,
	InquiryProcessingGroup = 13,
	EmailMarketingWidget = 14,
	EmailMarketingPerformanceWidget = 15,
	PatientsBlock = 16,
	MetricsStatisticWidget = 17,
	Orders = 18,
	Cases = 19,
	ProfitWidget = 20,
	Analysis = 21,
	Claims = 22,
	SpecialsSales = 23,
}

export const DashboardInfoNames = {
	[DashboardInfo.Inquiry]: 'Inquiries',
	[DashboardInfo.Review]: 'Reviews',
	[DashboardInfo.Patient]: 'Patients',
	[DashboardInfo.Finances]: 'Finances',
	[DashboardInfo.FaqCasesInquiresReviewBlock]: 'Faqs, Reviews, Inquiries and Before-Afters',
	[DashboardInfo.PatientsAppointmentsBlock]: 'Patients and Appointments',
	[DashboardInfo.EmailsNewEmailsBlock]: 'Inbound Emails',
	[DashboardInfo.Payments]: 'Payments',
	[DashboardInfo.Appointments]: 'Appointments',
	[DashboardInfo.DoctorAppointments]: 'Provider Metrics',
	[DashboardInfo.StaticMetricWidget]: 'Static Metric',
	[DashboardInfo.DynamicMetricWidget]: 'Dynamic Metric',
	[DashboardInfo.UserStatus]: 'Users',
	[DashboardInfo.InquiryProcessingGroup]: 'Inquiries Processing',
	[DashboardInfo.EmailMarketingWidget]: 'Email Marketing',
	[DashboardInfo.EmailMarketingPerformanceWidget]: 'Email Marketing Performance',
	[DashboardInfo.PatientsBlock]: 'New and Returning Patients',
	[DashboardInfo.MetricsStatisticWidget]: 'Metrics Statistics',
	[DashboardInfo.Orders]: 'Orders',
	[DashboardInfo.Cases]: 'Before-Afters',
	[DashboardInfo.ProfitWidget]: 'Revenue',
	[DashboardInfo.Analysis]: 'Lab. Orders',
	[DashboardInfo.Claims]: 'Claims',
	[DashboardInfo.SpecialsSales]: 'Specials and Sale',
};

export const DashboardInfoIcons = {
	[DashboardInfo.Inquiry]: 'paper-plane',
	[DashboardInfo.Review]: 'pencil',
	[DashboardInfo.Patient]: 'user',
	[DashboardInfo.Finances]: 'line-chart',
	[DashboardInfo.FaqCasesInquiresReviewBlock]: 'book',
	[DashboardInfo.PatientsAppointmentsBlock]: 'users',
	[DashboardInfo.EmailsNewEmailsBlock]: 'envelope',
	[DashboardInfo.Payments]: 'credit-card',
	[DashboardInfo.Appointments]: 'calendar-check-o',
	[DashboardInfo.DoctorAppointments]: 'bar-chart',
	[DashboardInfo.StaticMetricWidget]: 'area-chart',
	[DashboardInfo.DynamicMetricWidget]: 'usd',
	[DashboardInfo.UserStatus]: 'user-circle-o',
	[DashboardInfo.InquiryProcessingGroup]: 'space-shuttle',
	[DashboardInfo.EmailMarketingWidget]: 'pie-chart',
	[DashboardInfo.EmailMarketingPerformanceWidget]: 'recycle',
	[DashboardInfo.PatientsBlock]: 'user-plus',
	[DashboardInfo.MetricsStatisticWidget]: 'bars',
	[DashboardInfo.Orders]: 'credit-card-alt',
	[DashboardInfo.Cases]: 'star',
	[DashboardInfo.ProfitWidget]: 'money',
	[DashboardInfo.Analysis]: 'flask',
	[DashboardInfo.Claims]: 'medkit',
	[DashboardInfo.SpecialsSales]: 'shopping-bag',
};

export interface UserDashboard extends WithDeleted {
	userId: number;
	user: User;
	dashboardInfo: DashboardInfo;
	x: number;
	y: number;
	isAvailable: boolean;
}

export interface UserLocation extends WithDeleted {
	userId: number;
	user: User;
	locationId: number;
	location: Location;
}

export interface User extends NTBaseUser {
	twoFactorEnabled: boolean;
	phoneNumber: string;

	timeZoneId: string;
	baseUtcOffset: string;

	role: UserRole;

	enableSounds: boolean;
	browserNotifications: boolean;

	doctorId: Nullable<number>;
	doctor: Doctor;
	locations: Array<UserLocation>;
	userGroups: Array<UserGroup>;
	root: boolean;

	transmutation: boolean;

	companyId: number;
	company: Company;

	workShifts: Array<WorkShift>;

	dashboards: Array<UserDashboard>;

	// for patient registration
	patients: Array<Patient>;

	userSource: UserSource;
	userSourceText: string;

	employerOccupancy: string;
	allowEmail: boolean;
	allowSms: boolean;
	allowCalls: boolean;
	allowPush: boolean;
	agreementForSignUp: boolean;

	colorSchema: ColorSchema;

	productCommission: Nullable<number>;

	color?: string;

	birthday: number | null;
	gender: Gender | null;

	unviewedSupportMessagesCount: number;

	userFavorites: Array<UserFavorite>;

	emailConfirmed?: boolean;
	phoneNumberConfirmed?: boolean;
}

export enum UserStatus {
	Offline,
	Online,
	Away
}

export const UserStatusNames = {
	[UserStatus.Online]: 'Online',
	[UserStatus.Offline]: 'Offline',
	[UserStatus.Away]: 'Away',
};

export interface UserPatient extends User {
	password: string;
}

export enum CustomNotificationType {
	All = 0,
	Status = 1
}

export const CustomNotificationTypeNames = {
	[CustomNotificationType.All]: 'All',
	[CustomNotificationType.Status]: 'Status',
};

export interface UserCustomNotification extends WithDeleted {
	user: User;
	userId: number;

	doctor: Doctor | null;
	doctorId: number | null;

	patient: Patient | null;
	patientId: number | null;

	location: Location | null;
	locationId: number | null;

	created: number | null;

	customNotificationType: CustomNotificationType;

	fromStatus: AppointmentStatus | null;
	fromStatusId: number | null;

	toStatus: AppointmentStatus | null;
	toStatusId: number | null;

	appointmentType: AppointmentType | null;
	appointmentTypeId: number | null;
}

export interface UserFavorite {
	id: number;
	title: string;
	url: string;
}
