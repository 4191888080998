import React from 'react';

import { LogoLoaderPage } from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';

const TunaLoader: React.FC = () => {
	return <LogoLoaderPage />;
};

export const params = {
	fallback: <TunaLoader />,
};

export default TunaLoader;
