import * as React from 'react';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { FetchMethod } from '@common/react/components/UI/LoadingButton/LoadingButton';

import { DownloadFileButton } from '@commonTuna/react/components/UI/DownloadFileButton/DownloadFileButton';

import { Appointment, SystemAppointmentStatus } from '@app/objects/Appointment';

interface Props {
	appointment: Appointment;
	onError?: (err: string) => void;
	className?: string;
	style?: React.CSSProperties;
}

export const WizardButton: React.FC<Props> = ({
	appointment,
	onError,
	className = 'btn btn-sm btn-primary mr5',
	style = { width: 34 },
}) => {
	return appointment.status?.saStatus !== SystemAppointmentStatus.Closed
		? (
			<LinkWithPrevLocation
				title="Start Appointment"
				className={className}
				to={`/appointment-wizard/${appointment.id}`}
				style={style}
			>
				<i className="fa fa-rocket" />
			</LinkWithPrevLocation>
		)
		: (
			<DownloadFileButton
				title="Download Medical Note Caption"
				className={className}
				method={FetchMethod.GET}
				path={`/wizardPdf?appointmentId=${appointment.id}`}
				onError={onError}
			>
				<i className="fa fa-download" />
			</DownloadFileButton>
		);
};
