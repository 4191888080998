import * as React from 'react';

import { BaseUser } from '@common/react/objects/BaseUser';
import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';

interface GenerateUserPasswordProps {
	user: BaseUser;
	title?: string;
	disabled?: boolean;
	onSuccess?: () => void;
	onError?: (e) => void;
}

const GenerateUserPassword: React.FC<GenerateUserPasswordProps> = ({
	user,
	title = 'Generate password',
	disabled,
	onError,
	onSuccess,
}) => {
	return <RequestButton
		requestType="generateUserPassword"
		requestProps={{ id: user.id }}
		className="btn btn-sm btn-default"
		title={title}
		disabled={disabled}
		successMessage="A letter with new password successfully send to user"
		onError={onError}
		onSuccess={onSuccess}
		confirmationProps={{
			title: title || 'User password generation',
			content: 'System will generate new password for this user. Are you sure?',
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'Cancel',
		}}
	>
		<i className="fa fa-retweet" aria-hidden="true" />
	</RequestButton>;
};

export default GenerateUserPassword;
