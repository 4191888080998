import React from 'react';

import VideoMessagePlayer from '@common/react/components/UI/VideoMessage/VideoMessagePlayer';

interface VideoMessageChatPlayerProps {
	videoMessageUrl?: string;
}

const VideoMessageChatPlayer: React.FC<VideoMessageChatPlayerProps> = ({ videoMessageUrl }) => {
	return videoMessageUrl ? <VideoMessagePlayer videoMessageUrl={videoMessageUrl} /> : <div>VIDEO MESSAGE URL IS UNDEFINED</div>;
};

export default VideoMessageChatPlayer;
