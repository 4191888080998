import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Message from 'antd/lib/message';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

import Transmutation from '@common/react/components/Forms/Transmutation';
import Ban from '@common/react/components/Forms/Ban/Ban';
import { getSearchParamsFromUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';
import {
	ColumnActions,
	EditableTable,
	EditableTableColumn,
} from '@common/react/components/Core/EditableTable/EditableTable';
import { ItemsProvider } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';
import { allPhoneFormat } from '@common/react/components/Forms/PhoneOrEmailInput/PhoneOrEmailInput';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import ListWithTooltip from '@commonTuna/react/components/UI/ListWithTooltip/ListWithTooltip';

import {
	User, UserLocation, UserRole, UserRoleNames, UserStatus,
} from '@app/objects/User';
import TableTitle from '@app/components/UI/TableTitleConnected/TableTitleConnected';
import UserFilters from '@app/components/Pages/Admin/Users/UserFilters';
import UserAvatarWithStatus from '@app/components/UI/UserAvatarWithStatus';
import SendMessageButton from '@app/components/UI/SendMessageButton/SendMessageButton';
import GenerateUserPassword from '@app/components/UI/GenerateUserPassword/GenerateUserPassword';
import { clearListFields } from '@app/components/Utils';

export const sorterByStatus = (users: Array<User>) => {
	return users.sort((a, b) => {
		if (a.status > b.status) {
			return 1;
		}
		if (a.status < b.status) {
			return -1;
		}

		return 0;
	});
};

export const getUserStatusDot = (status: number) => {
	let color = '#c0c0c0';
	let title = 'Offline';
	switch (status) {
		case UserStatus.Offline:
			color = '#c0c0c0';
			title = 'Offline';
			break;
		case UserStatus.Online:
			color = '#09e32d';
			title = 'Online';
			break;
		case UserStatus.Away:
			color = '#e0eb13';
			title = 'Away';
			break;
		default:
			break;
	}

	return (
		<div className="user-status-circle">
			<i style={{ color }} title={title} className="fa fa-circle" />
		</div>
	);
};

const antdProps = {
	className: 'table-mobile ant-table-auto-scroll',
};

const additionalParams = {
	withDoctors: true,
};

const Users: React.FC = () => {
	const location = useLocation();
	const params = getSearchParamsFromUrl(location) as any;
	const { page } = useParams<{ page: string }>();

	const { getUser } = useApplicationContext();
	const user = getUser<User>();

	const columns: Array<EditableTableColumn<User>> = React.useMemo(() => [
		{
			title: '',
			settingsTitle: 'Avatar',
			dataIndex: 'avatar',
			view: (text: string, record: User) => <UserAvatarWithStatus user={record} />,
		},
		/* {
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			sorter: true,
			view: (text, record: User): JSX.Element => {
				return getUserStatusDot(record.status);
			}
		}, */
		{
			title: 'Name',
			dataIndex: 'name',
			sorter: true,
			view: (text: string, record: User) => (
				<>
					{record.firstName || ''}
					{' '}
					{record.lastName || ''}
				</>
			),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: true,
			view: (text: string) => text,
		},
		{
			title: 'Phone',
			dataIndex: 'phoneNumber',
			hide: true,
			view: (text: string, record: User) => {
				return <>{allPhoneFormat(record.phoneNumber)}</>;
			},
		},
		{
			title: 'Role',
			dataIndex: 'role',
			view: (text, record: User) => UserRoleNames[record.role],
		},
		{
			title: 'Locations',
			dataIndex: 'locations',
			key: 'locations',
			view: (text: string, record: User): JSX.Element | null => (
				<ListWithTooltip
					count={1}
					array={record.locations || []}
					getValue={(item) => item.location.nameEn}
				/>
			),
		},
		{
			title: 'Company',
			dataIndex: 'company',
			enable: user?.transmutation || !!user?.root,
			view: (name: string, record: User) => record?.company?.name,
		},
		{
			title: 'Provider',
			dataIndex: 'doctor',
			key: 'doctor',
			view: (text, record: User) => (record.doctor ? (
				<LinkWithPrevLocation
					to={{ pathname: `/doctor-editor/${record.doctor.id}`, search: 'mode=view' }}
				>
					{record.doctor.nameEn}
					{record.doctor.provider
				&& <i title="Provider" className="fa fa-user-md ml10" />}
					{record.doctor.showOnCalendar
				&& <i title="Show on calendar" className="fa fa-calendar ml10" />}
				</LinkWithPrevLocation>
			) : ''),
		},
		{
			title: '',
			dataIndex: 'actions',
			settingsTitle: 'Actions',
			view: (text, record: User, props) => {
				return (
					<div className="text-right table-actions">
						<SendMessageButton className="btn btn-sm btn-primary" user={record} />
						{(user?.root || user?.transmutation || user?.role === UserRole.Admin)
							&& (
								<Transmutation
									user={record}
									className={`btn btn-sm btn-${record?.company?.allowSupportLogin ? 'success' : 'default'}`}
								/>
							)}
						{user?.transmutation && !user?.root && user?.companyId !== record.companyId
							? (
								<LinkWithPrevLocation
									className="btn btn-sm btn-default"
									title="View"
									to={{ pathname: `/user-editor/${record.id}`, search: '?mode=view&user-profile-tabs=general' }}
								>
									<i className="fa fa-eye" />
								</LinkWithPrevLocation>
							)
							: (
								<>
									<LinkWithPrevLocation
										className="btn btn-sm btn-default"
										title="Edit"
										to={{ pathname: `/user-editor/${record.id}` }}
									>
										<i className="fa fa-pencil" />
									</LinkWithPrevLocation>
									{props?.getDefaultAction([ColumnActions.Remove])}
									<Ban user={record} />
									<GenerateUserPassword user={record} onError={Message.error} />
								</>
							)
						}
					</div>
				);
			},
		},
	] as Array<EditableTableColumn<User>>, []);

	return (
		<>
			<ServerPageProvider
				path="user-list"
				inner={(serverPage) => (
					<ItemsProvider
						type="user"
						saveRequest="user"
						loadRequest="usersPageViewList"
						items={serverPage?.items?.list}
						unhandledFilters={additionalParams}
						clearForSubmit={(values: User) => {
							return { locations: clearListFields(values.locations as Array<UserLocation>, ['location', 'user']) };
						}}
						withHandleUrl
						filters={params}
						pagination={{
							pageSize: params.pageSize || 10,
							current: +page || 1,
							total: serverPage?.items?.count,
						}}
						path="user-list"
					>
						<TableTitle
							inner={() => {
								return (
									<>
										<LinkWithPrevLocation to="/user-editor/-1" className="btn btn-primary pull-right">
											Add System User
										</LinkWithPrevLocation>
									</>
								);
							}}
							title="System Users"
						/>
						<UserFilters
							initialValues={serverPage?.filterProps}
						/>
						<EditableTable
							removeConfirmationText="This item will be permanently deleted and cannot be recovered. Are you sure?"
							bordered={false}
							columns={columns}
							skipInitLoad
							antdProps={antdProps}
							withColumnSettings
							readonly
						/>
					</ItemsProvider>
				)}
			/>
		</>
	);
};

export default Users;
