import * as React from 'react';

import { ChatPlugin } from '@common/react/components/Chat/Chat';
import Button from '@common/react/components/Forms/Button';

export const OnlineFilterPlugin : ChatPlugin = {
	chatsListHeaderComponent: ({ filters, handleChange }) =>
		<Button
			className="btn btn-primary load-chats-btn icon-btn"
			title={filters.online ? 'Online' : 'Offline'}
			onClick={() => handleChange({ online: !filters.online })}
		>
			<i className={`fa fa-${filters.online ? 'user' : 'user-o'}`} aria-hidden="true" />
		</Button>,
};
