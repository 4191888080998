import React from 'react';

import Tooltip from 'antd/lib/tooltip';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import Autocomplete from '@common/react/components/Forms/Autocomplete/Autocomplete';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { ItemsProvider } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import { EditableTable, EditableTableColumn } from '@common/react/components/Core/EditableTable/EditableTable';
import LoadMoreButton from '@common/react/components/Core/ItemsProvider/LoadMoreButton';
import { useItemModalProviderContext } from '@common/react/components/Core/ItemModalProvider/ItemModalProvider';
import Button from '@common/react/components/Forms/Button';

import MultiSelectAuto from '@commonTuna/react/components/UI/MultiSelectAuto/MultiSelectAuto';
import { UserGroupType } from '@commonTuna/react/objects/UserGroup';

import { Appointment, AppointmentType, SystemAppointmentStatus } from '@app/objects/Appointment';
import { dateTimeFormatString, dateToUtcString } from '@app/components/Utils';
import { Logo, logoDictionary } from '@app/components/UI/Logo/Logo';
import { WizardButton } from '@app/components/UI/WizardButton/WizardButton';
import { Patient } from '@app/objects/Patient';
import { Doctor, DoctorProcedure } from '@app/objects/Doctor';
import TimeFrom from '@app/components/UI/TimeFrom/TimeFrom';
import { ProcedureGroupProcedure } from '@app/objects/ProcedureGroup';
import { formatPrice } from '@app/objects/Product/Product';
import { LinkToInsuranceEditor } from '@app/components/UI/LinkToInsuranceEditor/LinkToInsuranceEditor';
import { OrderTargetType } from '@app/objects/Order';
import DoctorAutocompleteFilter from '@app/components/UI/DoctorAutocompleteFilter';
import { getContrast } from '@app/components/Pages/Admin/Calendar/calendarUtils';
import { useUserGroupContext } from '@app/components/UI/UserGroupProvider/UserGroupProvider';

interface ComponentProps {
	id: number;
	patient: Patient | null;
	to: number | null;
	doctorProcedures: Array<DoctorProcedure>;
	doctor: Doctor | null;
	selectAppointment?: (item: Appointment) => void;
}

interface Filters {
	procedureGroupIds: Array<number>;
	procedureIds: Array<number>;
	doctorIds: Array<number>;
	patientId: number;
}

const getUniqueById = (values) => {
	const uniqueIds = new Set(values.map(({ id }) => id));
	return Array.from(uniqueIds).map((i) => values.find(({ id }) => id === i));
};

export enum ObjectHistoryType {
	All = 0,
	OrderTargets = 1,
	AppointmentProcedures = 2
}

export const objectHistoryTypeFilterGroup = [
	{ caption: 'All', value: ObjectHistoryType.All },
	{ caption: 'Orders', value: ObjectHistoryType.OrderTargets },
	{ caption: 'Appointments', value: ObjectHistoryType.AppointmentProcedures },
];

export interface AppointmentHistoryRow {
	id: number;
	duration: number;
	scheduledTime: number;
	service: string;
	count: number;
	price: number;
	color: string;
	basePrice: number;
	total: number;
	created: number;
	appointment: Appointment;
	objectId: number;
	appointmentId?: number;
	imageCount?: number;
	tax?: number;
	hasOrdersInProgress: boolean;
	orderTargetType: OrderTargetType | undefined;
	quotasCount: number;
	answeredConsentForms: number;
	appointmentType: AppointmentType;
	serviceId?: number;
	specialId?: number;
	productId?: number;
}

const getHistoryContainer = (node) => node.closest('.history__top') || document.body;

const getAppointmentSearch = (filters) => {
	return Object.keys(filters).reduce((ac, key) => {
		if (filters[key] === -1) {
			return ac;
		}
		if (Array.isArray(filters[key])) {
			return `${ac}&${key}=[${filters[key]}]`;
		}
		return `${ac}&${key}=${filters[key]}`;
	}, '');
};

interface AppointmentHistoryReloadHandlerProps {
	reload: () => void;
	filters: any;
}

const AppointmentHistoryReloadHandler: React.FC<AppointmentHistoryReloadHandlerProps> = (props) => {
	const {
		reload,
		filters,
	} = props;
	React.useEffect(() => {
		reload();
	}, [filters]);
	return <></>;
};

const AppointmentHistory: React.FC<ComponentProps> = ({
	id,
	patient,
	doctorProcedures,
	doctor,
	to,
	selectAppointment,
}) => {
	const defaultDoctors = React.useMemo(() => {
		return getUniqueById(doctorProcedures.map((item) => item.doctor).concat(doctor ? [doctor] : []));
	}, [doctor, doctorProcedures]);
	const modalContext = useItemModalProviderContext();

	const { checkUserAccess } = useApplicationContext();
	const { checkUserGroupAccess } = useUserGroupContext();

	const defaultProcedures = React.useMemo(() => {
		const withOutGroup = doctorProcedures
			.map((item) => item.procedure)
			.filter(({ groups }) => (groups || []).length === 0);
		return getUniqueById(withOutGroup);
	}, [doctorProcedures]);

	const defaultGroups = React.useMemo(() => {
		const groups: Array<ProcedureGroupProcedure> = [];
		doctorProcedures.forEach((item) => item.procedure.groups?.forEach((group) => groups.push(group)));

		return getUniqueById(groups).map(({ id, procedureGroup }) => ({ id, ...procedureGroup }));
	}, [doctorProcedures]);

	const [filters, setFilters] = React.useState<Filters>({
		patientId: patient?.id || -1,
		procedureGroupIds: defaultGroups.map((item) => item.id),
		procedureIds: defaultProcedures.map((item) => item.id),
		doctorIds: defaultDoctors.map((item) => item.id),
	});
	const [keys, setKeys] = React.useState<any>({ key: 'default' });

	React.useEffect(() => {
		const {
			patientId, procedureGroupIds, procedureIds, doctorIds,
		} = filters;
		if (patientId !== (patient?.id || -1) || `${doctorIds}` !== `${defaultDoctors.map((item) => item.id)}`
			|| `${procedureIds}` !== `${defaultProcedures.map((item) => item.id)}`
				|| `${procedureGroupIds}` !== `${defaultGroups.map((item) => item.id)}`) {
			setKeys((prev) => ({ ...prev, key: Math.random(), procedures: Math.random() }));
			setFilters((prev) => ({
				...prev,
				patientId: patient?.id || -1,
				doctorIds: defaultDoctors.map((item) => item.id),
				procedureIds: defaultProcedures.map((item) => item.id),
				procedureGroupIds: defaultGroups.map((item) => item.id),
			}));
		}
	}, [patient, doctor, doctorProcedures.length]);

	const renderActionButtons = (record: Appointment): JSX.Element => {
		let to = { pathname: `/appointment-editor/${record.id}`, search: '' };

		if (record.patientId) {
			to = { pathname: `/patient-editor/${record.patientId}`, search: `patient-tabs=${record.id}` };
		}

		return (
			<>
				<LinkWithPrevLocation
					className="btn btn-sm btn-default"
					title="Edit"
					to={to}
				>
					<i className="fa fa-pencil" />
				</LinkWithPrevLocation>
			</>
		);
	};

	const columns: Array<EditableTableColumn<AppointmentHistoryRow>> = [
		{
			title: 'Time',
			dataIndex: 'scheduledTime',
			key: 'scheduledTime',
			view: (text, record: AppointmentHistoryRow): JSX.Element | null => {
				if (record.scheduledTime) {
					const patientId = record.appointment?.patient?.id || record.appointment?.patientId;
					const color = record.color || '#fff';

					return (
						<div>
							{record.color && (
								<Tooltip
									placement="left"
									title={record.appointmentType?.name}
									overlayStyle={{
										'--color': getContrast(color),
										'--bg-color': color,
									} as React.CSSProperties}
									overlayClassName="appointment-history-tooltip"
								>
									<div
										className="appointment-history__line"
										style={{ color: record.color || 'transparent' }}
									/>
								</Tooltip>
							)}
							<LinkWithPrevLocation
								to={{
									pathname: `/patient-editor/${patientId}`,
									search: `patient-tabs=${record.appointment.id}&mode=view`,
								}}
							>
								{dateToUtcString(record.scheduledTime, dateTimeFormatString)}
							</LinkWithPrevLocation>
						</div>
					);
				}
				return null;
			},
			width: '16%',
		},
		{
			title: <i className="fa fa-hourglass" />,
			dataIndex: 'time',
			key: 'time since',
			view: (text, record: AppointmentHistoryRow): JSX.Element | null => {
				return (
					<TimeFrom
						time={record.scheduledTime}
						duration={record.duration}
					/>
				);
			},
			width: '9%',
		},
		{
			title: 'Service',
			dataIndex: 'service',
			key: 'service',
			view: (text, record: AppointmentHistoryRow) => record.service,
			width: '33%',
		},
		{
			title: 'Units',
			dataIndex: 'units',
			view: (text, record: AppointmentHistoryRow) => record.count,
			width: '6%',
		},
		{
			title: '$',
			dataIndex: 'price',
			view: (text, record: AppointmentHistoryRow) => {
				return (
					<Tooltip title="Item Procedure Price">
						{`$${formatPrice(record.price)}${record.basePrice ? ` (${record.basePrice})` : ''}`}
					</Tooltip>
				);
			},
			width: '12%',
		},
		{
			title: '$',
			dataIndex: 'total',
			view: (text, record: AppointmentHistoryRow) => (
				<Tooltip title="Total Procedure Price">
					{`$${formatPrice(record.price * record.count)}`}
				</Tooltip>
			),
			width: '10%',
		},
	];

	const canLoadHistory: boolean = id > 0
		? Boolean(patient)
		: (filters.patientId > 0
			|| filters.procedureIds.length > 0
			|| filters.doctorIds.length > 0
			|| filters.procedureGroupIds.length > 0);

	return (
		<ItemsProvider
			type="orderTargetAppointmentHistory"
			loadRequest="orderTargetAppointmentHistory"
			pagination={{
				current: 1,
				pageSize: 10,
			}}
			filters={filters}
		>
			{({ state: { items, pagination }, actions }) =>
				<>
					<AppointmentHistoryReloadHandler
						filters={filters}
						reload={() => {
							if (canLoadHistory) {
								actions.reload(filters);
							} else {
								actions.setItems([]);
							}
						}}
					/>
					<div className="history__top">
						<div className="history-filters row">
							<div className="form-group col-sm-3 mb10">
								<Autocomplete<Patient>
									key={keys.key}
									type="patientViewAppointmentList"
									renderTitle={(item?: Patient) => `${item?.firstName || ''} ${item?.lastName || ''}`}
									onSelect={(value) => {
										actions.handleChange({ patientId: +value });
									}}
									onChange={(value = '') => {
										if (value === '') {
											actions.handleChange({ patientId: -1 });
										}
									}}
									value={patient ? `${patient?.firstName || ''} ${patient?.lastName || ''}` : ''}
									loadOnFocus
									placeholder="Patient..."
								/>
							</div>
							<div className="filter-element col-sm-3 mb10">
								<DoctorAutocompleteFilter
									multiple
									key={keys.procedures}
									onChange={(value: any) => {
										actions.handleChange({ doctorIds: value.map((q) => +q) });
									}}
									items={defaultDoctors as any}
									antdProps={{
										defaultValue: defaultDoctors as any,
										allowClear: true,
										maxTagTextLength: 12,
										getPopupContainer: getHistoryContainer,
									} as any}
								/>
							</div>
							<div className="form-group col-sm-3 mb10">
								<MultiSelectAuto
									key={keys.procedures}
									type="procedureList"
									onChange={(value: any) => {
										actions.handleChange({ procedureIds: value.map((q) => +q) });
									}}
									items={defaultProcedures}
									antdProps={{
										style: { width: '100%' },
										defaultValue: defaultProcedures as any,
										allowClear: true,
										maxTagTextLength: 12,
										getPopupContainer: getHistoryContainer,
									} as any}
									loadOnFocus
									placeholder="Procedures..."
								/>
							</div>
							<div className="filter-element col-sm-3 mb10">
								<MultiSelectAuto
									key={keys.procedures || 'procedureIds'}
									type="procedureGroupList"
									onChange={(value: any) => {
										actions.handleChange({ procedureGroupIds: value.map((q) => +q) });
									}}
									renderTitle={(item) => item.name}
									placeholder="Service Groups..."
									loadOnFocus
									antdProps={{
										defaultValue: defaultGroups,
										maxTagTextLength: 12,
										getPopupContainer: getHistoryContainer,
									} as any}
									items={defaultGroups as any}
								/>
							</div>
						</div>
						<LinkWithPrevLocation
							title="Appointments"
							className="btn btn-default ml10"
							to={{
								pathname: '/appointment-list',
								search: getAppointmentSearch(filters),
							}}
						>
							<i className="fa fa-calendar-o" title="Appointments" />
						</LinkWithPrevLocation>
					</div>
					{items && items.length > 0
						? (
							<EditableTable
								antdProps={{
									showHeader: false,
									className: 'appointment-document-table appointment-history',
								}}
								bordered={false}
								readonly={false}
								hidePagination
								skipInitLoad
								columns={columns}
								withColumnSettings={false}
								adaptiveActions
								addButton={false}
								actionColumnWidth="4%"
								viewActions={[
									(record) =>
										!!Object.keys(logoDictionary).filter((key) => !!record[key] || !!record[`${key}Id`]).length && <Logo
											className={null}
											item={record.appointment}
											style={{
												width: 20, height: 20, marginRight: 10, borderRadius: 2,
											}}
										/>,
									(record) => (checkUserAccess([]) || checkUserGroupAccess(UserGroupType.InsuranceAccess))
										&& record.appointment?.insurance
										&& <LinkToInsuranceEditor appointment={record.appointment} />,
									(record) => (record.appointment?.status?.saStatus === SystemAppointmentStatus.Completed ? (
										<LinkWithPrevLocation
											title="Payments and Quotes"
											className="btn btn-sm btn-primary mr5"
											to={`/checkout/${record.appointment.id}`}
										>
											<i className="fa fa-dollar" />
										</LinkWithPrevLocation>
									) : (
										<button type="button" title="Payments and Quotes" className="btn btn-sm btn-primary mr5" disabled>
											<i className="fa fa-dollar" />
										</button>
									)),
									(record) => record.appointment && <WizardButton appointment={record.appointment} />,
									(record) => record.appointment && modalContext?.state && <Button
										disabled={id === record.appointment?.id}
										title={id === record.appointment?.id ? 'Current Appointment' : 'Show'}
										isLoading={modalContext.state.id === record?.id && modalContext.state.loading}
										key="modal"
										className="btn btn-sm btn-default"
										type="button"
										onClick={(e) => modalContext.actions.openModal(record.appointment)}
									>
										<i className="fa fa-eye" />
									</Button>,
									(record) => record.appointment && renderActionButtons(record.appointment),
								]}
							/>
						)
						: (
							<div className="text-center">
								{id > 0
									? 'There are no one appointment in history after current appointment'
									: 'There are no one appointment in history for this patient and / or provider'}
							</div>
						)}
					{items.length < pagination.total ? (
						<div className="text-center">
							<LoadMoreButton className="btn btn-primary mt10">
								Load more
							</LoadMoreButton>
						</div>
					) : null}
				</>}
		</ItemsProvider>
	);
};

export default AppointmentHistory;
