import * as React from 'react';

import { ChatPlugin } from '@common/react/components/Chat/Chat';
import SimpleSearchInput from '@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput';

export const SearchingPlugin : ChatPlugin = {
	chatsListHeaderComponent: ({ handleChange }) =>
		<SimpleSearchInput
			onSubmit={handleChange}
			name="text"
			withoutForm
		/>,
};
