import * as React from 'react';

import Select, { SelectProps, OptionProps } from 'antd/lib/select';

import ItemsProviderWithStore, { ItemsProviderWithStoreProps } from '@common/react/components/Core/ItemsProviderWithStore/ItemsProviderWithStore';
import {
	ItemsProvider,
	ItemsProviderContextState,
	WithKey,
} from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import ItemsProviderSynchronizer, {
	ItemsProviderSynchronizerProps,
} from '@common/react/components/Core/AdvancedItemsProvider/ItemsProviderSynchronizer';

export interface ItemsSelectProps<T extends WithKey> extends Omit<ItemsProviderWithStoreProps<T>, 'storeName' | 'children'> {
	selectProps: SelectProps;
	getOptionProps: (item: T) => OptionProps;
	storeName?: string;
	children?: React.ReactNode | ((context: ItemsProviderContextState<T>) => React.ReactNode);
	additionalOptions?: React.ReactNode;
	loading?: boolean;
	loadingPlaceholder?: string;
	errorMessage?: string;
	filterItem?: (item: T) => boolean;
	itemsProviderSynchronizerProps?: ItemsProviderSynchronizerProps;
}

const defaultErrorMessage = 'Ops something goes wrong... Click to try reload';

const ItemsSelect = <T extends WithKey>(props: ItemsSelectProps<T>) => {
	const {
		getOptionProps, selectProps, storeName, defaultSort, filterHandler, sortHandler, children,
		additionalOptions,
		loading,
		loadingPlaceholder,
		filterItem,
		errorMessage = defaultErrorMessage,
		itemsProviderSynchronizerProps,
		...itemsProviderProps
	} = props;

	if (loading) {
		return <Select
			{...selectProps}
			placeholder={loadingPlaceholder}
			value={undefined}
			loading
			disabled
		/>;
	}

	const selectNode = (state, actions) => <>
		{typeof children === 'function' ? children(state) : undefined}
		<Select
			loading={state.loading}
			{...selectProps}
			placeholder={state.error && !state.loading
				? errorMessage
				: selectProps.placeholder}
			title={state.error && !state.loading
				? errorMessage
				: selectProps.title}
			onClick={state.error && !state.loading ? () => actions.load() : undefined}
		>
			{additionalOptions || null}
			{state.items.filter((item) => (filterItem ? filterItem(item) : true)).map((item) => {
				const props = getOptionProps(item);
				return <Select.Option key={item.id} {...props}>
					{props.children}
				</Select.Option>;
			})}
		</Select>
		{itemsProviderSynchronizerProps ? <ItemsProviderSynchronizer
			{...itemsProviderSynchronizerProps}
		/> : null}
	</>;

	return (
		storeName
			? (
				<ItemsProviderWithStore<T>
					defaultSort={defaultSort}
					filterHandler={filterHandler}
					sortHandler={sortHandler}
					storeName={storeName}
					{...itemsProviderProps}
				>
					{selectNode}
				</ItemsProviderWithStore>
			)
			: (
				<ItemsProvider
					{...itemsProviderProps}
					skipInitLoad={!!itemsProviderProps.items}
				>
					{(context) => selectNode(context.state, context.actions)}
				</ItemsProvider>
			)
	);
};

export default ItemsSelect;
