import { Action, Reducer } from 'redux';

export interface ServerPagesState {
	pages: any | null;
}

export enum TypeKeys {
	INITPAGES = 'INITPAGES'
}

export interface InitPagesAction {
	type: TypeKeys.INITPAGES;
	storageName: string | null;
	pages: any;
}

type KnownPageAction = InitPagesAction;

export const reducer = <P>(storageName: string):Reducer<ServerPagesState> => {
	return (state: ServerPagesState = { pages: null }, incomingAction: Action) => {
		const action = incomingAction as KnownPageAction;
		if (!action.storageName || action.storageName === storageName) {
			switch (action.type) {
				case TypeKeys.INITPAGES:
					return { pages: action.pages };
				default:
					const exhaustiveCheck: any = action;
			}
		}

		return state;
	};
};
