import * as React from 'react';

import { ChatMessageType, ChatPlugin } from '@common/react/components/Chat/Chat';
import RegularChatMessage from '@common/react/components/Chat/RegularMessagePlugin/RegularChatMessage';
import ReplyMessage from '@common/react/components/Chat/ReplyPlugin/ReplyMessage';
import ReplyLastMessage from '@common/react/components/Chat/ReplyPlugin/ReplyLastMessage';

const allowMessageTypes = [
	ChatMessageType.Regular,
	ChatMessageType.Giphy,
	ChatMessageType.Tenor,
	ChatMessageType.Sticker,
	ChatMessageType.ReplyMessage,
];

export const ReplyPlugin : ChatPlugin = {
	chatMessageAction: ({
		reply, message, isEdit, fromUser,
	}) => {
		return (!fromUser && allowMessageTypes.includes(message?.chatMessageType) ? <button
			key="edit"
			className="message-action btn btn-default btn-sm"
			type="button"
			onClick={() => reply(isEdit ? null : message)}
		>
			{isEdit ? 'Cancel' : 'Reply'}
		</button> : null);
	},
	message: {
		render: ({ message, contacts, onImageClick }) => <ReplyMessage message={message} contacts={contacts} />,
		lastMessage: ({ message, chat, userId }) => <ReplyLastMessage message={message} chat={chat} userId={userId} />,
		notification: ({ message, withRemoteId, contacts }) => {
			return <RegularChatMessage message={message} contacts={contacts} />;
		},
	},
};
