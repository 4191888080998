import * as React from 'react';

import Modal from 'antd/lib/modal';

import { Chat } from '@common/react/components/Chat/Chat';
import { getUserName } from '@common/react/utils/utils';
import Autocomplete from '@common/react/components/Forms/Autocomplete/Autocomplete';
import { BaseParams } from '@common/react/objects/BaseParams';
import { BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import useRequest from '@common/react/hooks/useRequest';

interface AddChatPopupProps {
	user: BaseUserWithAvatar;
	render: (show: () => void) => void;
	chat: Chat;
	autocompleteParams?: BaseParams;
	type?: string;
	renderItem?: (user: BaseUserWithAvatar) => React.ReactNode;
	renderAutocompleteItem?: (user: BaseUserWithAvatar) => React.ReactNode;
}

const AddUserToChatPopup:React.FC<AddChatPopupProps> = (props) => {
	const [popupShow, setPopupShow] = React.useState(false);

	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests } } = context;
	const request = useRequest();

	const showPopup = () => setPopupShow(true);
	const popupHide = () => setPopupShow(false);

	const onSelect = (value, option) => {
		const item = {
			chatId: props.chat.id,
			userId: +value,
		};

		request(requests.chatUser, item);
	};

	const {
		user: loggedUser, chat, autocompleteParams, type = 'userList', renderItem = (user: BaseUserWithAvatar) => getUserName(user),
	} = props;
	const { renderAutocompleteItem = renderItem } = props;

	return <>
		{props.render(showPopup)}
		<Modal
			title="Add users"
			open={popupShow}
			onCancel={popupHide}
			destroyOnClose
			width={500}
			footer={null}
		>
			<div className="add-user-to-chat">
				<label htmlFor="">Contacts:</label>
				<div>
					{chat.contacts.map((user: BaseUserWithAvatar) => <div
						key={user.id}
						style={{ width: '100%' }}
						className="inline-select-item"
					>
						{renderItem(user)}
					</div>)}
				</div>
				<div>
					<Autocomplete<BaseUserWithAvatar>
						type={type}
						renderOption={(autocompleteItem) => {
							return {
								key: `${autocompleteItem.id}`,
								value: getUserName(autocompleteItem),
								title: getUserName(autocompleteItem),
								item: autocompleteItem,
								label: renderAutocompleteItem(autocompleteItem),
							};
						}}
						onSelect={onSelect}
						value=""
						isClear
						params={autocompleteParams}
						loadOnFocus
						antdProps={{
							placeholder: 'Start typing for search...',
							filterOption: (inputValue, option: any) => {
								const id = +(option.key as string);
								return !chat.contacts.some((item: BaseUserWithAvatar) => id === item.id);
							},
						}}
					/>
				</div>
			</div>
		</Modal>
	</>;
};

export default AddUserToChatPopup;
