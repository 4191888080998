import React from 'react';
import Modal from 'antd/lib/modal';

import { getLocalStorageValue, setLocalStorageValue } from '@common/react/utils/localStorage/localStorage';

import { detectMob } from '@app/components/Utils';

const CheckDevice: React.FC = () => {
	const [firstOpen, setFirstOpen] = React.useState(!!+getLocalStorageValue('check-device', 1));
	const handleClose = () => {
		setLocalStorageValue('check-device', 0);
		setFirstOpen(false);
	};

	React.useEffect(() => {
		if (detectMob() && window.innerWidth < 830 && firstOpen) {
			Modal.warn({
				icon: <div style={{ width: 17 }} />,
				title: <div className="text-center">
					<h4>
						⚠️ Device Compatibility Alert ⚠️
					</h4>
					Your current device isn't compatible with our system.
					Please switch to another device for optimal performance. Thank you!
				</div>,
				onOk: handleClose,
				onCancel: handleClose,
				okButtonProps: {
					style: {
						display: 'block',
						margin: '0 auto',
					},
				},
			});
		}
	}, []);

	return <></>;
};

export default CheckDevice;
