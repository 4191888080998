import React from 'react';

import VoiceMessagePlayer from '@common/react/components/UI/VoiceMessage/VoiceMessagePlayer';

interface VoiceMessageChatPlayerProps {
	voiceMessageUrl?: string;
	onPlayed?: () => void;
}

const VoiceMessageChatPlayer: React.FC<VoiceMessageChatPlayerProps> = ({ voiceMessageUrl, onPlayed }) => {
	return voiceMessageUrl ? <VoiceMessagePlayer url={voiceMessageUrl} onPlayed={onPlayed} /> : <div>VOICE MESSAGE URL UNDEFINED</div>;
};

export default VoiceMessageChatPlayer;
