import * as React from 'react';

import Colorbox from '@common/react/components/UI/Colorbox/Colorbox';
import { ChatPlugin } from '@common/react/components/Chat/Chat';

export const ColorboxPlugin: ChatPlugin = {
	additionalComponent: ({
		chat, defaultIdx, files, setImg, basePath,
	}) => (
		<Colorbox
			basePath={basePath}
			files={files || []}
			defaultIdx={defaultIdx ?? null}
			onCancel={() => setImg(undefined)}
			withDownload={false}
		/>),
};
