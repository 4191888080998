import React from 'react';
import { useDispatch } from 'react-redux';

import { Dispatch } from 'redux';

import { NotificationAction } from '@common/typescript/objects/NotificationAction';
import { ItemsProviderContext } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Notification } from '@common/typescript/objects/Notification';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import {
	useAdvancedItemsProviderContext,
} from '@common/react/components/Core/AdvancedItemsProvider/AdvancedItemsProvider';
import { TypeKeys } from '@common/react/store/ItemsProviderStore';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

export interface ItemsProviderSynchronizerProps {
	objectType: string;
	objectSubtype?: string;
	getId?: (item) => any;
	customHandler?: (notification: any, context: ItemsProviderContext<WithDeleted>) => void;
	handle?: (notification: Notification<BaseUser>, context: ItemsProviderContext<any>) => void;
	storeName?: string;
	storeType?: string;
	storeSortAfterAdd?: (items) => Array<any>;
	storeHandler?: (notification: Notification<BaseUser>, dispatch: Dispatch<any>) => void;
	getItemsFromInit?: (notification) => Array<any>;
}

const defaultGetId = (value) => (value.id === undefined ? value?.entry.id : value.id);

const ItemsProviderSynchronizer: React.FC<ItemsProviderSynchronizerProps> = (props) => {
	const {
		getId = defaultGetId, objectType, objectSubtype, customHandler, handle,
		storeName, storeType = storeName, storeSortAfterAdd, storeHandler: storeHandlerProps, getItemsFromInit,
	} = props;
	const context = useAdvancedItemsProviderContext<any>();
	const { subscribe } = useApplicationContext();

	if (!context.state) throw 'Need ItemsProvider context!';

	const {
		state: { items, loaders, deleting },
		actions: {
			addItem, reloadItems, updateItem, setItems,
		},
	} = context;

	const dispatch = useDispatch();

	const defaultHandler = (notification) => {
		const data = notification.data;
		const itemData = data.entity ? { ...data.entity, id: data.id } : data;
		if (notification.action === NotificationAction.Add && (data?.entity || !objectType.toLowerCase().includes('redux'))) {
			addItem(itemData);
			return;
		}

		const item = items.find((item) => getId(item) === getId(notification.data));

		if (notification.action === NotificationAction.Update && (data?.entity || !objectType.toLowerCase().includes('redux'))) {
			const data = notification.data?.entity ? { id: notification.data.id, ...notification.data.entity } : notification.data;

			return item ? updateItem(data) : addItem(data);
		}

		if (notification.action === NotificationAction.Delete) {
			item && !deleting.current && reloadItems();
		}
	};

	const byObjectSubtype = (notification: Notification<BaseUser>) =>
		notification.objectType === objectType && notification.service && objectSubtype && notification.objectSubtype === objectSubtype;

	const storeHandlerDefault = (notification, dispatch) => {
		if (storeName) {
			const data = notification.data;
			if (notification.objectType === objectType) {
				const item = data.entity ? { ...data.entity, id: data.id } : data;
				if (notification.action === NotificationAction.Add && (data?.entity || !objectType.toLowerCase().includes('redux'))) {
					dispatch({
						type: TypeKeys.ADDITEM,
						storageName: storeName,
						objectType: storeType,
						value: item,
						sort: storeSortAfterAdd,
					});
				} else if (notification.action === NotificationAction.Delete) {
					dispatch({
						type: TypeKeys.REMOVEITEM,
						storageName: storeName,
						objectType: storeType,
						paramName: 'id',
						value: item.id,
					});
				} else if (notification.action === NotificationAction.Update && (data?.entity || !objectType.toLowerCase().includes('redux'))) {
					dispatch({
						type: TypeKeys.UPDATEITEM,
						storageName: storeName,
						objectType: storeType,
						paramName: 'id',
						value: item,
					});
				}
			}
		}
	};

	const storeHandler = storeHandlerProps || storeHandlerDefault;

	const _handle = (notification: Notification<BaseUser>) => {
		if (notification.objectType === 'Init' && getItemsFromInit) {
			setItems(getItemsFromInit(notification));
		}

		if (handle) {
			handle(notification, context);

			return;
		}

		if (byObjectSubtype(notification)) {
			if (notification.data) {
				customHandler ? customHandler(notification, context) : defaultHandler(notification);
				storeHandler(notification, dispatch);
			}
			return;
		}

		if (notification.objectType === objectType && notification.service && !objectSubtype && !notification.objectSubtype) {
			if (notification.data) {
				customHandler ? customHandler(notification, context) : defaultHandler(notification);
				storeHandler(notification, dispatch);
			}
		}
	};

	React.useEffect(subscribe(_handle), [items, loaders]);

	return <></>;
};

export default React.memo(ItemsProviderSynchronizer);
