import * as React from 'react';

import { ChatPlugin } from '@common/react/components/Chat/Chat';

const EmailMessage: any = ({ message }: any) => <div style={{
	display: 'flex',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	backgroundColor: 'aliceblue',
	width: 'fit-content',
}}
>
	<i className="fa fa-envelope" style={{ color: 'dodgerblue', fontSize: '2rem' }} />
	<div style={{
		fontSize: '1.5rem',
		fontWeight: 'bold',
		marginLeft: '1rem',
		width: 'fit-content',
	}}
	>
		{message.text}
	</div>
</div>;

const LastMessage = ({ message, userId }) => <div className="chat-list__item-last-message">
	{message.userId === userId ? 'You: ' : ''}
	{' '}
	sent email
</div>;

export const EmailPlugin: ChatPlugin = {
	message: {
		render: EmailMessage,
		lastMessage: LastMessage,
		notification: EmailMessage,
	},
};
