import React from 'react';

import { v4 as uuid } from 'uuid';

import LinkPreviewComponent from '@common/react/components/UI/LinkPreview/LinkPreview';
import { PreviewInfo } from '@common/react/objects/LinkPreview';
import useRequest from '@common/react/hooks/useRequest';

interface LinkPreviewGroupProps {
	linkPreviewId: string;
	links: Array<string>;
	className?: string;
	previewRequest?: (onSuccess?: (infos: Array<PreviewInfo>) => void, onError?: (err) => void) => void;
	onProcess?: () => void;
	onSuccess?: () => void;
	onError?: () => void;
}

const LinkPreviewGroup: React.FC<LinkPreviewGroupProps> = ({
	linkPreviewId, links, className, previewRequest, onProcess, onSuccess, onError,
}) => {
	const request = useRequest();
	const [previewInfos, setPreviewInfos] = React.useState<Array<{link: string, id: string, item?: PreviewInfo}>>(() =>
		links.map((link) => ({ link, id: uuid() })));

	React.useEffect(() => {
		if (links.length > 0) {
			const isMount = { mount: true };
			onProcess && onProcess();
			if (previewRequest) {
				previewRequest((previewInfos) => handleSuccess(previewInfos, isMount), handleError);
			} else {
				request<any>('linkPreview', { linkPreviewId, links })
					.then((res) => handleSuccess(res, isMount))
					.catch(handleError);
			}
			return () => {
				isMount.mount = false;
			};
		}
	}, [links]);

	const handleSuccess = React.useCallback((previewInfos: Array<PreviewInfo>, isMount: {mount: boolean}) => {
		if (isMount.mount) {
			setPreviewInfos(previewInfos.map((previewInfo) => ({ link: previewInfo.link, id: uuid(), item: previewInfo })));
			onSuccess && onSuccess();
		}
	}, []);

	const handleError = React.useCallback((err) => {
		onError && onError();
	}, []);

	return (
		<>
			{
				previewInfos.map((item) =>
					<LinkPreviewComponent key={`link-preview-${item.id}`} previewInfo={item.item} />)
			}
		</>
	);
};

export default LinkPreviewGroup;
