import * as React from 'react';

import { FormikProps } from 'formik';

import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import {
	ChatFormButtonsProps,
	NewMessage,
	ChatPlugin,
	ChatStickerCollection,
} from '@common/react/components/Chat/Chat';
import StickerComponent from '@common/react/components/Chat/Sticker/StickerComponent';
import Colorbox from '@common/react/components/UI/Colorbox/Colorbox';

const StickerMessage: React.FC<{message, skeleton}> = ({ message, skeleton }) => {
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { basePath } } = context;

	const [error, setError] = React.useState(false);
	const [open, setOpen] = React.useState(false);

	const [src, fullSrc, description, type, width, height] = React.useMemo(() => {
		return (message.text || '').split('][').map((str) => str.replace(/[\[\]]/g, ''));
	}, [message]);

	return <div className="message__sticker">
		<div
			className={`message__sticker__image-wrapper ${fullSrc ? 'message__sticker-with-expand' : ''}`}
			onClick={() => setOpen(true)}
		>
			<div className="message__sticker__image">
				<img
					alt={description}
					src={error ? skeleton : src}
					onError={() => setError(true)}
				/>
			</div>
		</div>
		{fullSrc ? <Colorbox
			basePath={basePath}
			files={[{ file: { src: fullSrc.slice(0, 1) === '/' ? fullSrc.slice(1) : fullSrc } }]}
			defaultIdx={open ? 0 : null}
			onCancel={() => setOpen(false)}
		/> : null}
	</div>;
};

const LastMessage = ({ message, userId }) => {
	return <div className="chat-list__item-last-message">
		{message.userId === userId ? 'You: sent sticker' : 'sticker'}
	</div>;
};

export interface StickerComponentSettings {
	skeleton: string;
	collections: Array<ChatStickerCollection>;
}

export interface StickerPlugin extends ChatPlugin {
	options: StickerComponentSettings;
}

export const StickerPlugin : StickerPlugin = {
	formButton: (formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) =>
		(props.chatId ? <StickerComponent
			getPopupContainer={props.getPopupContainer}
			chatId={props.chatId}
			{...StickerPlugin.options}
		/> : <></>),
	message: {
		render: ({ message }) => <StickerMessage message={message} skeleton={StickerPlugin.options.skeleton} />,
		lastMessage: LastMessage,
		notification: ({ message }) => {
			const [src] = message.text.split('][').map((str) => str.replace(/[\[\]]/g, ''));

			return <div className="chat-message-notifier-glider__message-text__sticker">
				<img
					width="200"
					height="200"
					src={src}
					alt="sticker"
				/>
			</div>;
		},
	},
	options: {
		skeleton: '',
		collections: [],
	},
};
