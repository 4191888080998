import React from 'react';

import { RouteProps } from 'react-router-dom';

import { shallowEqual, useSelector } from 'react-redux';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import BaseSecureRouteWrapper from '@commonTuna/react/components/Routes/SecureRoute/BaseSecureRouteWrapper';

import { ApplicationState } from '@app/store';
import {
	useCompanyFeatureProviderContext,
} from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';
import { RoutesPropsMap } from '@app/components/Routes/SecureRoute/SecureRoutesPropsPagesMap';
import { User, UserRole } from '@app/objects/User';
import { useUserGroupContext } from '@app/components/UI/UserGroupProvider/UserGroupProvider';

interface ComponentProps extends RouteProps {
	path: string;
	children?: any;
}

export const SecureRouteWrapper: React.FC<ComponentProps> = ({
	path,
	children,
}) => {
	const routesPropsPagesMap = useSelector((state: ApplicationState) => state.routesPropsPagesMap.item, shallowEqual);
	const { checkFeatureAccess } = useCompanyFeatureProviderContext();
	const { checkUserGroupAccess } = useUserGroupContext();
	const { checkUserAccess, getUser } = useApplicationContext();
	const user = getUser<User>();

	const canRoleAccess = (route: RoutesPropsMap, role: UserRole) =>
		!route.roles || (route.roles.length > 0 && checkUserAccess(route.roles));

	const canFeatureAccess = (route: RoutesPropsMap) =>
		!route.features || !route.features.length || (route.features.length > 0 && checkFeatureAccess(route.features));

	const canUserGroupAccess = (route: RoutesPropsMap) =>
		!route.userGroupTypes || !route.userGroupTypes.length
		|| (route.userGroupTypes.length > 0 && checkUserGroupAccess(route.userGroupTypes));

	const canAccess = (mainPath: string): boolean => {
		const route = routesPropsPagesMap[mainPath];

		return Boolean(!route || (user && (user.root
			|| (canRoleAccess(route, user.role) && canFeatureAccess(route) && canUserGroupAccess(route)))));
	};

	return (
		<BaseSecureRouteWrapper
			path={path}
			canAccess={canAccess}
		>
			{children}
		</BaseSecureRouteWrapper>
	);
};

export default SecureRouteWrapper;
