import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { loadable, loadableDelay } from '@common/react/loadable/loadableSettings';
import { useSizableProviderContext } from '@common/react/components/Core/SizableWithServerPage/SizableWithServerPage';

import { UserGroupType } from '@commonTuna/react/objects/UserGroup';

import { params } from '@app/components/UI/TunaLoader';
import SecureRouteWrapper from '@app/components/Routes/SecureRoute/SecureRouteWrapper';
import { ApplicationState } from '@app/store';
import { useUserGroupContext } from '@app/components/UI/UserGroupProvider/UserGroupProvider';

const DashboardLayout = loadable(() => loadableDelay(
	/* webpackChunkName: "DashboardLayout" */ import('@app/components/Layouts/DashboardLayout/DashboardLayout'),
), params);

interface Props extends RouteProps {
	component: any;
	redirectPath?: string;
	title?: string;
	dashboard?: boolean;
}

const DashboardRoute: React.FC<Props> = ({
	component: Component, redirectPath = '/', dashboard = true, title, ...rest
}) => {
	const user = useSelector((state: ApplicationState) => state.login.user);
	const { checkUserGroupAccess } = useUserGroupContext();
	const context = useSizableProviderContext();

	return (
		<Route
			{...rest}
			render={(props) => (user
				? (
					<SecureRouteWrapper path={rest.path as string}>
						{(checkUserGroupAccess(UserGroupType.DashboardMenuInWizard) || dashboard) && !context.state
							? (
								<DashboardLayout>
									{title || dashboard ? (
										<Helmet>
											<title>{title}</title>
										</Helmet>
									) : null}
									<Component {...props} />
								</DashboardLayout>
							) : <Component {...props} /> }
					</SecureRouteWrapper>
				) : <Redirect to={redirectPath} />)}
		/>
	);
};

export default DashboardRoute;
