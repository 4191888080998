import React from 'react';

import Button from 'antd/lib/button';

import { record, RecordControls, RecordResult } from '@common/react/utils/record-audio';

const wrapperStyle: React.CSSProperties = {
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	zIndex: 30,
	backgroundColor: 'white',
	minWidth: '100px',
	minHeight: '100px',
	borderRadius: '50%',
	boxShadow: '0 0 25px 5px rgba(0, 0, 0, 0.2)',
	overflow: 'hidden',
};

interface VideoMessageButtonProps {
	button?: (onClick, isRecording) => JSX.Element;
	setRecordResult: (value: RecordResult | undefined) => void;
	onStop?: (value: RecordResult | undefined) => void;
	className?: string;
	shape?: 'circle' | 'default' | 'round';
	disabled?: boolean;
}

// TODO: Refactor
const VideoMessageRecorder: React.FC<VideoMessageButtonProps> = ({
	button, setRecordResult, onStop, ...rest
}) => {
	const { className = 'btn-primary', shape = 'default' } = rest;
	const [isRecording, setIsRecording] = React.useState<boolean>(false);
	const [mediaStream, setMediaStream] = React.useState<MediaStream>();
	const [controls, setControls] = React.useState<RecordControls>();

	const videoRef = React.useRef<HTMLVideoElement>(null);

	React.useEffect(() => {
		if (videoRef.current && mediaStream) {
			videoRef.current.srcObject = mediaStream;
		}
	}, [mediaStream]);

	const handleClick: React.MouseEventHandler = (e) => {
		e.preventDefault();

		if (!isRecording) {
			startRecordVideo();
			return;
		}

		stopRecordVideo();
	};

	const startRecordVideo = () => {
		const getMediaStream = async () => {
			const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
			setMediaStream(stream);
			return stream;
		};

		const getRecordControls = async () => {
			const stream = await getMediaStream();
			const controls = await record(stream, 'video/webm');
			setControls(controls);
			return controls;
		};

		getRecordControls()
			.then((controls) => {
				controls.start();
				setIsRecording(true);
			})
			.catch(console.error);
	};

	const stopRecordVideo = () => {
		console.log('stopped recording enter');
		if (!controls) return;
		console.log('stopped recording');

		controls.stop()
			.then((result) => {
				mediaStream?.getTracks().forEach((track) => track.stop());
				setIsRecording(false);
				setRecordResult(result);
				onStop && onStop(result);
			})
			.catch(console.error);
		setMediaStream(undefined);
		setControls(undefined);
	};

	return (
		<>
			<div className="video-message-recorder" style={{ ...wrapperStyle, display: isRecording ? 'block' : 'none' }}>
				<video style={{ transform: 'scale(1.8)' }} ref={videoRef} width={500} height={500} autoPlay muted />
			</div>
			{
				button
					? button(handleClick, isRecording)
					: (
						<Button
							disabled={rest.disabled}
							type="primary"
							shape={shape}
							className={`mr10 ${className}`}
							icon={!isRecording ? <i className="fa fa-circle" /> : <i className="fa fa-stop" />}
							onClick={isRecording ? stopRecordVideo : startRecordVideo}
						/>
					)
			}
		</>
	);
};

export default VideoMessageRecorder;
