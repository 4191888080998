import * as React from 'react';

import { ChatPlugin } from '@common/react/components/Chat/Chat';

export const DeletePlugin : ChatPlugin = {
	chatMessageAction: ({
		remove, isEdit, message, update, fromUser,
	}) =>
		(fromUser && !isEdit ? <button
			key="remove"
			className="message-action btn btn-danger btn-sm"
			type="button"
			onClick={remove}
		>
			Remove
		</button> : null),
};
