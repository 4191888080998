import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';
import { WithTime } from '@common/typescript/objects/WithTime';
import { isUndefined } from '@common/react/utils/guards';

import { PaymentInquiryStatus } from '@commonTuna/react/objects/BasePaymentInquiry';

import { Appointment, AppointmentProcedure } from '@app/objects/Appointment';
import { Patient } from '@app/objects/Patient';
import { Product, ProductLocation } from '@app/objects/Product/Product';
import { User } from '@app/objects/User';
import { Ledger } from '@app/objects/Ledger';
import { Special } from '@app/objects/Special';
import { Procedure } from '@app/objects/Procedure';
import { DoctorProcedure } from '@app/objects/Doctor';
import { DoctorPayer } from '@app/objects/DoctorPayer';
import { BillItem } from '@app/objects/BillItem';
import { PaymentInquiry, PaymentInquiryOrder } from '@app/objects/PaymentInquiry';
import { Transaction } from '@app/objects/Transaction';

/* export enum OrderTargetType {
	All = 0,
	Service = 1,
	Product = 2,
	Special = 3,
	Deposit = 4,
	ServiceProductSpecial = 5
} */

export enum OrderTargetType {
	None = 0,
	Service = 1,
	Product = 2,
	Special = 3,
	Deposit = 4,
	Copay = 5,
	Credit = 6,
	Tip = 7,
}

export const TargetName = [
	// { caption: 'All', type: OrderTargetType.All },
	{ caption: 'Service', type: OrderTargetType.Service },
	{ caption: 'Product', type: OrderTargetType.Product },
	{ caption: 'Specials', type: OrderTargetType.Special },
];

export enum OrderType {
	All = 0,
	Buy = 1,
	Use = 2,
	Quote = 3,
	Refund = 4,
	Order = 5
}

export enum PaymentType {
	NoPayments = -1,
	All = 0,
	Cash = 1,
	CreditCard = 2,
	DebitCard = 3,
	Check = 4,
	ElectronicPayment = 5,
	Balance = 6,
	Credit = 7,
	GiftCard = 8
}

export const PaymentTypeNames = {
	[PaymentType.NoPayments]: 'No payments',
	[PaymentType.All]: 'All',
	[PaymentType.Cash]: 'Cash',
	[PaymentType.CreditCard]: 'Credit Card',
	[PaymentType.DebitCard]: 'Debit Card',
	[PaymentType.Check]: 'Check',
	[PaymentType.ElectronicPayment]: 'Electronic Payment',
	[PaymentType.Balance]: 'Balance',
	[PaymentType.Credit]: 'A.R. (I.O.U)',
	[PaymentType.GiftCard]: 'Gift Card',
};

export enum OrderStatus {
	Complete = 0,
	InProgress = 1,
	Canceled = 2,
	Pending = 3
}

export enum OrderTextSearchObjects {
	Procedure,
	Product,
	Special
}

export const orderStatusFilter = {
	[OrderStatus.Complete]: 'Complete',
	[OrderStatus.InProgress]: 'InProgress',
	[OrderStatus.Canceled]: 'Canceled',
	[OrderStatus.Pending]: 'Pending',
};

export const OrderStatusColor = {
	[OrderStatus.Complete]: '#149c50',
	[OrderStatus.InProgress]: '#3c8dbc',
	[OrderStatus.Canceled]: '#da0000',
	[OrderStatus.Pending]: '#d2a127',
};

export const isProcessedPayment = (payment) => {
	return (payment.paymentType !== PaymentType.CreditCard && payment.paymentType !== PaymentType.DebitCard)
		|| payment.paymentInquiryOrders?.some((pio) => pio.paymentInquiry?.status === PaymentInquiryStatus.Processed);
};

export interface OrderPayment extends WithDeleted {
	orderId: Nullable<number>;
	order?: Order;

	paymentType: PaymentType;

	price: number;

	terminalLocationId?: number | null;
	paymentAccountId?: number | null;
	qrCodeLocationId?: number | null;

	transaction?: Transaction;
	transactionId?: number;

	paymentInquiryOrders?: Array<PaymentInquiryOrder>;
}

export interface Order extends WithDeleted, WithTime {
	userId: Nullable<number>;
	user: User | null;

	appointmentId: number;
	appointment?: Appointment;

	patientId: number;
	patient?: Patient;

	targets: Array<OrderTarget>;
	payments: Array<OrderPayment>;

	price: number;

	cost: number;

	accrualPrice: number;
	accrualCost: number;

	paymentType: PaymentType;

	status: OrderStatus;

	useInsurance: boolean | null;

	refund: boolean;

	quotes?: Array<Order>;

	quoteIds?: Array<number>;

	portalId?: number;

	paymentInquiries?: Array<PaymentInquiry>;

	completeTime?: number;
	credits?: number;

	orderCheck?: string | null;
	claimPaymentId?: number | null;
}

export enum InsuranceTargetStatus {
	Waiting = 0,
	Complete = 1
}

export interface OrderTargetPayment {
	id: number;
	orderTargetId: number;
	paymentId: number;
	value: number;
}

export interface OrderTarget extends WithDeleted, WithTime {
	addToBalance: boolean;

	appointmentId: number;
	appointment?: Appointment;

	inventory: boolean;

	patientId: number;
	patient?: Patient;

	productLocationId: Nullable<number>;
	productLocation?: ProductLocation;

	productId: Nullable<number>;
	product?: Product;
	productUsable?: boolean;

	doctorProcedureId: Nullable<number>;
	doctorProcedure?: DoctorProcedure;

	doctorPayer: DoctorPayer | null;
	doctorPayerId?: number;

	procedureId: Nullable<number>;
	procedure?: Procedure;

	appointmentProcedureId: Nullable<number>;
	appointmentProcedure?: AppointmentProcedure;

	totalCount: number;
	count: number;
	price: number;
	debt?: number | null;
	priceUpcharge: number;
	isPriceUpcharge: boolean;

	paymentType: PaymentType | null;

	orderTargetType: OrderTargetType;

	insuranceTargetStatus: Nullable<InsuranceTargetStatus>;

	parentId: Nullable<number>;
	parent?: OrderTarget;

	orderId: Nullable<number>;
	order?: Order;

	orderType: OrderType;

	commission: number;

	userId: Nullable<number>;
	user: User | null;

	balance: number;
	discount: number;
	tax: number;

	ledgerId: Nullable<number>;
	ledger?: Ledger;

	specialsId?: Nullable<number>;
	specials?: Special;

	checked: boolean;
	deposited: boolean;

	nopaliId: number;
	note: string;
	refund: Nullable<number>;

	hasPatientFile: boolean;

	billItems: Array<BillItem>;
	fromOrder?: number;

	orderPayment?: OrderPayment;

	giftCardBalance?: number;

	// field for client only
	oldGiftCardBalance: number;
	partialSpecialsPrice: number;
	usedCount: number;
	refundCount: number;
}

export const getOrderTargetPrice = (ot: OrderTarget): number => ot.price * (ot.discount ? ((100 - ot.discount) / 100) : 1);

export const hasGiftCardBalance = (ot: OrderTarget) => ((!isUndefined(ot.giftCardBalance) && ot.giftCardBalance !== null)
	|| (!isUndefined(ot.oldGiftCardBalance) && ot.oldGiftCardBalance !== null));

export const isGiftCard = (ot: OrderTarget): boolean => hasGiftCardBalance(ot)
	&& ot.orderTargetType === 2;

export const isGiftCardUse = (ot: OrderTarget): boolean => hasGiftCardBalance(ot)
	&& ot.orderTargetType === OrderTargetType.None
	&& ot.orderType === OrderType.Use;

export const isRefundGiftCardUse = (ot: OrderTarget): boolean => hasGiftCardBalance(ot)
	&& ot.orderTargetType === OrderTargetType.None
	&& ot.orderType === OrderType.Refund;
