import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Message from 'antd/lib/message';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import Button from '@common/react/components/Forms/Button';

import {
	AppointmentInsurance,
	AppointmentInsuranceStatusesInfo,
} from '@app/objects/AppointmentInsurance';
import { Appointment } from '@app/objects/Appointment';
import { request } from '@app/components/Api';

interface PropsWithAppointment {
	appointment: Appointment;
	className?: string;
	asButton?: boolean;
}

interface DisabledLayoutProps {
	className: string;
	asButton: boolean;
}

const DisabledLayout: React.FC<DisabledLayoutProps> = ({ className, asButton }) => {
	return asButton
		? <Button disabled className={className}><i className="fa fa-medkit" /></Button>
		: (
			<span title="Appointment Insurance Coding" className={`${className} text-muted`}>
				<i className="fa fa-medkit" />
			</span>
		);
};

interface ActiveLayoutProps {
	className: string;
	asButton: boolean;
	appointment: Appointment;
}

const ActiveLayout: React.FC<ActiveLayoutProps> = (props) => {
	const { appointment, className, asButton } = props;

	const history = useHistory();
	const [loading, setLoading] = React.useState<boolean>(false);

	const hasInsurance = appointment.insurance || appointment.location?.allowAutoCoding;
	const styleIcon = hasInsurance && appointment.appointmentInsurance
		? { color: AppointmentInsuranceStatusesInfo[appointment.appointmentInsurance.status].color }
		: { color: 'grey' };
	const caption = hasInsurance && appointment.appointmentInsurance
		? AppointmentInsuranceStatusesInfo[appointment.appointmentInsurance.status].name
		: 'No insurance';

	const requestInsurance = () => {
		setLoading(true);
		request<AppointmentInsurance>('getAppointmentInsuranceId', {
			appointmentId: appointment.id,
		})
			.then((res) => {
				history.push(`/insurance-editor/${res.id}`);
			})
			.catch((err) => {
				Message.error(err);
				setLoading(false);
			});
	};

	if (appointment.appointmentInsuranceId) {
		return (
			<LinkWithPrevLocation
				className={className}
				title={caption}
				to={`/insurance-editor/${appointment.appointmentInsuranceId}`}
			>
				<i className="fa fa-medkit" style={styleIcon} />
			</LinkWithPrevLocation>
		);
	}

	return asButton
		? (
			<Button disabled={loading} className={className} title={caption} onClick={requestInsurance} isLoading={loading}>
				<i className="fa fa-medkit" />
			</Button>
		)
		: (
			<span
				title={caption}
				className={`${className} ${loading ? 'text-muted' : ''}`}
				onClick={requestInsurance}
			>
				<i className="fa fa-medkit" style={styleIcon} />
			</span>
		);
};

export const LinkToInsuranceEditor: React.FC<PropsWithAppointment> = (props) => {
	const { appointment, asButton = true } = props;

	const hasInsurance = appointment.insurance || appointment.location?.allowAutoCoding;
	const className = props.className || 'btn btn-sm btn-default';

	return hasInsurance
		? <ActiveLayout appointment={appointment} className={className} asButton={asButton} />
		: <DisabledLayout className={className} asButton={asButton} />;
};
