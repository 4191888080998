import React from 'react';

import { ChatKind } from '@common/react/components/Chat/Chat';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import LoadingButton from '@common/react/components/UI/LoadingButton/LoadingButton';
import { BaseUser } from '@common/typescript/objects/BaseUser';

interface Props {
	className?: string;
	user: BaseUser;
}

const SendMessageButton: React.FC<Props> = ({
	user,
	className = '',
	children = <i className="fa fa-paper-plane-o" />,
}) => {
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { actions: { startChat } } = context;

	const handleStartChat = () => {
		const value = {
			name: `${user?.lastName} ${user?.firstName}` || user?.firstName || '',
			kind: ChatKind.Personal,
			userId: user.id,
		};

		return startChat(value);
	};

	return (
		<LoadingButton actionFunction={handleStartChat} className={className} onSuccess={() => undefined}>
			{children}
		</LoadingButton>
	);
};

export default SendMessageButton;
