import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { bindActionCreators } from 'redux';

import RegularChatMessage from '@common/react/components/Chat/RegularMessagePlugin/RegularChatMessage';
import { ChatMessage } from '@common/react/components/Chat/Chat';
import '@common/react/scss/components/forwardedMessage.scss';
import Button from '@common/react/components/Forms/Button';
import { getActionCreators } from '@common/react/components/Chat/Store/Chats';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import { replaceSearchUrl } from '@common/react/components/Utils/Utils';

interface ForwardMessageProps {
	message: ChatMessage;
	contacts: Array<any>;
}

const ForwardMessage: React.FC<ForwardMessageProps> = ({ message, contacts }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const chat = useSelector((state: any) => state.chats.chats.list.find((chat) => chat.id === message.innerChatMessage?.chatId));
	const actions = React.useMemo(() => bindActionCreators(getActionCreators(), dispatch), []);
	const { state: { storageName, plugins } } = useChatSettingsProviderContext();

	const handleForward = () => {
		actions.selectChat(chat, storageName);
		history.push({
			...history.location,
			search: replaceSearchUrl(history.location.search, 'messageId', `${message.innerChatMessageId}`),
		});
	};

	return (
		<div>
			<div className="forwarded-message">
				<div className="forwarded-message__info">
					{
						chat
							? <>
								Forwarded from chat:
								<span className="forwarded-message__info__chat-name">{chat.name}</span>
							</>
							: 'Forwarded'
					}
				</div>
				<div className="forwarded-message__content">
					{
						message.innerChatMessage
							? plugins[message.innerChatMessage.chatMessageType]?.message?.render({
								message: message.innerChatMessage,
								contacts,
								withRemoteId: undefined,
								onImageClick: undefined,
								onMouseEnter: undefined,
								lastVideoCallId: undefined,
							})
							: <>Forwarded message is not found</>
					}
					{
						chat
						&& <Button onClick={handleForward} className="message-action btn btn-primary btn-xs">
							To Message
						</Button>
					}
				</div>
			</div>
			<RegularChatMessage message={message} contacts={contacts} />
		</div>
	);
};

export default ForwardMessage;
