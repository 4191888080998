import * as React from 'react';

import { ErrorMessage, FieldProps, FormikProps } from 'formik';

import { defaultErrorComponent } from '@common/react/components/Forms/FormikInput/FormikInput';

export interface FormikListProps<FormValues> {
	fieldProps: FieldProps;
	formikBag: FormikProps<FormValues>;
	equalityChecker: (item: any, newItem: any) => boolean;
	renderItem: (item: any, removeItem: () => void) => JSX.Element | null;
	renderInput: (addNewItem: (item) => void) => JSX.Element;
	showValidateAfterSubmit?: boolean;
	ErrorComponent?: React.FC<{error: string}>;
}

export const FormikList = <FormValues, >(props: FormikListProps<FormValues>) => {
	const { showValidateAfterSubmit, ErrorComponent = defaultErrorComponent, fieldProps } = props;
	const { field } = fieldProps;
	let arr = field.value || [];

	if (!Array.isArray(arr)) {
		arr = field.value.list;
	}

	return <>
		<div>
			{arr.filter((item) => !item.deleted)
				.map((item) => props.renderItem(item, () => {
					const itemIndex = arr.indexOf(item);

					const newArray = arr.slice();

					if (item.id === -1) {
						newArray.splice(itemIndex, 1);
					} else {
						newArray.splice(itemIndex, 1, {
							...item,
							deleted: true,
						});
					}

					props.formikBag.setFieldValue(field.name, newArray);
				}))
			}
		</div>
		<div className="is-relative">
			{showValidateAfterSubmit && fieldProps.form.submitCount > 0
				&& <ErrorMessage name={field.name} render={(msg) => <ErrorComponent error={msg} />} />}
			{props.renderInput((newItem) => {
				let arr = field.value;

				if (!Array.isArray(arr)) {
					arr = field.value.list;
				}

				const foundItem = arr.filter((nextItem) => props.equalityChecker(nextItem, newItem))[0];

				if (!foundItem) {
					props.formikBag.setFieldValue(field.name, [...arr, newItem]);
				} else if (foundItem.deleted) {
					const itemIndex = arr.indexOf(foundItem);
					const newArray = arr.slice();

					newArray.splice(itemIndex, 1);

					newArray.push({
						...foundItem,
						deleted: false,
					});

					props.formikBag.setFieldValue(field.name, newArray);
				}
			})}
		</div>
	</>;
};
