import React from 'react';

import Select, { SelectProps } from 'antd/lib/select';

import SelectFilter from '@common/react/components/Forms/SelectFilter/SelectFilter';
import { BaseParams } from '@common/typescript/objects/BaseParams';

import { ReportSourceType, ReportSourceTypeNames } from '@app/components/Pages/Admin/BaseReport/BaseReport';

interface ComponentProps {
	multiple: boolean;
	additionalParams?: BaseParams;
	value: ReportSourceType | Array<ReportSourceType>;
	defaultValue?: ReportSourceType;
	onChange: (value: ReportSourceType | Array<ReportSourceType>) => void;
	className?: string;
	excludeTypes?: Array<ReportSourceType>;
	disabled?: boolean;
	selectProps?: SelectProps;
	containerClassName?: string;
	style?: React.CSSProperties;
	maxTagCount?: number;
	maxTagTextLength?: number;
	overrideNames?: object;
}

const { Option } = Select;

const ReportSourceTypeSelect: React.FC<ComponentProps> = ({
	multiple,
	additionalParams = {},
	value,
	defaultValue,
	onChange,
	className = 'pull-right mr8',
	excludeTypes,
	disabled,
	selectProps,
	containerClassName = className,
	style = { width: 250, marginLeft: 10 },
	maxTagTextLength = 10,
	maxTagCount = 1,
	overrideNames,
}) => {
	let names = ReportSourceTypeNames;

	if (overrideNames) {
		names = { ...names, ...overrideNames };
	}

	let reportSourceTypeNamesKeys = Object.keys(names);

	if (excludeTypes?.length) {
		reportSourceTypeNamesKeys = reportSourceTypeNamesKeys.filter((key) => !excludeTypes.includes(+key));
	}

	return (
		<>
			<div className={containerClassName}>
				{multiple
					? (
						<div className={className}>
							<Select
								{...selectProps}
								className={className}
								style={style}
								disabled={disabled}
								onChange={(value: Array<string>) => {
									onChange(value.map((q) => +q));
								}}
								value={Array.isArray(value)
									? value.map((rst) => rst.toString())
									: undefined}
								mode="multiple"
								showArrow
								showSearch={false}
								placeholder="Source types..."
								filterOption={false}
								maxTagTextLength={maxTagTextLength}
								maxTagCount={maxTagCount}
								allowClear
							>
								{reportSourceTypeNamesKeys.map((key) =>
									<Option key={key} value={key}>{names[key]}</Option>)}
							</Select>
						</div>
					)
					: (
						<SelectFilter
							additionalParams={additionalParams}
							param="reportSourceType"
							disabled={disabled}
							onChange={(evt) => {
								onChange(+evt.currentTarget.value);
							}}
							selectProps={{ ...selectProps }}
							className={className}
							defaultValue={defaultValue ? names[defaultValue] : undefined}
							currentValue={!Array.isArray(value)
								? names[value]
								: undefined}
						>
							{reportSourceTypeNamesKeys.map((item, index) => (
								<Select.Option
									key={item}
									value={item}
								>
									{names[item]}
								</Select.Option>
							))}
						</SelectFilter>
					)}
			</div>
		</>
	);
};

export default ReportSourceTypeSelect;
