import * as React from 'react';

import Message from 'antd/lib/message';

import ItemsProviderWithStore from '@common/react/components/Core/ItemsProviderWithStore/ItemsProviderWithStore';
import { SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import ItemsProviderSynchronizer from '@common/react/components/Core/AdvancedItemsProvider/ItemsProviderSynchronizer';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

import { request } from '@app/components/Api';
import { Appointment, AppointmentStatus, defaultStatusesFilters } from '@app/objects/Appointment';
import '@app/scss/components/customEventStatusList.scss';
import ColorLine from '@app/components/UI/ColorLine/ColorLine';

interface Props {
	appointment: Appointment;
	onSelect(status: AppointmentStatus): void;
}

const StatuesWithSort: React.FC<{statuses: Array<AppointmentStatus>} & Props> = ({ statuses: propsStatuses, appointment, onSelect }) => {
	const statuses = React.useMemo(
		() =>
			propsStatuses
				.slice()
				.sort((a, b) => a.sortOrder - b.sortOrder),
		[propsStatuses],
	);

	const onSelectHandle = React.useCallback((status: AppointmentStatus) => {
		if (status.id === appointment.statusId) {
			onSelect(status);
		} else {
			request<Appointment>('appointmentStatusChanging', {
				id: appointment.id,
				statusId: status.id,
			}).then(() => {
				onSelect(status);
			}).catch(Message.error);
		}
	}, [appointment]);

	return (
		<div>
			<ul className="custom-event-status-list">
				{statuses.map((status) => (
					<li
						className={`custom-event-status-list__item ${(appointment.statusId || appointment?.status?.id) === status.id
							? 'custom-event-status-list__item_active' : ''}`}
						key={status.id}
						onClick={() => onSelectHandle(status)}
					>
						<ColorLine color={status.color} />
						{status.nameEn}
					</li>
				))}
			</ul>
		</div>
	);
};

export const StatusListWrapper: React.FC<{ render?: any, withStore?: boolean }> = ({ render, withStore = true }) => {
	return <ItemsProviderWithStore<AppointmentStatus>
		defaultSort={['sortOrder', SortingDirection.Ascending]}
		filterHandler={() => true}
		sortHandler={(a, b) => a.sortOrder - b.sortOrder}
		storeName="appointmentStatuses"
		type="appointmentStatus"
		pagination={{ current: 1, pageSize: 100 }}
		filters={defaultStatusesFilters}
	>
		{(state) => <>
			<ItemsProviderSynchronizer
				objectType="AppointmentStatusRedux"
				storeName="appointmentStatuses"
				storeSortAfterAdd={(items) => [...items].sort((a, b) => a.sortOrder - b.sortOrder)}
				getItemsFromInit={(notification) => notification.data.appointmentStatuses}
			/>
			{render && render(state)}
		</>}
	</ItemsProviderWithStore>;
};

const CustomEventStatusList: React.FC<Props> = ({
	appointment,
	onSelect,
}) => {
	return (
		<StatusListWrapper
			withStore={false}
			render={(state) =>
				(state.loading ? <Loader /> : <StatuesWithSort appointment={appointment} statuses={state.items} onSelect={onSelect} />)}
		/>
	);
};

export default CustomEventStatusList;
