import React from 'react';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

const Header: React.FC = () => {
	return (
		<div className="privacy-policy__header">
			<div className="logo">
				<LinkWithPrevLocation to="/">
					<img src="/amp-assets/images/logo.png" height={74} alt="Northern Tuna Portal logo" />
				</LinkWithPrevLocation>
			</div>
		</div>
	);
};

export default Header;
