import * as React from 'react';

import { ChatMessageType, ChatPlugin } from '@common/react/components/Chat/Chat';

export const EditPlugin : ChatPlugin = {
	chatMessageAction: ({
		edit, message, isEdit, fromUser,
	}) =>
		(fromUser && message?.chatMessageType === ChatMessageType.Regular ? <button
			key="edit"
			className="message-action btn btn-default btn-sm"
			type="button"
			onClick={() => edit(isEdit ? null : message)}
		>
			{isEdit ? 'Cancel' : 'Edit'}
		</button> : null),
};
