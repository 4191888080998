import * as React from 'react';

import Modal from 'antd/lib/modal';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import { useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';

import { ReviewRequestStrategy } from '@app/objects/ReviewRequestStrategy';
import { Appointment, ReviewStatus } from '@app/objects/Appointment';
import { User } from '@app/objects/User';

interface HandleNotificationReviewRequestProps {
	appointmentId: number;
	onStatusChange?: (reviewStatus: ReviewStatus) => void;
	onErrorStatusChange?: () => void;
}

export const HandleReviewRequestStatusChangingNotification: React.FC<HandleNotificationReviewRequestProps> = (
	props,
) => {
	const { subscribe, getUser } = useApplicationContext();
	const user = getUser<User>();

	const context = useItemProviderContext<Appointment>(false);

	const {
		onStatusChange = (reviewStatus: ReviewStatus) => {
			if (context?.actions) {
				const {
					actions: { setItem },
				} = context;

				setItem((item) => ({ ...item, reviewStatus }));
			}
		},
		onErrorStatusChange = () => undefined,
		appointmentId,
	} = props;

	React.useEffect(subscribe((notification: any) => {
		const data = notification.data;

		if (notification.user === user?.id) {
			if (notification.objectType === 'ReviewRequestStrategyNotification' && data?.id === appointmentId) {
				if (data.reviewRequestStrategy === ReviewRequestStrategy.SendAfterCompleteStatus) {
					onStatusChange(ReviewStatus.Pending);
				}
			}

			if (notification.objectType === 'ReviewRequestErrorNotification' && data?.id === appointmentId) {
				onErrorStatusChange();
				Modal.info({
					title: 'Appointment successfully saved, but',
					content: data?.errorMessage,
				});
			}

			if (notification.objectType === 'ReviewRequestCancelPendingStatusNotification' && data?.appointmentId === appointmentId) {
				onStatusChange(ReviewStatus.None);
			}

			if (notification.objectType === 'ReviewRequestStatusNotification' && data?.id === appointmentId) {
				onStatusChange(data.reviewRequest.status);
			}
		}
	}), [appointmentId]);

	return <></>;
};

export const HandleNotificationReviewRequest: React.FC = () => {
	const { subscribe, request, getUser } = useApplicationContext();
	const user = getUser<User>();
	const [modalData, setModalData] = React.useState<{ destroy }>();

	React.useEffect(subscribe((notification: any) => {
		const data = notification.data;

		if (notification.user === user?.id
			&& notification.objectType === 'ReviewRequestStrategyNotification'
			&& data.reviewRequestStrategy === ReviewRequestStrategy.AskAfterCompleteStatus) {
			reviewRequestModal(data?.id);
		}
	}));

	React.useEffect(() => {
		if (!user && modalData) {
			modalData.destroy();
			setModalData(undefined);
		}
	}, [user, modalData]);

	const reviewRequestModal = (appointmentId) => {
		const modalData = Modal.confirm({
			title: 'Do you want to send a review request?',
			content: '',
			okText: 'Yes',
			okType: 'primary',
			cancelText: 'No',
			onCancel: () => setModalData(undefined),
			onOk() {
				request('appointmentReviewStatusChanging', ({ id: appointmentId, reviewStatus: ReviewStatus.Pending }))
					.catch((err) => Modal.error({
						title: 'Error',
						content: err,
					}));
				setModalData(undefined);
			},
		});
		setModalData(modalData);
	};

	return <></>;
};
