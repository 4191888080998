import * as React from 'react';

import loadable from '@loadable/component';
import { FormikProps } from 'formik';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import CustomButton from '@common/react/components/Forms/Button';
import {
	ChatFormButtonsProps, ChatMessageType, NewMessage, ChatPlugin,
} from '@common/react/components/Chat/Chat';

const GiphyMessage = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "GiphyMessage" */ '@common/react/components/Chat/Giphy/GiphyMessage')),
	{ fallback: <div className="giphy-message__loader" /> },
);

const GiphyButton = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "GiphyButton" */ '@common/react/components/Chat/Giphy/GiphyButton')),
	{
		fallback: <CustomButton
			title="Giphy"
			className="btn btn-sm btn-default chat-message-item giphy-button"
			isLoading
			disabled
			type="button"
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 35">
				<g fillRule="evenodd" clipRule="evenodd">
					<path fill="#00ff99" d="M0 3h4v29H0z" />
					<path fill="#9933ff" d="M24 11h4v21h-4z" />
					<path fill="#00ccff" d="M0 31h28v4H0z" />
					<path fill="#fff35c" d="M0 0h16v4H0z" />
					<path fill="#ff6666" d="M24 8V4h-4V0h-4v12h12V8" />
					<path fill="#121212" opacity="0.4" d="M24 16v-4h4M16 0v4h-4" />
				</g>
			</svg>
		</CustomButton>,
	},
);

const LastMessage = ({ message, userId }) => {
	return <div className="chat-list__item-last-message">
		{message.userId === userId ? 'You: sent gif' : 'gif'}
	</div>;
};

export interface GiphyOptions {
	skeleton: string;
	giphyKey: string;
}

export interface GiphyPlugin extends ChatPlugin {
	options: GiphyOptions;
}

export const GiphyPlugin : GiphyPlugin = {
	formButton: (formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) =>
		(props.chatId ? <GiphyButton
			getPopupContainer={props.getPopupContainer}
			chatId={props.chatId}
			giphyKey={GiphyPlugin.options.giphyKey}
		/> : <></>),
	message: {
		render: ({ message }) => {
			const [src, fullSrc, description, type, width, height] = (message.text || '')
				.split('][').map((str) => str.replace(/[\[\]]/g, ''));

			return <div
				className="giphy-message__wrapper"
				style={{ '--padding-top': `${(height / width) * 100}%` } as React.CSSProperties}
			>
				<GiphyMessage id={src} width={width} height={height} {...GiphyPlugin.options} />
			</div>;
		},
		lastMessage: LastMessage,
		notification: ({ message }) => {
			const [src, fullSrc, description, type, width, height] = (message.text || '')
				.split('][')
				.map((str) => str.replace(/[\[\]]/g, ''));
			const solvedWidth = 200 * (width > height ? 1 : width / height);
			const solvedHeight = 200 * (width > height ? height / width : 1);

			return <div
				className="giphy-message__wrapper giphy-notification is-relative"
				style={{ '--padding-top': `${(height / width) * 100}%`, width: solvedWidth, height: solvedHeight } as React.CSSProperties}
			>
				<GiphyMessage
					id={src}
					width={solvedWidth}
					height={solvedHeight}
					withoutModal
					{...GiphyPlugin.options}
				/>
			</div>;
		},
	},
	options: {
		skeleton: '',
		giphyKey: '',
	},
};
