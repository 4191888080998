import * as React from 'react';

import Select, { SelectProps } from 'antd/lib/select';

import { SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import ItemsSelect from '@common/react/components/UI/ItemsSelect/ItemsSelect';

import { defaultStatusesFilters } from '@app/objects/Appointment';
import '@app/scss/components/statusFilter.scss';
import { ProcedureGroup } from '@app/objects/ProcedureGroup';
import { filterOption } from '@app/utils/filterOption';
import { NullableProcedureGroup } from '@app/components/Pages/Admin/BaseReport/BaseReport';

interface ComponentProps extends Omit<SelectProps, 'mode'> {
	value: Array<number> | undefined;
	onChange: (value, option?) => void;
	procedureGroups?: Array<ProcedureGroup>;
	antdProps?: SelectProps;
	withAdditionalOptions?: boolean;
	maxTagCount?: number;
	maxTagTextLength?: number;
	allowClear?: boolean;
	className?: string;
	valueName?: string;
	mode?: string;
	resetValue?: boolean;
	filterItem?: (item) => boolean;
}

const Option = Select.Option;

const ProcedureGroupsFilter: React.FC<ComponentProps> = (props) => {
	const {
		value, onChange, maxTagCount, maxTagTextLength, antdProps, valueName = 'procedureGroupIds', mode = 'multiple', resetValue,
	} = props;
	const {
		withAdditionalOptions, procedureGroups, allowClear = true, className = 'ml10 pull-right',
	} = props;
	const [currentValue, setValue] = React.useState<any>(null);

	const handleChange = (value, option) => {
		onChange({ [valueName]: value }, option);
		setValue(value);
		resetValue && setTimeout(() => setValue(undefined), 0);
	};

	return (
		<div className={`status-filter ${className}`}>
			<ItemsSelect<ProcedureGroup>
				getOptionProps={(item) => ({
					value: item.id,
					children: item.name,
					item,
					label: item.name,
				})}
				selectProps={{
					...antdProps,
					allowClear,
					mode: mode === 'multiple' ? mode : undefined,
					placeholder: 'Service Groups...',
					value: resetValue
						? currentValue
						: value || (mode === 'multiple' ? [] : undefined),
					onChange: handleChange,
					maxTagCount,
					maxTagTextLength,
					className: 'w100',
					filterOption,
					showSearch: true,
				}}
				filterItem={props.filterItem}
				defaultSort={['sortOrder', SortingDirection.Ascending]}
				filterHandler={() => false}
				storeName="procedureGroups"
				type="procedureGroup"
				items={procedureGroups}
				pagination={{ current: 1, pageSize: 100 }}
				filters={defaultStatusesFilters}
				additionalOptions={withAdditionalOptions
					? (
						<Option key={NullableProcedureGroup} value={NullableProcedureGroup} label="Without group">
							Without group
						</Option>
					) : null}
			/>
		</div>
	);
};

export default ProcedureGroupsFilter;
