import * as React from 'react';

import * as Yup from 'yup';
import {
	Field, FieldProps,
} from 'formik';
import Modal from 'antd/lib/modal';

import { Chat, ChatKind } from '@common/react/components/Chat/Chat';
import { FormikList } from '@common/react/components/Forms/FormikList/FormikList';
import Autocomplete from '@common/react/components/Forms/Autocomplete/Autocomplete';
import Button from '@common/react/components/Forms/Button';
import { BaseParams } from '@common/react/objects/BaseParams';
import { getUserName } from '@common/react/utils/utils';
import { BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';

const validationSchema = Yup.object().shape({
	kind: Yup.number(),
	contacts: Yup.array().when('kind', {
		is: ChatKind.Group,
		then: Yup.array().min(1, 'You must select users'),
	}),
});

interface BaseUserWithAvatarWithDeleted extends BaseUserWithAvatar, WithDeleted {
}

interface AddChatPopupProps {
	user: BaseUserWithAvatarWithDeleted;
	onSave: (chat: Chat) => void;
	render: (show: () => void) => void;
	autocompleteParams?: BaseParams;
	type?: string;
	renderItem?: (user: BaseUserWithAvatarWithDeleted) => React.ReactNode;
	setChatKind?: (contacts: Array<any>, kind: ChatKind) => ChatKind;
}

interface AddChatPopupState {
	isLoading: boolean;
	popupShow: boolean;
}

interface FormValues {
	id: number;
	name: string;
	kind: ChatKind;
	contacts: Array<BaseUserWithAvatarWithDeleted>;
}

const AddChatPopup:React.FC<AddChatPopupProps> = (props) => {
	const [state, setState] = React.useState<AddChatPopupState>({
		isLoading: false,
		popupShow: false,
	});

	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests } } = context;

	const showPopup = () => setState((prev) => ({ ...prev, popupShow: true }));
	const popupHide = () => setState((prev) => ({ ...prev, popupShow: false }));

	const {
		user: loggedUser, autocompleteParams, type = 'userList', renderItem = (user: BaseUserWithAvatarWithDeleted) => getUserName(user),
	} = props;

	return <>
		{props.render(showPopup)}
		<Modal
			title="New chat"
			open={state.popupShow}
			onCancel={popupHide}
			destroyOnClose
			width={500}
			footer={null}
		>
			<ItemProvider<FormValues>
				id={-1}
				add={{
					id: -1,
					name: '',
					kind: ChatKind.Group,
					contacts: [],
				} as FormValues}
				type=""
				validationSchema={validationSchema}
				saveRequest={requests.chat}
				readonly={false}
				clearForSubmit={(values) => {
					return {
						kind: props.setChatKind ? props.setChatKind(values.contacts, values.kind) : values.kind,
						name: values.name.trim() === '' ? '' : values.name,
						contacts: values.contacts.map((contact: BaseUserWithAvatarWithDeleted) => (
							{
								id: -1,
								userId: contact.id,
							}
						)).concat(
							{
								id: -1,
								userId: props.user.id,
							},
						),
					};
				}}
			>
				<ItemEditor<FormValues>
					formikProps={{
						enableReinitialize: true,
					}}
					afterSubmit={(item, res) => {
						popupHide();
						props.onSave?.(res);
					}}
					edit={(formikBag, _, state) => {
						return <>
							<div className="row">
								{formikBag.values.kind !== ChatKind.Personal
									&& <Field name="name">
										{({ field }: FieldProps<string, FormValues>) =>
											<div className="form-group col-sm-6">
												<label htmlFor="chatName">Name</label>
												<div className="is-relative">
													<input type="text" className="form-control" {...field} />
												</div>
											</div>
										}
									</Field>
								}
							</div>
							<Field name="contacts">
								{(props: FieldProps<FormValues>) => (
									<div className={`form-group ${props.form.errors.contacts && props.form.touched.contacts ? 'has-error' : ''}`}>
										<label>Contacts*</label>
										<FormikList
											fieldProps={props}
											formikBag={formikBag}
											equalityChecker={(item, newItem) => item.id === newItem.id}
											renderItem={(user: BaseUserWithAvatarWithDeleted) => (
												<div className="inline-select-item" key={user.id}>
													<i
														className="fa fa-times inline-select-item__remove"
														onClick={() => formikBag.setFieldValue(
															props.field.name,
															formikBag.values.contacts.filter((item) => item.id !== user.id),
														)}
													/>
													<span>{renderItem(user)}</span>
												</div>
											)}
											renderInput={(addNewItem) =>
												<div className="is-relative">
													<Autocomplete<BaseUserWithAvatarWithDeleted>
														type={type}
														renderOption={(autocompleteItem) => {
															return {
																key: `${autocompleteItem.id}`,
																value: getUserName(autocompleteItem),
																title: getUserName(autocompleteItem),
																item: autocompleteItem,
																label: renderItem(autocompleteItem),
															};
														}}
														onSelect={(value, option) => {
															addNewItem(option.props.item);
														}}
														loadOnFocus
														value=""
														isClear
														params={{
															...autocompleteParams,
															excludeUserIds: [loggedUser.id].concat(
																formikBag.values.contacts?.filter((u) => !u.deleted)?.map((user) => user.id),
															),
														}}
														antdProps={{
															placeholder: 'Start typing for search...',
															filterOption: (inputValue, option: any) => {
																const id = +(option.key as string);
																return loggedUser && loggedUser.id !== id
																	&& !formikBag.values.contacts
																		.some((item: BaseUserWithAvatarWithDeleted) =>
																			id === item.id && !item.deleted);
															},
														}}
													/>
													{props.form.touched.contacts && props.form.errors.contacts
														? <div className="validation-message">{props.form.errors.contacts}</div> : ''}
												</div>
											}
										/>
									</div>
								)}
							</Field>
							<div className="text-right">
								<Button isLoading={state.loading} className="btn btn-sm btn-primary">Save</Button>
							</div>
						</>;
					}}
				/>
			</ItemProvider>
		</Modal>
	</>;
};

export default AddChatPopup;
