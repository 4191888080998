import * as React from 'react';

import { Feature } from '@commonTuna/react/objects/CompanyFeature';

import { useCompanyFeatureProviderContext } from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';

interface CompanyFeatureAccessProps {
	children: any;
	feature: Feature | Array<Feature>;
}

export const CompanyFeatureAccess: (props: CompanyFeatureAccessProps) => React.ReactElement = ({
	children,
	feature,
}) => {
	const { checkFeatureAccess } = useCompanyFeatureProviderContext();
	const [isVisible, setIsVisible] = React.useState<boolean>(false);

	React.useEffect(() => {
		checkFeatureAccess(feature) && setIsVisible(true);
	}, []);

	return <>{isVisible && children}</>;
};

export default CompanyFeatureAccess;
