import React from 'react';

import Tooltip from 'antd/lib/tooltip';

interface Props {
	onDelete: () => void;
}

const RemoveUserFiltersButton: React.FC<Props> = ({
	onDelete,
}) => {
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Tooltip
				open={open}
				onOpenChange={setOpen}
				trigger="click"
				placement="bottom"
				title={<div className="is-relative">
					<h5>
						<i className="fa fa-exclamation-circle pull-left" style={{ color: '#cba306', fontSize: 24 }} />
						This item will be permanently deleted and cannot be recovered. Are you sure?
					</h5>
					<div className="text-end">
						<button
							type="button"
							className="btn btn-sm btn-default"
							title="Save"
							onClick={() => setOpen(false)}
						>
							No
						</button>
						<button
							type="button"
							className="btn btn-sm btn-primary"
							onClick={() => {
								onDelete();
							}}
						>
							Yes
						</button>
					</div>
				</div>}
			>
				<button
					type="button"
					className="user-filter-action user-filter-action__delete"
				>
					<i className="fa fa-trash" />
				</button>
			</Tooltip>
		</>
	);
};

export default RemoveUserFiltersButton;
