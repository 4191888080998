import React from 'react';

import '@common/react/scss/components/sizable.scss';

interface SizableProps {
	leftComponent?: React.ReactNode;
	rightComponent?: React.ReactNode;
	className?: string;
	showRightComponent?: boolean;
}

const Sizable: React.FC<SizableProps> = (props) => {
	const {
		className = '', leftComponent, rightComponent, showRightComponent = true,
	} = props;
	const resizeElement = React.useRef<any>();
	const [width, setWidth] = React.useState(55);

	const onPointerDown = (e) => {
		if (!e.target.classList.contains('tag-sizable-handler')) {
			return;
		}
		e.target?.setPointerCapture(e.pointerId);
		const container = e.target.closest('.sizable-container');

		const containerWidth = container?.getBoundingClientRect().width;
		const startWidth = e.target.closest('.tag-sizable-left')?.getBoundingClientRect().width;

		resizeElement.current = {
			startLeft: e.clientX,
			containerWidth,
			startWidth,
			container,
		};
	};

	const onPointerMove = (e) => {
		if (!resizeElement.current) {
			return;
		}

		e.currentTarget.style.cursor = 'col-resize';

		const { container, containerWidth } = resizeElement.current;

		if (container) {
			const width = resizeElement.current.startWidth + e.clientX - resizeElement.current.startLeft;
			if (width >= 400 && width <= containerWidth) {
				const resolveWidth = Math.round((10000 * width) / containerWidth) / 100;
				resizeElement.current.newWidth = resolveWidth;
				container.firstElementChild.style.width = `${resolveWidth}%`;
				container.firstElementChild.nextSibling.style.width = `${100 - resolveWidth}%`;
			}
		}
	};

	const onPointerUpOrLeave = (e) => {
		if (!resizeElement.current) {
			return;
		}

		e.currentTarget.style.cursor = 'unset';

		const { newWidth } = resizeElement.current;
		resizeElement.current = null;

		if (newWidth) {
			setWidth(newWidth);
		}
	};

	return <div
		onPointerDown={onPointerDown}
		onPointerMove={onPointerMove}
		onPointerLeave={onPointerUpOrLeave}
		onPointerUp={onPointerUpOrLeave}
		className={`sizable-container ${showRightComponent ? '' : 'sizable-container__without-right'} ${className}`}
	>
		<div className="tag-sizable-left" style={{ width: `${showRightComponent ? width : 100}%` }}>
			<div className="tag-sizable-left__inner">
				{leftComponent}
			</div>
			{showRightComponent ? <div
				key="resizable"
				className="tag-sizable-handler"
			/> : null}
		</div>
		{showRightComponent ? <div className="tag-sizable-right" style={{ width: `${100 - width}%` }}>
			<div className="tag-sizable-right__inner">
				{rightComponent}
			</div>
		</div> : null}
	</div>;
};

export default Sizable;
